import useLocation from 'react-use/lib/useLocation';

const pathAllowsNewChat = (path: string = ''): boolean => {
  return path.startsWith('/support');
};

export const useCanStartChat = (): boolean => {
  const { pathname } = useLocation();
  return pathAllowsNewChat(pathname);
};
