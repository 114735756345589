const SESSION_KEY_KEY = 'belong_chat_session_key';
export const clearSessionKey = (): void => {
  window.localStorage.removeItem(SESSION_KEY_KEY);
};
export const saveSessionKey = (initiateChatResponse: string): void => {
  const encodedSessionKey = window.btoa(initiateChatResponse);
  window.localStorage.setItem(SESSION_KEY_KEY, encodedSessionKey);
};
export const loadSessionKey = (): string | undefined => {
  return window.localStorage.getItem(SESSION_KEY_KEY) || undefined;
};

const WAS_OPEN_KEY = 'belong_chat_was_open';
export const saveWasOpen = (wasOpen: boolean | undefined): void => {
  window.localStorage.setItem(WAS_OPEN_KEY, JSON.stringify(wasOpen));
};
export const loadWasOpen = (): boolean => {
  return window.localStorage.getItem(WAS_OPEN_KEY) === 'true';
};

const WAS_CONNECTED_KEY = 'belong_chat_was_connected';
export const saveWasConnected = (wasConnected: boolean | undefined): void => {
  window.localStorage.setItem(WAS_CONNECTED_KEY, JSON.stringify(wasConnected));
};
export const loadWasConnected = (): boolean => {
  return window.localStorage.getItem(WAS_CONNECTED_KEY) === 'true';
};
