import InitialStep from './InitialStep';
import CompleteStep from './CompleteStep';

export enum FeedbackStep {
  INITIAL = 'initial',
  TEXT_INPUT = 'textInput',
  COMPLETE = 'complete'
}

export interface IFeedbackCaptureStep {
  testIdPrefix: string;
  next?: () => void;
}

const FeedbackCaptureSteps = {
  [FeedbackStep.INITIAL]: {
    Component: InitialStep,
    next: FeedbackStep.COMPLETE
  },
  [FeedbackStep.COMPLETE]: {
    Component: CompleteStep,
    next: null
  }
};

export default FeedbackCaptureSteps;
