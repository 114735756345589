import type { Entry, ICON_NAMES, ILink } from '@belong/types';
import { parseLinkData } from '../../helpers/parseLinkData';

export interface IEntryLink {
  key?: string;
  a11yLabel?: string;
  hash?: string;
  icon?: ICON_NAMES;
  label: string;
  shouldOpenInNewTab?: boolean;
  url?: Entry<{ url: string }>;
  variant: string;
}

export const FLink = ({ fields }: Entry<IEntryLink>): ILink => {
  const { a11yLabel, hash, icon, label, shouldOpenInNewTab, url, variant } = fields;

  const result: ILink = {
    label,
    children: label,
    variant: variant.toLowerCase(),
    a11yLabel,
    icon,
    ...(url
      ? parseLinkData({
          ...url,
          hash
        })
      : {})
  };

  if (shouldOpenInNewTab) {
    result.target = '_blank';
  }

  return result;
};
