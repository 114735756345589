import { IOrganismMenuItem } from '@belong/types';
import { FMoleculeNavLinkGroup } from '../molecules/moleculeNavLinkGroup.factory';
import { FRichText } from '../richText.factory';
import { FNavLink } from '../elements/elementNavLink.factory';

export const FMenuItem = (data: any): IOrganismMenuItem => {
  const { fields } = data;

  return {
    identifier: fields.identifier,
    parentLabel: fields.parentLabel,
    url: fields.url && FNavLink(fields.url),
    summary: fields.summary && FRichText(fields.summary),
    summaryLink: fields.summaryLink && FNavLink(fields.summaryLink),
    navGroup1: fields.navGroup1 && fields.navGroup1.map(navLinkGroup => FMoleculeNavLinkGroup(navLinkGroup)),
    navGroup2: fields.navGroup2 && fields.navGroup2.map(navLinkGroup => FMoleculeNavLinkGroup(navLinkGroup))
  };
};
