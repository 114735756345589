import styled, { css } from 'styled-components';

import { MediaMapResult, TBreakpointKeys } from '@belong/types';
import { mediaMap } from '@belong/themes';

import { Copy } from '../../styles/Typography/Copy';

export type ColumnOptions = number | 'auto';
export interface IColumnLayout {
  hasColumns?: { [k in TBreakpointKeys]?: ColumnOptions } | ColumnOptions;
}

const getColumnProp = ({ hasColumns }: IColumnLayout): IColumnLayout => ({ hasColumns });

export const ColumnLayout = styled(Copy).attrs(getColumnProp)`
  ${({ hasColumns }): MediaMapResult =>
    mediaMap(
      hasColumns,
      columnCount => css`
        column-count: ${columnCount};
      `
    )}
  > * {
    // ensure countries are on separate lines
    display: block;
  }
`;
