import React from 'react';
import { ACCENTSTRIP_VARIANTS, StyledAccentStrip } from './AccentStrip.styles';

export interface IAccentStrip {
  variant?: ACCENTSTRIP_VARIANTS;
  hasOpacity?: boolean;
  isSmall?: boolean;
  isFlexEnd?: boolean;
  isBottom?: boolean;
  isVertical?: boolean;
  isSolid?: boolean;
}

/** The AccentStrip component can be used to decorate another component with predefined color. */
export const AccentStrip = ({
  variant = ACCENTSTRIP_VARIANTS.DISCO_PINK,
  isSmall: isBasic,
  isSolid = false,
  ...others
}: IAccentStrip) => {
  return <StyledAccentStrip variant={variant} isSmall={isBasic} isSolid={isSolid} {...others} />;
};

AccentStrip.displayName = 'AccentStrip';
