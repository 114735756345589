import { MediaMapResult, OUTER_SPACING, TMediaMap } from '@belong/types';
import { mediaMap, SECTION_VERTICAL_SPACINGS } from '@belong/themes';
import styled, { css } from 'styled-components';

interface IOuterSpacingProps {
  spacing: OUTER_SPACING;
}

export const spacingRules: Record<OUTER_SPACING, TMediaMap> = {
  [OUTER_SPACING.COMPACT]: {
    xs: '1.6rem',
    md: '2.4rem',
    lg: '2.4rem'
  },
  [OUTER_SPACING.STANDARD]: {
    xs: '3.2rem',
    md: '4rem',
    lg: '4rem'
  },
  [OUTER_SPACING.LOOSE]: SECTION_VERTICAL_SPACINGS
};

/**
 * A utility component that provides top spacing and bottom spacing, and does nothing else.
 */
const OuterSpacing = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['spacing'].includes(prop) && defaultValidatorFn(prop)
})<IOuterSpacingProps>`
  ${(props): MediaMapResult =>
    mediaMap(
      spacingRules[props.spacing],
      spacing => css`
        padding-top: ${spacing};
        padding-bottom: ${spacing};
      `
    )};
`;

export default OuterSpacing;
