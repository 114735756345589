import React, { useImperativeHandle, useState } from 'react';
import { ICON_NAMES, IntrinsicElements } from '@belong/types';
import { FONT_COLOURS, ICON_SIZES } from '@belong/themes';
import { useAnalyticsEvents } from '@belong/providers/analyticsEvents'; // if you import from `@belong/providers` only, the InvoiceHistory.test.tsx will fail
import { stringOrRichTextToString } from '@belong/contentful';
import { Copy } from '../../styles/Typography/Copy';
import { ACCORDION_DROPDOWN_TEST_ID } from '../../../helpers/testIds';
import CollapsibleItem from '../CollapsibleItem';
import * as Accordion from './AccordionRow.styles';
import { ACCORDION_ROW_STATE, IAccordionRow } from './AccordionRow.types';

export type ToogleIndexHandle = {
  toggleIndex: () => void;
};

export const AccordionRow: React.FC<IAccordionRow> = React.forwardRef<ToogleIndexHandle, IAccordionRow>(
  (
    {
      label,
      headingLevel,
      children,
      icon,
      variant,
      available = true,
      hasBackground = 'transparent',
      hasPadding,
      headingHasVerticalBorder = false,
      initialState = ACCORDION_ROW_STATE.closed,
      hasBottomBorder = false,
      unmountCollapsedContent = true,
      onClick,
      ...otherProps
    },
    ref
  ) => {
    const [isOpen, toggleOpen] = useState<boolean>(initialState === ACCORDION_ROW_STATE.open);
    const analyticsEvents = useAnalyticsEvents();

    useImperativeHandle(ref, () => ({
      toggleIndex() {
        toggleOpen(!isOpen);
      }
    }));

    const toggleIndex = (): void => {
      toggleOpen(!isOpen);
    };

    const handleClick = (event): void => {
      event.preventDefault();

      toggleIndex();

      if (onClick !== undefined) {
        onClick(event);
      }

      analyticsEvents.send({
        category: 'page engagement',
        action: `accordion ${!isOpen ? 'expand' : 'collapse'}`,
        label: stringOrRichTextToString(label || '')
      });
    };

    const accordionHeader = (
      <Accordion.Button
        hasVerticalBorder={headingHasVerticalBorder}
        onClick={handleClick}
        aria-expanded={isOpen}
        data-testid={ACCORDION_DROPDOWN_TEST_ID}
        variant={variant}
        available={available}
      >
        <Accordion.StyledIconContainer>
          {icon && (
            <Accordion.StyledIcon name={icon.name} size={ICON_SIZES.small} className="accordion-row-inline-icon" />
          )}
          <Accordion.Label className="accordion-label" variant={variant}>
            {label}
          </Accordion.Label>
        </Accordion.StyledIconContainer>
        <Accordion.Chevron
          isOpen={isOpen}
          name={ICON_NAMES.ChevronDown}
          size="small"
          hasColor={FONT_COLOURS.MESSAGE}
          variant={variant}
        />
      </Accordion.Button>
    );

    return (
      <Accordion.Wrapper
        isOpen={isOpen}
        variant={variant}
        hasBottomBorder={hasBottomBorder}
        className="accordion-wrapper"
        hasBackground={hasBackground}
        {...otherProps}
      >
        <Copy
          className="accordion-row--heading"
          as={(headingLevel as IntrinsicElements) ?? 'span'}
          variant={variant === 'slim' ? 'small' : 'large'}
          hasColor={available ? FONT_COLOURS.STRONG : FONT_COLOURS.FINE_PRINT}
          isBold
        >
          {accordionHeader}
        </Copy>

        <CollapsibleItem isOpened={isOpen} shouldUnmount={unmountCollapsedContent} className="accordion-row--content">
          <Accordion.Body hasPadding={hasPadding} variant={variant}>
            {children}
          </Accordion.Body>
        </CollapsibleItem>
      </Accordion.Wrapper>
    );
  }
);

AccordionRow.displayName = 'AccordionRow';
