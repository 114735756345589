import { useEffect } from 'react';

/**
 * @param className The CSS class to add to the \<body\> tag
 * @param isActive Whether to add the class, defaults to `false`
 */
export const useBodyClass = (className: string, isActive = false): void => {
  useEffect(() => {
    document.body.classList.toggle(className, isActive);
    return () => {
      document.body.classList.remove(className);
    };
  }, [className, isActive]);
};
