export type LoginMessage = {
  action: 'login';
  payload: string;
};

export type LogoutMessage = {
  action: 'logout';
};

export type AuthenticationMessage = LoginMessage | LogoutMessage;

export const isLoginMessage = (data: AuthenticationMessage): data is LoginMessage => data.action === 'login';
export const isLogoutMessage = (data: AuthenticationMessage): data is LogoutMessage => data.action === 'logout';
