import { ButtonWithRef } from './ButtonBase';
import withLink from '../../hocs/withLink/withLink';

// Note: Separating the `withLink`ed button into its own file as `withLink`
// adds Next-specific behavior; Non-Next apps are unable to use anything
// from a file that uses `withLink` without having Next deps included in
// their bundle.

/**
 * A customizable button component with different variants in style and size.
 */
export const Button = withLink(ButtonWithRef);
Button.displayName = 'Button';
