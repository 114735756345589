import { TBreakpointKeys } from '../theme';

export type ResponsiveProp<P> = P | Partial<Record<TBreakpointKeys, P>>;

export type ResponsiveRangeProps = {
  below?: TBreakpointKeys;
  atAndAbove?: TBreakpointKeys;
};

// todo: move these objects to ui-components or ui-core
export const alignXToFlex = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
} as const;

export const alignXToFlexReverse = {
  left: 'flex-end',
  center: 'center',
  right: 'flex-start'
} as const;

export type AlignX = keyof typeof alignXToFlex;

export const alignYToFlex = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
  stretch: 'stretch'
} as const;

export type AlignY = keyof typeof alignYToFlex;

export const progressColourVariants = {
  blue: 'blue',
  foil_slick: 'foil_slick',
  orange: 'orange'
} as const;

export const progressBackgroundColourVariants = {
  white: 'white',
  grey: 'grey',
  transparent: 'transparent'
} as const;

export type PROGRESS_COLOUR_VARIANTS = keyof typeof progressColourVariants;
export type PROGRESS_BACKGROUND_COLOUR_VARIANTS = keyof typeof progressBackgroundColourVariants;
