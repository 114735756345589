import { ThemeKey, IThemeVariationProperties } from '@belong/types';
import { COLOURS, SHADOWS } from '../helpers';
import { buttonVariants as buttonLight } from './light';
import { buttonVariants as buttonDark } from './dark';

export const THEME_VARIATIONS: Record<ThemeKey, IThemeVariationProperties> = {
  // define variations here
  Light: {
    backgroundColor: COLOURS.WHITE,
    backgroundColorLight: COLOURS.GREY_100,
    foregroundColor: COLOURS.GREY_600,
    foregroundColorDark: COLOURS.BLACK,
    foregroundColorLight: COLOURS.GREY_500,
    linkColor: COLOURS.DARK_BLUE,
    borderColor: COLOURS.GREY_300,
    borderColorLight: COLOURS.GREY_200,
    outlineColor: COLOURS.BELONG_BLUE_DARK,
    focusRingColor: COLOURS.BELONG_BLUE_DARK,
    shadows: SHADOWS,
    baseFontColor: COLOURS.BLACK,
    greyFontColor: COLOURS.GREY_500,
    button: {
      ...buttonLight
    }
  },
  Dark: {
    backgroundColor: COLOURS.BLACK,
    backgroundColorLight: COLOURS.GREY_600,
    foregroundColor: COLOURS.WHITE,
    foregroundColorDark: COLOURS.WHITE,
    foregroundColorLight: COLOURS.WHITE,
    linkColor: COLOURS.BELONG_BLUE_LIGHT,
    borderColor: COLOURS.GREY_600,
    borderColorLight: COLOURS.BELONG_BLUE_LIGHT,
    outlineColor: COLOURS.BELONG_BLUE_DARK,
    focusRingColor: COLOURS.BELONG_BLUE_DARK,
    baseFontColor: COLOURS.WHITE,
    greyFontColor: COLOURS.GREY_400,
    button: {
      ...buttonDark
    }
  }
};
