import { StyledCSS } from '@belong/types';
import { isFocused, media, COLOURS } from '@belong/themes';
import styled, { css } from 'styled-components';
import { withLink } from '../../hocs';

export const Root = styled.nav`
  background-color: ${COLOURS.BLACK};
  font-size: 15px;
  user-select: none;
`;

export const List = styled.ol`
  line-height: 0;
  list-style: none;
  margin: 0;
  padding: 1rem 0 2rem;
`;

export const ListItem = styled.li<{ isSecondLast: boolean }>`
  margin: 0;
  padding: 0;
  display: none;
  color: ${COLOURS.WHITE};

  a {
    color: ${COLOURS.BELONG_BLUE_LIGHT};
  }

  &:last-child a {
    color: ${COLOURS.WHITE};
  }

  &::after,
  &::before {
    margin: 0 16px;
  }

  // mobile view: only first parent is shown
  ${({ isSecondLast }): StyledCSS => {
    if (isSecondLast) {
      return css`
        display: inline-block;

        &::before {
          content: '<';
        }
      `;
    }
    return css``;
  }}

  // All other breakpoints display as normal
  ${media('md')`
    display: inline-block;
    
    &::before {
      content: none;
    }

    &:not(&:last-child)::after {
      content: '>';
    }
  `};
`;

export const Link = withLink(styled.a`
  display: inline-block;
  padding: 1.4rem 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    ${isFocused()};
  }
`);
