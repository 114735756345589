import { GlobalConfigEntry, IHeaderMenu, IFooterMenu, IGlobalConfigParsed } from '@belong/types';
import { FFooterMenu } from './footerMenu.factory';
import { FHeaderMenu } from './headerMenu.factory';

/**
 *
 * @param items header and footer Contentful raw response
 * @returns Parsed header and footer content or undefined
 */
export const extractGlobalLayoutContent = (items?: GlobalConfigEntry[]): IGlobalConfigParsed => {
  let headerMenuContent: IHeaderMenu | undefined;
  let footerMenuContent: IFooterMenu | undefined;

  if (items && items.length > 0) {
    const { footerMenuItems, socialLinks, firstNationsAcknowledgement, belongAbnDetails, ...headerMenuFields } =
      items[0].fields;
    const footerMenuFields = { footerMenuItems, socialLinks, firstNationsAcknowledgement, belongAbnDetails };
    headerMenuContent = FHeaderMenu(headerMenuFields);
    footerMenuContent = FFooterMenu(footerMenuFields);
  }
  return { headerMenuContent, footerMenuContent };
};
