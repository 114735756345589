import slugify from 'slugify';

import type { IBaseLink } from '@belong/types';

export const parseLinkData = (data: any): IBaseLink => {
  const { fields, hash } = data;
  const { url } = fields;
  const urlContentType = data.sys?.contentType?.sys.id;
  const isExternal = urlContentType === 'urlExternal';
  const asUrl = (isExternal ? '' : url) + (hash ? `#${slugify(hash)}` : '');

  return { href: url, asUrl };
};
