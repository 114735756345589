import React from 'react';
import { logger } from '@belong/logging/logger';
import { IWithLink } from '@belong/types';
import { config, defaultRenderer } from './renderers';

export const withLink = <P extends object>(
  StyledComponent: React.ComponentType<P>
): React.FC<React.PropsWithChildren<P & IWithLink>> => {
  const withLinkFactory: React.FC<any> = (props: P & IWithLink) => {
    const { debug, linkRenderers, routes, defaultLinkRenderer } = withLink.config;
    const renderer = Object.values(linkRenderers).find(item => item.test(props, routes));
    const linkProps = {
      ...props,
      children: (props as any).children || (props as any).label
    };

    if (renderer) {
      if (debug) {
        logger.debug(`${renderer.name} renderer - href: ${linkProps.href}, asUrl: ${linkProps.asUrl}`);
      }
      return renderer.render(StyledComponent, linkProps, withLink.config);
    }

    logger.debug(`!! Using default renderer !! (${linkProps.href}, asUrl: ${linkProps.asUrl})`);
    return (defaultLinkRenderer || defaultRenderer).render(StyledComponent, linkProps, withLink.config);
  };

  withLinkFactory.displayName = 'withLink';
  return withLinkFactory;
};

// Attach overridable config
withLink.config = config;
export default withLink;
