import { createContext, useContext, useMemo } from 'react';
import { Cookies } from 'react-cookie';

import { COOKIES } from '@belong/constants';
import { IPlatformConfig, PLATFORM_TYPE } from '@belong/types';
import { parsePlatformConfig } from '@belong/utils';

export const PlatformConfigContext = createContext<IPlatformConfig>({
  platform: PLATFORM_TYPE.BROWSER, // Always initially assume browser
  settings: {}
});

export const PlatformConfigProvider = ({ cookies, children }: React.PropsWithChildren<{ cookies: Cookies }>) => {
  const nativeAppCookie = cookies.get(COOKIES.NATIVE_APP);

  const platformConfig = useMemo(() => parsePlatformConfig(nativeAppCookie), [nativeAppCookie]);

  return <PlatformConfigContext.Provider value={platformConfig}>{children}</PlatformConfigContext.Provider>;
};

export interface IUsePlatformConfig {
  isNativeApp: boolean;
  config: IPlatformConfig;
}

export const usePlatformConfig = (): IUsePlatformConfig => {
  const platformConfig = useContext(PlatformConfigContext);

  if (!platformConfig) {
    throw new ReferenceError(
      'No Context found for usePlatformConfig. You probably forgot to add the <PlatformConfigContext.Provider> to your app.'
    );
  }

  const isNativeApp = useMemo(() => platformConfig.platform !== PLATFORM_TYPE.BROWSER, [platformConfig.platform]);

  return {
    isNativeApp,
    config: platformConfig
  };
};
