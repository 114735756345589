// Transformer to parse Contentful data into component props
import { IFeedbackCapture } from './FeedbackCapture';

const FFeedbackCapture = (data: any): IFeedbackCapture => {
  const { heading, labelYes: yes, labelNo: no, successMessage } = data.fields;

  return {
    heading,
    successMessage,
    optionLabels: {
      yes,
      no
    }
  };
};

export default FFeedbackCapture;
