import { useId, useMemo } from 'react';
import { useBodyClass } from '../useBodyClass';

/**
 * Generates an ID which can be interpolated into a valid css class name
 * @example
 * const id = useUniqueClassId();
 * const myClassname = `class-prefix-${id}`;
 */
const useUniqueClassId = (): string => {
  const id = useId();
  const safeId = useMemo(() => id.replaceAll(':', ''), [id]);
  return safeId;
};

/**
 * @param isActive Whether to add the 'scroll locking' class to body, defaults to `false`
 */
export const useLockBodyScroll = (isActive = false): void => {
  // Generates a unique instance of the prefixed classname, so that multiple simultaneous
  // calls to lock or unlock the body do not interfere and add/remove the same classname.
  // Requires a broader accompanying CSS selector: [class*=body-scroll-lock]
  const id = useUniqueClassId();
  const uniqueClass = `body-scroll-lock-${id}`;
  useBodyClass(uniqueClass, isActive);
};
