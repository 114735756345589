import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { ListItem, OrderedList, UnorderedList } from '../../..';

export default {
  /* eslint-disable react/display-name */
  [BLOCKS.LIST_ITEM]: (_, children) => <ListItem>{children}</ListItem>,
  [BLOCKS.OL_LIST]: (_, children) => <OrderedList>{children}</OrderedList>,
  [BLOCKS.UL_LIST]: (_, children) => <UnorderedList>{children}</UnorderedList>
  /* eslint-enable react/display-name */
};
