import { hasAlignment, hasColor } from '@belong/themes';
import { IText } from '@belong/types';

import styled from 'styled-components';

/**
 * The text component wraps any string like input
 */
export const Text = styled.div<IText>`
  ${hasAlignment}
  ${hasColor}
`;
export type { IText };
