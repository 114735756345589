import React, { FunctionComponent, useState } from 'react';
import { Divider } from '@belong/ui-core';
import { Heading } from '../../styles/Typography/Heading';
import { Fieldset } from '../../forms/Fieldset';
import { Container } from './FeedbackCapture.styles';
import Steps, { FeedbackStep } from './steps';
import { TEST_ID } from './constants';

export interface IFeedbackCapture extends React.HTMLAttributes<HTMLDivElement> {
  heading: string;
  optionLabels: Record<'yes' | 'no', string>;
  successMessage: string;
}

/**
 * Multi-step mechanism to capture user feedback.
 *
 * Results/input is currently recorded using analytics platforms
 */
const FeedbackCapture: FunctionComponent<IFeedbackCapture> = ({ heading, optionLabels, successMessage, ...props }) => {
  const [currentStep, setCurrentStep] = useState<FeedbackStep>(FeedbackStep.INITIAL);
  const Step = Steps[currentStep];

  const next = () => {
    if (Step.next) {
      setCurrentStep(Step.next);
    }
  };

  const stepProps = {
    optionLabels,
    successMessage,
    next
  };

  return (
    <Container data-testid={props['data-testid'] || TEST_ID.COMPONENT}>
      <Divider />
      <Fieldset>
        <legend>
          <Heading data-testid={TEST_ID.HEADING} variant="small" as="h2" alignment="left">
            {heading}
          </Heading>
        </legend>
        <Step.Component {...stepProps} />
      </Fieldset>
    </Container>
  );
};

FeedbackCapture.displayName = 'FeedbackCapture';
export default FeedbackCapture;
