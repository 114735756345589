import { qs as queryString } from 'url-parse';
import { IGenericFetchResult } from '@belong/types';
import { createAbortController, genericFetch, IAppConfig, IGenericFetch, IGenericOptions } from '../../helpers';

type FetchBody = IGenericOptions['body'];
export type FetchOptions = Omit<IGenericOptions, 'body' | 'signal'> & Partial<IAppConfig>;

export class GenericAPI {
  static serviceConfig?: IAppConfig['config'];

  controller = createAbortController();
  signal = this.controller?.signal;

  isBusy = false;

  async get<T>(
    endpoint: string,
    payload: Record<string, any> = {},
    options: FetchOptions = {}
  ): Promise<IGenericFetchResult<T>> {
    const queryParams = queryString.stringify(payload).replace(/^(.)/, '?$1');
    return this.doFetch({ endpoint: `${endpoint}${queryParams}`, method: 'GET', ...options });
  }

  async post<T>(endpoint: string, body: FetchBody, options: FetchOptions = {}): Promise<IGenericFetchResult<T>> {
    return this.doFetch({ body, endpoint, method: 'POST', ...options });
  }

  async put<T>(endpoint: string, body: FetchBody, options: FetchOptions = {}): Promise<IGenericFetchResult<T>> {
    return this.doFetch({ body, endpoint, method: 'PUT', ...options });
  }

  async patch<T>(endpoint: string, body: FetchBody, options: FetchOptions = {}): Promise<IGenericFetchResult<T>> {
    return this.doFetch({ body, endpoint, method: 'PATCH', ...options });
  }

  async delete<T>(endpoint: string, body: FetchBody, options: FetchOptions = {}): Promise<IGenericFetchResult<T>> {
    return this.doFetch({ body, endpoint, method: 'DELETE', ...options });
  }

  abort(): void {
    if (this.controller && this.isBusy) {
      this.controller.abort();
    }
  }

  protected async doFetch<T>(options: Omit<IGenericFetch, 'signal'>): Promise<IGenericFetchResult<T>> {
    if (!GenericAPI.serviceConfig) {
      throw new Error(
        '`serviceConfig` missing. Ensure `serviceConfig` has been set on the GenericAPI before consuming this service'
      );
    }

    if (this.signal?.aborted) {
      this.controller = createAbortController();
      this.signal = this.controller?.signal;
    }

    this.isBusy = true;

    const result = await genericFetch<T>({
      config: GenericAPI.serviceConfig,
      signal: this.signal,
      ...options
    });

    this.isBusy = false;

    return result;
  }
}
