import React from 'react';
import { media } from '@belong/themes';
import styled from 'styled-components';
import { UnorderedList, ListItem } from '../List';
import { IMultiColumnList } from './MultiColumnList.types';

const StyledColumns = styled.div`
  columns: 2 auto;
  column-gap: 0.8rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;

  ${media('md')`
    columns: 3 auto;
    column-gap: 2.4rem;
  `}

  ${media('lg')`
    column-gap: 3.2rem;
  `}
`;

export const MultiColumnList: React.FC<IMultiColumnList> = ({ listItems }: IMultiColumnList) => {
  return (
    <StyledColumns className="columns">
      <UnorderedList style={{ paddingTop: 0, paddingBottom: 0 }}>
        {listItems.map(item => (
          <ListItem key={item}>{item}</ListItem>
        ))}
      </UnorderedList>
    </StyledColumns>
  );
};

MultiColumnList.displayName = 'MultiColumnList';
export default MultiColumnList;
