import { useEffect, useRef, useState, FC } from 'react';
import { Button, Column, Columns, ICON_NAMES, Stack, Searchbox, Error, Label } from '@belong/ui-components';
import { useSearchBox } from 'react-instantsearch';
import { COLOURS, media } from '@belong/themes';
import styled from 'styled-components';
import { TError } from '@belong/types';
import { urls } from '@belong/utils/url';
import {
  iconOnlyButtonStyles,
  iconOnlyLabelStyles,
  iconOnlyIconStyles
} from '@belong/ui-components/components/actions/Button/Button.styles';
import { SEARCH_TEST_IDS } from './test/utils/testIds';
import { ISearchInputProps } from './types';

const StyledSearchBox = styled(Searchbox)`
  ::-ms-clear,
  ::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }
`;

const StyledSubmitButton = styled(Button)`
  ${media('xs', 'md')`
    ${iconOnlyButtonStyles}
    .children {
      ${iconOnlyLabelStyles}
    }
    .icon {
      ${iconOnlyIconStyles}
    }
  `};
`;

export const SearchInput: FC<ISearchInputProps> = ({
  defaultValue = '',
  searchOnRedirect = false,
  callback,
  searchInputRef,
  'data-testid': testId = SEARCH_TEST_IDS.INPUT_ROOT,
  ...props
}) => {
  const { query, refine, clear } = useSearchBox(props);

  const [inputValue, setInputValue] = useState(query);
  const tempRef = useRef<HTMLInputElement>(null);
  const inputRef = searchInputRef ?? tempRef;
  const [error, setError] = useState<TError>({ hasError: false, message: '' });
  const { hasError } = error;

  const idInput = `${testId}-${SEARCH_TEST_IDS.INPUT_FIELD}`;
  const idError = `${testId}-${SEARCH_TEST_IDS.INPUT_ERROR}`;
  const idLabel = `${testId}-${SEARCH_TEST_IDS.INPUT_LABEL}`;

  useEffect(() => {
    setInputValue(query);
    if (query !== '') {
      setError({ hasError: false, message: '' });
    }
  }, [query, defaultValue]);

  const clearSearch = () => {
    clear();
    setInputValue('');
    inputRef.current?.focus();
  };

  const validateField = (): boolean => {
    if (inputRef.current?.value.trim() === '') {
      setError({ hasError: true, message: 'Please type something to search' });
      inputRef.current?.focus();
      return false;
    }
    setError({ hasError: false, message: '' });
    return true;
  };

  const submitSearch = event => {
    event.preventDefault();
    event.stopPropagation();

    if (validateField()) {
      // this is for searches initiated on pages that are not the results page
      if (searchOnRedirect) {
        // the search on redirect prop might exist on an input that appears on the results page
        if (window.location.pathname === urls.SEARCH) {
          refine(inputValue);
        } else {
          // the initial redirect to a search page with the query
          // a full page reload so the experience is the same from all apps
          window.location.assign(`${urls.SEARCH}?query=${encodeURIComponent(inputValue)}`);
        }
      } else {
        // you're on a page that shows the hits
        refine(inputValue);
      }
      callback?.();
    }
  };

  // this is used to initiate a search on the search page if there is a reload or a direct link to the search page
  useEffect(() => {
    if (window.location.pathname === urls.SEARCH && inputValue !== '') {
      refine(inputValue);
    }
  }, []);

  return (
    <form
      method="GET"
      encType="application/x-www-form-urlencoded"
      role="search"
      noValidate
      onSubmit={submitSearch}
      data-testid={`${testId}-${SEARCH_TEST_IDS.INPUT_FORM}`}
    >
      <Stack space="small">
        <Label htmlFor={idInput} hasColor={COLOURS.WHITE} id={idLabel}>
          What are you searching for?
        </Label>
        <Columns space="xsmall" alignY="top">
          <Column>
            <Stack space="small">
              <StyledSearchBox
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck={false}
                maxLength={60}
                type="search"
                value={query !== inputValue ? inputValue : query}
                onChange={event => {
                  setInputValue(event.currentTarget.value);
                }}
                width="auto"
                a11yClear={''}
                hasSelection={false}
                handleClear={clearSearch}
                refProp={inputRef}
                name="q"
                id={idInput}
                data-testid={`${testId}-${SEARCH_TEST_IDS.INPUT_FIELD}`}
                isError={hasError}
                aria-describedby={hasError ? idError : ''}
                onDarkBG
              />
              {hasError && (
                <Error idError={idError} onDarkBG>
                  {error.message}
                </Error>
              )}
            </Stack>
          </Column>
          <Column width="content">
            <StyledSubmitButton
              type="submit"
              variant="primary"
              label="Search"
              icon={ICON_NAMES.Search}
              hasIconLeft
              isLightColor
              data-testid={`${testId}-${SEARCH_TEST_IDS.SUBMIT_BUTTON}`}
            />
          </Column>
        </Columns>
      </Stack>
    </form>
  );
};

SearchInput.displayName = 'SearchInput';
