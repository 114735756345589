import { AGENT, ICON_NAMES } from '@belong/types';
import { ICON_SIZES } from '@belong/themes';
import useAnalyticsEvents from '@belong/providers/analyticsEvents';
import { buttonClick } from '@belong/analytics';
import { clearAgentSession, useAgent } from '@belong/user';
import { navigateToExternalPage } from '@belong/utils';
import { Copy } from '@belong/ui-components';

import { GLOBAL_HEADER_TEST_ID } from '../../testids';
import { Container, LogoutLink, StyledAgentHeader, StyledIcon } from './styles';

const content = {
  welcome: '👋🏻 Welcome, ',
  link: 'Log out of portal'
};

const AgentHeader: React.FC = () => {
  const { agentName, userId } = useAgent();
  const welcomeMsg = `${content.welcome} ${agentName}`;
  const analyticsEvents = useAnalyticsEvents();

  const agentLogout = (): void => {
    analyticsEvents.send(buttonClick({ text: content.link, label: welcomeMsg, otherProps: { userId } }));
    clearAgentSession();

    navigateToExternalPage(`${AGENT.LOGOUT}`);
  };

  return (
    <StyledAgentHeader data-testid={GLOBAL_HEADER_TEST_ID.AGENT_HEADER}>
      <Container>
        <Copy isBold>{welcomeMsg}</Copy>
        <Copy isBold>
          <LogoutLink data-testid={GLOBAL_HEADER_TEST_ID.AGENT_BUTTON_LOGOUT} onClick={() => agentLogout()}>
            <StyledIcon name={ICON_NAMES.LogOut} size={ICON_SIZES.xsmall} />
            {content.link}
          </LogoutLink>
        </Copy>
      </Container>
    </StyledAgentHeader>
  );
};

AgentHeader.displayName = 'AgentHeader';

export default AgentHeader;
