import styled, { css } from 'styled-components';
import { ISectionWrapper, OUTER_SPACING, StyledCSS } from '@belong/types';
import { COLOURS, mediaMap } from '@belong/themes';
import { spacingRules } from '../../layout/OuterSpacing';

const CARD_BACKGROUND_COLOR = COLOURS.WHITE;

const getBackground = (hasBackground: string, themeBackground: any): string => {
  // Override theme with background colour if it's not transparent or undefined
  // TODO: WORK OUT A BETTER WAY TO HANDLE FALL BACKS FOR BACKGROUND COLOURS
  if (hasBackground === 'default') {
    return 'transparent';
  }
  if (hasBackground !== 'default' && hasBackground !== 'transparent') {
    return hasBackground;
  }
  return themeBackground;
};

export const SectionWrapper = styled.section<ISectionWrapper>`
  ${({
    hasBackground,
    hasPaddingTop,
    hasMarginTop,
    hasPaddingBottom,
    containsCard,
    paddingSize
  }): StyledCSS<ISectionWrapper> => css`
    background-color: ${({ theme }) => hasBackground && getBackground(hasBackground, theme.backgroundColor)};
    ${hasMarginTop && `margin-top: 2.4rem;`}

    /*
     * When a section wrapper has a card in it, we want to override any background
     * color on small breakpoints because the whole card "disappears" into the
     * background at that size. We want to ensure that the card doesn't become
     * a strange borderless block of color on a differently-colored section. 
     */
    ${mediaMap(
      {
        xs: containsCard
          ? CARD_BACKGROUND_COLOR
          : hasBackground && getBackground(hasBackground, ({ theme }) => theme.backgroundColor),
        md: hasBackground && getBackground(hasBackground, ({ theme }) => theme.backgroundColor)
      },
      color => css`
        background-color: ${color};
      `
    )};

    ${hasPaddingTop &&
    mediaMap(
      spacingRules[paddingSize ?? OUTER_SPACING.LOOSE],
      (spacing: string) => css`
        padding-top: ${spacing};
      `
    )}

    ${hasPaddingBottom &&
    mediaMap(
      spacingRules[paddingSize ?? OUTER_SPACING.LOOSE],
      (spacing: string) => css`
        padding-bottom: ${spacing};
      `
    )}
  `}

  &:only-child {
    flex-grow: 1;
  }
`;

SectionWrapper.displayName = 'SectionWrapper';

/**
 * This is a rough approximation of the height of the global header (which is
 * not a fixed height) in pixels with some fat added to it to account for a
 * possible alert also. The exact number is not important as long as there is
 * some amount of offset to avoid the global header overlapping content which
 * is linked to with an anchor
 */
export const ANCHOR_OFFSET = 160;

export const Anchor = styled.a`
  display: block;
  position: relative;
  top: -${ANCHOR_OFFSET}px;
  visibility: hidden;
`;
