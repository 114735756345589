import React from 'react';
import Container from '../../layout/Container';
import { SECTION_WRAPPER_TEST_ID } from '../../../helpers/testIds';

interface ISectionContainer {
  hasContainer: boolean;
  isPadded?: boolean;
  isFullWidth?: boolean;
}

const SectionContainer: React.FC<React.PropsWithChildren<ISectionContainer>> = ({
  hasContainer,
  isPadded,
  isFullWidth,
  children
}) => {
  if (hasContainer) {
    return (
      <Container isPadded={isPadded} isFullWidth={isFullWidth} data-testid={SECTION_WRAPPER_TEST_ID.CONTAINER}>
        {children}
      </Container>
    );
  }

  return <>{children}</>;
};

SectionContainer.displayName = 'SectionContainer';

export default SectionContainer;
