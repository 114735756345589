import type { INavLink } from '@belong/types';
import { parseLinkData } from './parseLinkData';

export const parseNavLink = (data: any): INavLink => {
  const { label, a11yLabel, url, navLinkIcon } = data.fields;
  return {
    label,
    navLinkIcon,
    'aria-label': a11yLabel,
    ...parseLinkData(url)
  };
};
