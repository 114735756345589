export enum PLATFORM_TYPE {
  BROWSER = 'BROWSER',
  IOS = 'N_IOS',
  ANDROID = 'N_ANDROID'
}

export interface IPlatformConfig {
  platform: PLATFORM_TYPE;
  settings: { [key: string]: boolean };
  appVersion?: string;
}
