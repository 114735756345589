import { useState } from 'react';
import { IFooterMenu } from '@belong/types';

import createProvider from '../_factory';

interface IProvider {
  footer: IFooterMenu | null;
  setFooter: (newValue: IFooterMenu) => void;
}

type hook = (/* this hook takes no args */) => IProvider;

const useFooter: hook = (): IProvider => {
  const [footer, setFooter] = useState<IFooterMenu | null>(null);

  return {
    footer,
    setFooter
  };
};

const { hook, provider } = createProvider<hook>(useFooter, 'useFooter');

export const Provider = provider;

export default hook;
