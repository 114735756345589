export enum KEYBOARD_KEYS {
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  TAB = 'Tab',
  SPACE = ' '
}

export const CHARCODES = {
  ENTER: 13
};

export const LIB_INPUTS_DEFAULT_IDS = {
  ACCOUNT_NUMBER: 'accountNumber',
  DOB: 'dateOfBirth',
  DISCONNECTION_DATE: 'dateOfDisconnection',
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  MOBILE: 'mobileNumber',
  PASSWORD: 'password',
  POSTCODE: 'postcode',
  VERIFICATION_CODE: 'verificationCode',
  SIM_NUMBER: 'simNumber'
} as const;

export const ARIA_CURRENT = 'page';
