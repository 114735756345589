import React from 'react';
import { ITestable, TBreakpointKeys } from '@belong/types';
import styled, { css } from 'styled-components';

export interface IHiddenProps extends ITestable {
  children: React.ReactNode;
  /**
    Make children hidden at and above the breakpoint
  */
  atAndAbove?: TBreakpointKeys;
  /**
    Make children hidden below the breakpoint
  */
  below?: TBreakpointKeys;
  /**
    Make visually hidden (invisible)
   */
  invisible?: boolean;
  /**
    id of element
   */
  id?: string;
}

const atAndAboveCssHelper = css<IHiddenProps>`
  @media (min-width: ${p => p.atAndAbove && Math.max(0, p.theme.breakpoints[p.atAndAbove])}px) {
    display: none;
  }
`;

const belowCssHelper = css<IHiddenProps>`
  @media (max-width: ${p => p.below && p.theme.breakpoints[p.below] - 1}px) {
    display: none;
  }
`;
const invisibleHelper = css<IHiddenProps>`
  z-index: 9999;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
`;

const HiddenParent = styled.div<IHiddenProps>`
  width: 100%;

  ${p => p.atAndAbove && atAndAboveCssHelper};
  ${p => p.below && belowCssHelper};
  ${p => p.invisible && invisibleHelper}
`;
// todo: use responsiveRangeProps
export const Hidden: React.FC<IHiddenProps> = ({ children, atAndAbove, below, invisible, id, ...props }) => (
  <HiddenParent
    atAndAbove={atAndAbove}
    below={below}
    data-testid={`hidden${atAndAbove ? `-atAndAbove:${atAndAbove}` : ''}${below ? `-below:${below}` : ''}`}
    data-hidden-below={below ?? ''}
    data-hidden-at-and-above={atAndAbove ?? ''}
    invisible={invisible}
    id={id}
    {...props}
  >
    {children}
  </HiddenParent>
);

Hidden.displayName = 'Hidden';
