import { COLOURS } from '@belong/themes';
import styled, { css } from 'styled-components';
import { TLevelOpen } from './index';

export const Wrapper = styled.span`
  width: 24px; // match default icon size
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Inner = styled.span`
  width: 18px;
  height: 12px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
`;

export const Line = styled.span<{ open?: boolean; secondLevelOpen?: TLevelOpen }>`
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: ${COLOURS.BELONG_BLUE};
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
`;

export const Line1 = styled(Line)`
  top: 0px;

  ${({ open }): any =>
    open &&
    css`
      top: 5px;
      width: 0%;
      left: 50%;
    `}
`;

export const Line2 = styled(Line)`
  top: 5px;

  ${({ open }): any =>
    open &&
    css`
      transform: rotate(45deg);
    `};

  ${({ secondLevelOpen }): any =>
    secondLevelOpen &&
    css`
      top: 8px;
      transform: rotate(45deg);
      width: 60%;
      left: 0;
    `};
`;

export const Line3 = styled(Line)`
  top: 5px;

  ${({ open }): any =>
    open &&
    css`
      transform: rotate(-45deg);
    `}

  ${({ secondLevelOpen }): any =>
    secondLevelOpen &&
    css`
      top: 2px;
      transform: rotate(-45deg);
      width: 60%;
      left: 0;
    `};
`;

export const Line4 = styled(Line)`
  top: 10px;

  ${({ open }): any =>
    open &&
    css`
      top: 5px;
      width: 0%;
      left: 50%;
    `}
`;
