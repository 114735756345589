import { ICON_NAMES } from '@belong/types';
import type { ITestable } from '@belong/types';
import { StyledError, StyledIcon } from './Error.styles';

export interface IErrorProps extends ITestable, React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  idError: string;
  onDarkBG?: boolean;
}

/**
 * The Error component can be used to provide feedback to the user about an error state in the page.
 */
export const Error: React.FC<IErrorProps> = ({ idError, children, onDarkBG, ...otherProps }) => {
  // If children is just a string which is whitespace, we don't want to render it
  if (typeof children === 'string' && !children.trim()) {
    return null;
  }

  return (
    <div data-testid={idError} {...otherProps}>
      <StyledError onDarkBG>
        <StyledIcon name={ICON_NAMES.Danger} size="small" aria-hidden="true" />
        <span id={idError}>{children}</span>
      </StyledError>
    </div>
  );
};

Error.displayName = 'Error';
