/*
 * Existing headers from storage:
 *   accessToken: the JWT returned by Auth0 during authentication, that is used to make sure a secure session for the user.
 *
 * New headers from storage (Added as part of NBN transformation project)
 *   idToken: the JWT returned by Auth0 during authentication is needed to be able to access all the API's in Classic Side
 *   octaneId: The unique utility id that is used to access each customer's Broadband/Nbn service info in Classic SF and TiAb.
 */
export enum HEADERS {
  ACCEPT = 'accept',
  ACCESS_TOKEN = 'accessToken',
  API_KEY = 'x-api-key',
  AUTHORIZATION = 'authorization',
  CACHE_CONTROL = 'cache-control',
  CONSUMER_ID = 'x-consumer-id',
  CONTENT_TYPE = 'content-type',
  CONTENT_DISPOSITION = 'Content-Disposition',
  CORRELATION_ID = 'x-correlation-id',
  ID_TOKEN = 'idToken',
  OCTANE_ID = 'octaneId',
  PRAGMA = 'pragma'
}
