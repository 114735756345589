import * as React from 'react';

import { Container, IContainerProps } from './styles';

interface IProps extends React.ComponentPropsWithRef<'div'>, IContainerProps {}

const UICard: React.FC<IProps> = React.forwardRef(({ children, hasShadow, containsForm, ...otherProps }, ref) => {
  if (containsForm && hasShadow) {
    throw new Error('Invalid props on UICard: pass EITHER containsForm or hasShadow but not both.');
  }

  return (
    <Container containsForm={containsForm} hasShadow={hasShadow} ref={ref} {...otherProps}>
      {children}
    </Container>
  );
});

UICard.displayName = 'UICard';

export default UICard;
