import React from 'react';
import { Entry } from 'contentful';

import { FLink, IEntryLink } from '@belong/contentful';
import withLink from '@belong/ui-components/components/hocs/withLink';
import { FONT_COLOURS } from '@belong/themes';
import type { ObjectValues } from '@belong/types';
import { Button } from '@belong/ui-components';
import { registerRichTextElements } from '@belong/ui-components/components/styles/Typography/RichText/RichText';
import useAnalyticsEvents from '@belong/providers/analyticsEvents';
import { embeddedLinkClick } from '@belong/analytics';
import { GenericAPI } from '@belong/api';
import { getConfig } from '@belong/configs/next/config';
import { INTERNAL_ROUTES } from './routes';

interface IElementLink {
  data: { target: Entry<IEntryLink> };
  hasColor: ObjectValues<typeof FONT_COLOURS>;
}
const ElementLink = ({ data, hasColor }: IElementLink) => {
  const analyticsEvents = useAnalyticsEvents();
  const { label, href, ...otherProps } = FLink(data.target);

  return (
    <Button
      {...otherProps}
      onClick={() => analyticsEvents.send(embeddedLinkClick(href, label))}
      href={href}
      isLightColor={!!hasColor && hasColor === FONT_COLOURS.LIGHT}
    >
      {label}
    </Button>
  );
};

registerRichTextElements({ elementLink: ElementLink });

// Configure link renderers
export const routeConfig = withLink.config.clone();

/**
 * Public page serves the homepage, but not with the base path tacked on.
 *
 * Removing Homepage from the routes list ensures that Next <Link> components
 * won't add the basepath to the homepage.
 *
 * @see https://nextjs.org/docs/api-reference/next.config.js/basepath#links
 */
const { HOME, ...ROUTES } = INTERNAL_ROUTES;

routeConfig.setRoutes(Object.values(ROUTES));
withLink.config = routeConfig;

GenericAPI.serviceConfig = {
  ...getConfig().publicRuntimeConfig.gateway,
  basePath: getConfig().publicRuntimeConfig.basePath
};
