import React, { ProviderProps, useContext } from 'react';
import { DictionaryContextType, DictionaryContext } from './context';

export type IProps = ProviderProps<DictionaryContextType>;

/**
 * Use to provide context to dictionary consumers within the component tree.
 * Can be nested multiple times and lookups will traverse up the tree until a
 * result is found.
 *
 * @example
 *
 * <Dictionary value={{ firstName: 'Jamie', lastName: 'Hoeks' }}>
 *   <Dictionary value={{ planCost: 70 }}>
 *     <App />
 *   </Dictionary>
 * </Dictionary>
 *
 * // Within App component
 * const applyDictionaryToString = useApplyDictionaryToString();
 * const input = 'Hello {{firstName}}, your new service will cost $${{planCost}} per month.';
 * const output = applyDictionaryToString(input);
 * <span>{output}</span>; // => Hello Jamie, your new service will cost $70 per month.
 */
export const Dictionary: React.FC<IProps> = ({ value, children }) => {
  const parent = useContext(DictionaryContext);
  return <DictionaryContext.Provider value={{ ...parent, ...value }}>{children}</DictionaryContext.Provider>;
};

export default Dictionary;
