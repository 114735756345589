import { FLAIR_COLOURS } from './variables';

// TODO: This should move to a new package perhaps `@belong/validation`.
export const isObject = (variable: any): boolean => {
  return variable && typeof variable === 'object' && variable.constructor === Object;
};

export const isUndefined = (variable: any): boolean => {
  return typeof variable === typeof undefined;
};

export const isDefined = <T>(data: T | undefined): data is T => !isUndefined(data) && !isNull(data);

export const isNull = (variable: any): boolean => {
  return variable === null;
};

export const isString = (variable: any): boolean => {
  return typeof variable === 'string';
};

export const isNumber = (variable: any): boolean => {
  return typeof variable === 'number';
};

export const hasValue = (variable: any): boolean => {
  return !isUndefined(variable) && !isNull(variable);
};

export const hasKey = (variable: Record<string, any>, value: any): boolean => {
  if (!isObject(variable)) {
    return false;
  }
  return Object.keys(variable).indexOf(value) !== -1;
};

export const isFlairColor = (key: string): key is keyof typeof FLAIR_COLOURS => {
  return key in FLAIR_COLOURS;
};
