import { getConfig } from '@belong/configs/next/config';

export const typesenseConfig = getConfig().publicRuntimeConfig.typesense;
export const CONFIG = {
  apiKey: typesenseConfig?.apiKey,
  host: typesenseConfig?.host,
  path: typesenseConfig?.path,
  port: typesenseConfig?.port,
  protocol: typesenseConfig?.protocol
};

/* search index config */
export const searchIndexName = 'belong-website';
export const searchOptions = {
  preserveSharedStateOnUnmount: true
};

export const RESULTS_PER_PAGE = 10;
export const QUERY_BY = [
  'hierarchy.lvl0',
  'hierarchy.lvl1',
  'hierarchy.lvl2',
  'hierarchy.lvl3',
  'content',
  'embedding'
].join(',');
export const VECTOR_QUERY = 'embedding:([], alpha: 0.4)';
export const EXCLUDE_FIELDS = 'embedding'; //  Don't return the raw floating point numbers in the vector field in the search API response, to save on network bandwidth.
// The number of tokens that should surround the highlighted text on each side.
export const TOKENS_NUMBER = 15;
