import isEmpty from 'lodash/isEmpty';
import type { GetServerSidePropsContext, NextPageContext } from 'next';
import { Cookies } from 'react-cookie';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import { COOKIES } from '@belong/constants';
import { decodeJwtToken } from '@belong/jwt';
import { ACCESS_TOKEN_EMAIL_FIELD, IAccessToken, ID_TOKEN_USER_METADATA_FIELD, USER_TYPE } from '@belong/types';
import type {
  IAgentCustomerCookie,
  IAuth,
  IContentLookup,
  INbnPromo,
  TAgentAuth,
  IPlatformConfig,
  ICookieSetOptions,
  IUserIdToken
} from '@belong/types';
import { logger } from '@belong/logging/logger';

import { getCookie } from './getCookie';

export const DEFAULT_SESSION_EXPIRATION = 3600;

const isBrowser = (): boolean => {
  return typeof window !== 'undefined' && typeof window.document !== 'undefined';
};

/**
 * Clear a browser cookie
 * Always use domain root for path
 */
export const clearCookie = (name: string): void => {
  new Cookies().remove(name, { path: '/' });
};

/**
 * Clear the Belong auth cookie
 * Always use domain root for path
 */
export const clearAuthCookie = (): void => {
  clearCookie(COOKIES.BELONG);
  clearCookie(COOKIES.BELONG_AGENT_CUSTOMER);
};

export const setAuthCookie = (session: IAuth, expiry: number): void => {
  new Cookies().set(COOKIES.BELONG, session, {
    path: '/',
    secure: false,
    expires: new Date(Date.now() + expiry * 1000)
  });
};

export const setMfaEnrolCookie = (mfaAuth: IAuth, expiry: number): void => {
  new Cookies().set(COOKIES.MFA_ENROL, mfaAuth, {
    path: '/',
    secure: false,
    expires: new Date(Date.now() + expiry * 1000)
  });
};

export const clearMfaEnrolCookie = (): void => {
  clearCookie(COOKIES.MFA_ENROL);
};

export const getAuthCookie = (cookies?: string): IAuth | Record<string, never> =>
  readCookie(COOKIES.BELONG, cookies) || {};
export const getMfaEnrolCookie = (cookies?: string): IAuth | Record<string, never> =>
  readCookie(COOKIES.MFA_ENROL, cookies) || {};
export const getNativeAppCookie = (cookies?: string): IPlatformConfig | string =>
  readCookie(COOKIES.NATIVE_APP, cookies);

// This function was created to assist development work
// please double check with app team if we use it for
// customer facing features
export const setNativeAppCookie = (value: IPlatformConfig | string, cookieOptions?: ICookieSetOptions): void => {
  let cookieValue: string;

  try {
    cookieValue = typeof value === 'string' ? value : JSON.stringify(value);
  } catch (error) {
    logger.error('Error - unable to stringify and set the provided value into native app cookie');
    return;
  }

  return new Cookies().set(COOKIES.NATIVE_APP, cookieValue, { path: '/', ...cookieOptions });
};

export const removeNativeAppCookie = (): void => {
  clearCookie(COOKIES.NATIVE_APP);
};

const getMissingProps = (requiredProps: string[], cookie): string[] => {
  return requiredProps.reduce<string[]>((accumulator, currentValue) => {
    if (Object.keys(cookie).includes(currentValue)) {
      return accumulator;
    }

    accumulator.push(currentValue);
    return accumulator;
  }, []);
};

export const validateAgentAuthCookie = (authCookie?: TAgentAuth): boolean => {
  const cookie = authCookie || readCookie(COOKIES.BELONG);

  // make sure cookie exists
  if (!cookie) {
    logger.error('Auth cookie not found');
    return false;
  }

  // check for tokens (native app cookie does not contain a correlationId yet)
  const requiredProps = ['idToken', 'accessToken', 'userType'];
  const missingProps = getMissingProps(requiredProps, cookie);

  if (missingProps.length > 0) {
    logger.error(`These required props [${missingProps}] are missing from AuthCookie`);
    return false;
  }

  return true;
};

export const validateAuthCookie = (authCookie?: IAuth): boolean => {
  const cookie = authCookie || readCookie(COOKIES.BELONG);

  // make sure cookie exists
  if (!cookie) {
    logger.error('Auth cookie not found');
    return false;
  }
  const { userType } = cookie;

  // check for tokens (native app cookie does not contain a correlationId yet)
  const requiredProps = ['idToken', 'accessToken'];
  const missingProps = getMissingProps(requiredProps, cookie);

  if (missingProps.length > 0) {
    logger.error(`These required props [${missingProps}] are missing from AuthCookie`);
    return false;
  }
  // check if the user is an agent
  if (userType === USER_TYPE.AGENT) {
    return validateAgentAuthCookie(cookie);
  }

  const decodedAccessToken = decodeJwtToken<IAccessToken>(cookie.accessToken);
  const { [ACCESS_TOKEN_EMAIL_FIELD]: email, sub: auth0Id } = decodedAccessToken || {};

  const hasAllProps = !!email && !!auth0Id;

  if (!hasAllProps) {
    logger.error('AccessToken has missing properties');
  }

  return hasAllProps;
};

export const validateMfaCookie = (mfaCookie?: IAuth): boolean => {
  const cookie = mfaCookie || readCookie(COOKIES.MFA_ENROL);

  if (!cookie) {
    logger.error('Mfa cookie not found');
    return false;
  }

  const requiredProps = ['accessToken', 'refreshToken', 'tokenType', 'expiresIn', 'clientId'];
  const missingProps = getMissingProps(requiredProps, cookie);

  if (missingProps.length > 0) {
    logger.error(`These required props [${missingProps}] are missing from MfaCookie`);
    return false;
  }

  return true;
};

/**
 * Defence against session sharing (BT-28524)
 * Validate cookie param matches browser cookie on the initial client render
 */
export function validateCredentialsFromServer(serverCookies: string, correlationId: string): boolean {
  const { accessToken: browserAccessToken, correlationId: browserCorrelationId } = getAuthCookie();
  if (!browserAccessToken) {
    return true;
  }

  const serverAuth = decodeURIComponent(
    serverCookies
      .split(/;\s*/)
      .filter(cookie => cookie.startsWith(`${COOKIES.BELONG}=`))
      .map(v => v.split('=').pop()?.toString())
      .pop()
      ?.toString() || ''
  );

  try {
    const { accessToken: serverAccessToken, correlationId: serverCorrelationId } = JSON.parse(serverAuth) as IAuth;
    if (serverAccessToken !== browserAccessToken) {
      logger.error(
        correlationId,
        'Server cookie does NOT match browser cookie',
        { serverAccessToken, serverCorrelationId },
        { browserAccessToken, browserCorrelationId }
      );
      return false;
    }
  } catch (err) {
    return true;
  }
  return true;
}

/**
 * Return value of a cookie
 * Automatically decodes and JSON parses cookie value if necessary
 *
 * WARNING: It will ALWAYS try to read from document.cookie in the browser
 */
export const readCookie = (name: string, cookieString?: string | object): any => {
  return new Cookies(cookieString).get(name);
};

export const writeCookie = (name: string, value: string, options?: ICookieSetOptions): any => {
  return new Cookies().set(name, value, options);
};

export const getFixedOctaneIdFromCookie = (correlationId: string): number | string => {
  if (!isBrowser()) {
    return '';
  }

  try {
    const cookie = readCookie(COOKIES.BELONG);

    if (cookie && cookie.userType === USER_TYPE.AGENT) {
      const agentCookie = readCookie(COOKIES.BELONG_AGENT_CUSTOMER);
      return agentCookie?.octaneId ?? '';
    }

    if (cookie && cookie?.idToken) {
      const decodedIdToken = decodeJwtToken<IUserIdToken>(cookie.idToken);

      if (!decodedIdToken) {
        return '';
      }

      return decodedIdToken[ID_TOKEN_USER_METADATA_FIELD].fixedOctaneId || '';
    }
  } catch (error) {
    logger.error(correlationId, 'Error getting fixed octaneId', error);
  }

  return '';
};

export const isCustomerLoggedIn = (): boolean => {
  const authCookie = getAuthCookie();
  if (isEmpty(authCookie)) {
    logger.debug('Auth cookie not found');
    return false;
  }

  const { userType } = authCookie;

  if (userType === USER_TYPE.AGENT) {
    const agentCustomerCookie = readCookie(COOKIES.BELONG_AGENT_CUSTOMER);
    return !!agentCustomerCookie;
  }

  return true;
};

export const setCookieExpireTime = (cookieMins: number): Date => {
  const date = new Date();
  date.setTime(date.getTime() + cookieMins * 60 * 1000);
  return date;
};

export const setAgentCustomerCookie = (values: IAgentCustomerCookie): void => {
  new Cookies().set(COOKIES.BELONG_AGENT_CUSTOMER, values, {
    path: '/',
    expires: setCookieExpireTime(60)
  });
};

export const getContentLookupCookie = (
  ctx?: NextPageContext | GetServerSidePropsContext
): IContentLookup | undefined => {
  return getCookie<IContentLookup>(COOKIES.CONTENT_LOOKUP, ctx, { parse: true });
};

export const setContentLookupCookie = (values: IContentLookup): void => {
  new Cookies().set(COOKIES.CONTENT_LOOKUP, values, {
    path: '/',
    expires: setCookieExpireTime(60)
  });
};

export const updateContentLookupCookie = (contentLookup: IContentLookup): void => {
  const contentLookupCookie = getContentLookupCookie();
  const cookie = omitBy({ ...contentLookupCookie, ...contentLookup }, isNil);
  if (isEmpty(cookie)) {
    clearCookie(COOKIES.CONTENT_LOOKUP);
  } else {
    setContentLookupCookie(cookie);
  }
};

export const getContentLookupKey = (ctx?: NextPageContext | GetServerSidePropsContext): string => {
  let nbnContentLookupCookie;
  try {
    nbnContentLookupCookie = getContentLookupCookie(ctx);
  } catch {
    logger.error('Read and parse cookie error');
  }

  const productName = nbnContentLookupCookie?.productName || '';
  const campaignCode = nbnContentLookupCookie?.campaignCode || '';
  const promoCode = nbnContentLookupCookie?.promoCode || '';
  const stackInfo = nbnContentLookupCookie?.stackInfo || '';
  // Currently this will take the implicit promo over the explicit. However we are currently not stacking, when we do, this will need to be revisted.
  return nbnContentLookupCookie ? `${productName} ${campaignCode} ${promoCode} ${stackInfo}`.replace(/\s/g, '') : '';
};

export const clearContentLookupCookieForPlan = (): void => {
  const contentLookupCookie = getContentLookupCookie();
  if (contentLookupCookie?.stackInfo) {
    const { stackInfo } = contentLookupCookie;
    setContentLookupCookie({ stackInfo });
  } else {
    clearCookie(COOKIES.CONTENT_LOOKUP);
  }
};

export const setNbnPromoCookie = (values: INbnPromo): void => {
  new Cookies().set(COOKIES.NBN_PROMO, values, {
    path: '/',
    expires: setCookieExpireTime(60)
  });
};

export const getNbnPromoCookie = (ctx?: NextPageContext): INbnPromo | undefined => {
  return getCookie<INbnPromo>(COOKIES.NBN_PROMO, ctx, { parse: true });
};

export const getAgentCustomerCookie = (cookies?: string | object): IAgentCustomerCookie =>
  readCookie(COOKIES.BELONG_AGENT_CUSTOMER, cookies) || {};

export const clearAgentCustomerCookie = (): void => {
  clearCookie(COOKIES.BELONG_AGENT_CUSTOMER);
};

export const getUserType = (): USER_TYPE | undefined => {
  if (!isBrowser()) {
    return;
  }
  const cookie = readCookie(COOKIES.BELONG);
  return cookie?.userType;
};
