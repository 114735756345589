import { useState, useRef, createRef, useEffect, useCallback, Fragment } from 'react';
import kebabCase from 'lodash/kebabCase';

import { ICON_NAMES, IOrganismMenuItem, USER_TYPE } from '@belong/types';
import {
  KEYBOARD_KEYS,
  Logo as BelongLogo,
  A11yCopy,
  RichText,
  DropdownWrapper,
  AccentStrip
} from '@belong/ui-components';
import { SearchPanel } from '@belong/search';
import { IGlobalHeaderDesktop, THeaderDropdownWrapper, CONTAINERS } from './types';
import { GLOBAL_HEADER_TEST_ID as TEST_ID } from '../testids';
import AgentHeader from './AgentHeader';
import HeaderBarButton from './HeaderBarButton';
import { HeaderDropdown } from './HeaderDropdown';
import { useHeaderVisibility } from '../hooks/useHeaderVisibility';
import * as styles from './styles';

export const GlobalHeaderDesktop = ({
  headerMenuContent,
  currentUrl,
  isLoggedIn,
  userType,
  pageAlert,
  hideMyAccountLinks,
  urls
}: IGlobalHeaderDesktop): JSX.Element => {
  const [dropdownContainer, setDropdownContainer] = useState<THeaderDropdownWrapper>(CONTAINERS.CONTAINER_MAIN);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [menuContent, setMenuContent] = useState<IOrganismMenuItem | undefined>(undefined);
  const currentMenuItemIdx = useRef<number | undefined>(undefined);
  const [menuItemID, setMenuItemID] = useState<string | undefined>(undefined);
  const navHeaderRef = useRef<HTMLDivElement>(null);
  const menuItemRef = useRef<React.RefObject<HTMLButtonElement>[]>(createMenuItemRefs());
  const accountMenuButtonRef = useRef<HTMLButtonElement>(null);
  const headerVisibility = useHeaderVisibility(navHeaderRef, styles.HEIGHT);
  const isUserAgent = userType === USER_TYPE.AGENT;

  const searchMenuLabel = 'Search';
  const pressedKeys = useRef<string[]>([]);
  const KEY_MODIFIER = 'Shift';

  function createMenuItemRefs() {
    const refs: React.RefObject<HTMLButtonElement>[] = [];
    if (headerMenuContent) {
      headerMenuContent.headerMenuItems.forEach(() => {
        refs.push(createRef<HTMLButtonElement>());
      });
      // push an extra ref for the search button
      refs.push(createRef<HTMLButtonElement>());
    }

    return refs;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeHeader = ({ previousMenuItem = false }: { previousMenuItem?: boolean } = {}) => {
    if (isDropdownOpen) {
      (window.document.activeElement as HTMLElement)?.blur?.();
      restoreFocus({ nextMenuItem: false, previousMenuItem });

      setIsDropdownOpen(false);
    }
  };

  const focusMenuItem = (menuItemIdx: number) => {
    menuItemRef?.current[menuItemIdx]?.current?.focus();
  };

  const parseMenuItemlabel = (label: string) => {
    return `${TEST_ID.GLOBAL_HEADER_BUTTON_PREFIX}${kebabCase(label).toLowerCase()}`;
  };

  /**
   *
   * The purpose of this function is to determine where focus should go at any given time, mostly after the menu closing.
   * For now it is a little complex due to the feature toggle. After the toggle is removed it would be worth revisiting the logic to find ways of simplifying it.
   *
   * @nextMenuItem - if true, the focus will move to the next menu item
   * @previousMenuItem - if true, the focus will move to the previous menu item
   */

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restoreFocus = ({
    nextMenuItem = false,
    previousMenuItem = false
  }: {
    nextMenuItem?: boolean;
    previousMenuItem?: boolean;
  } = {}) => {
    // this finds the nav bar item that focus should return to
    if (headerMenuContent && currentMenuItemIdx.current !== undefined) {
      let onBlurMenuItem = '';
      if (dropdownContainer === CONTAINERS.CONTAINER_MAIN || dropdownContainer === CONTAINERS.CONTAINER_SEARCH) {
        // menu items which don't have children are wrapped with hasLink meaning they don't support refs
        // use the id instead so that the focus can be managed by all the buttons in the header
        if (nextMenuItem && currentMenuItemIdx.current === headerMenuContent.headerMenuItems.length) {
          onBlurMenuItem = parseMenuItemlabel(headerMenuContent.loginLabel); // at the end of the menu so focus on login link
          setDropdownContainer(CONTAINERS.CONTAINER_ACCOUNT);
        } else {
          // this gets a little complex because the search menu item is not part of the headerMenuItems array
          // the blurMenuItem is set to the search menu item if we're at the end of the header menu
          // otherwise it's set to the current menu item or the next header menu item if the 'nextMenuItem' argument is true
          onBlurMenuItem =
            currentMenuItemIdx.current < headerMenuContent.headerMenuItems.length
              ? parseMenuItemlabel(
                  headerMenuContent.headerMenuItems[
                    nextMenuItem ? currentMenuItemIdx.current + 1 : currentMenuItemIdx.current
                  ]?.parentLabel || searchMenuLabel
                )
              : parseMenuItemlabel(searchMenuLabel);

          if (nextMenuItem) {
            currentMenuItemIdx.current += 1;
          }
        }
        if (previousMenuItem) {
          onBlurMenuItem = parseMenuItemlabel(
            currentMenuItemIdx.current > 1
              ? headerMenuContent.headerMenuItems[currentMenuItemIdx.current - 1].parentLabel
              : headerMenuContent.belongHome.label
          );
        }
      }

      if (dropdownContainer === CONTAINERS.CONTAINER_ACCOUNT) {
        onBlurMenuItem = parseMenuItemlabel(headerMenuContent.loginLabel); // if we're not a headerMenuItem we must be the login link
        setIsDropdownOpen(false);
      }
      document.getElementById(onBlurMenuItem)?.focus();
    }
  };

  const handleEventMenuWithChildren = (menuItemIdx: number, newMenuContent: IOrganismMenuItem) => {
    if (isDropdownOpen) {
      if (currentMenuItemIdx.current === menuItemIdx) {
        closeHeader();
        return;
      }
    } else {
      setIsDropdownOpen(true);
    }

    setMenuItemID(parseMenuItemlabel(newMenuContent.parentLabel));
    currentMenuItemIdx.current = menuItemIdx;

    setDropdownContainer(CONTAINERS.CONTAINER_MAIN);
    focusMenuItem(menuItemIdx);
    setMenuContent(newMenuContent);
  };

  const handleSearchButtonClick = (menuItemIdx: number) => {
    if (isDropdownOpen) {
      if (currentMenuItemIdx.current === menuItemIdx) {
        closeHeader();
        return;
      }
    } else {
      setIsDropdownOpen(true);
    }

    setMenuItemID(parseMenuItemlabel('search'));
    currentMenuItemIdx.current = menuItemIdx;

    setDropdownContainer(CONTAINERS.CONTAINER_SEARCH);
    focusMenuItem(menuItemIdx);
  };

  const handleEventAccountMenu = () => {
    (window.document.activeElement as HTMLElement)?.blur?.();
    currentMenuItemIdx.current = headerMenuContent.headerMenuItems.length + 1;
    setDropdownContainer(CONTAINERS.CONTAINER_ACCOUNT);
    setIsDropdownOpen(true);
    accountMenuButtonRef.current?.focus();
  };

  const handleKeyPress = useCallback(
    ({ key }: KeyboardEvent): void => {
      if (key === 'Escape') {
        restoreFocus();
        closeHeader();
      }
    },
    [restoreFocus, closeHeader]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  // the onLastLinkBlur function is called for keyboard interaction when the focus leaves forward from the last link in the dropdown
  const onLastLinkBlur = (): void => {
    if (pressedKeys.current.pop() === 'Tab') {
      closeHeader();
      restoreFocus({ nextMenuItem: dropdownContainer === CONTAINERS.CONTAINER_MAIN });
      pressedKeys.current = [];
    }
  };

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const { key } = event;

    // Save pressed keys when focus is in the last active item within the get connected section
    if (
      key === KEYBOARD_KEYS.TAB ||
      key === KEYBOARD_KEYS.ENTER ||
      (event.getModifierState(KEY_MODIFIER) && key === KEYBOARD_KEYS.TAB)
    ) {
      pressedKeys.current.push(event.getModifierState(KEY_MODIFIER) ? `${KEY_MODIFIER}+${key}` : key);
    }
  };

  return (
    <>
      <styles.Root data-testid={TEST_ID.DESKTOP} isUserAgent={isUserAgent}>
        <styles.Screen
          data-testid={TEST_ID.OFFCANVAS}
          aria-hidden={!isDropdownOpen}
          isExpanded={isDropdownOpen}
          top={(navHeaderRef.current as HTMLDivElement)?.clientHeight}
          onClick={() => closeHeader()}
        />
        <styles.NavHeader isHidden={headerVisibility.isHidden && !isDropdownOpen} ref={navHeaderRef}>
          {isUserAgent ? <AgentHeader /> : null}
          {pageAlert && (
            <styles.StyledAlert {...pageAlert}>
              {pageAlert.content ? <RichText html={pageAlert.content} /> : pageAlert.children}
            </styles.StyledAlert>
          )}
          <styles.HeaderBar data-testid={TEST_ID.HEADER_BAR}>
            <styles.StyledContainer>
              <styles.LogoWrapper
                href={headerMenuContent.belongHome.href}
                data-testid={TEST_ID.BUTTON_HOME}
                onClick={closeHeader}
              >
                <BelongLogo />
                <A11yCopy>{headerMenuContent.belongHome.label}</A11yCopy>
              </styles.LogoWrapper>
              <styles.LinkWrapper>
                <styles.LeftLinks>
                  {headerMenuContent.headerMenuItems.map((menuItem, idx) => {
                    const hasNavGroup = menuItem.navGroup1 || menuItem.navGroup2;

                    return (
                      <Fragment key={menuItem.identifier}>
                        <HeaderBarButton
                          aria-expanded={
                            hasNavGroup && dropdownContainer === CONTAINERS.CONTAINER_MAIN
                              ? menuContent === menuItem && isDropdownOpen
                              : undefined
                          }
                          aria-current={
                            currentUrl !== undefined && currentUrl === menuItem.url?.href ? 'page' : undefined
                          }
                          data-testid={parseMenuItemlabel(menuItem.parentLabel)}
                          label={menuItem.parentLabel}
                          onClick={() => (hasNavGroup ? handleEventMenuWithChildren(idx, menuItem) : closeHeader())}
                          onBlur={() => {
                            if (pressedKeys.current[pressedKeys.current.length - 1] === 'Shift+Tab') {
                              closeHeader({ previousMenuItem: true });
                            }
                          }}
                          onKeyDown={onInputKeyDown}
                          onFocus={() => {
                            if (pressedKeys.current[pressedKeys.current.length - 1] === 'Enter' && isDropdownOpen) {
                              setIsDropdownOpen(false);
                            }
                            pressedKeys.current = [];
                          }}
                          ref={menuItemRef.current[idx]}
                          iconName={hasNavGroup ? ICON_NAMES.ChevronDown : undefined}
                          iconRight
                          rotateIconOnHover
                          id={parseMenuItemlabel(menuItem.parentLabel)}
                          href={menuItem.url ? menuItem.url.href : undefined}
                          isRightLink={headerMenuContent.headerMenuItems.length === idx + 1}
                        />

                        {dropdownContainer === CONTAINERS.CONTAINER_MAIN &&
                          currentMenuItemIdx &&
                          currentMenuItemIdx.current === idx && (
                            <DropdownWrapper
                              isExpanded={isDropdownOpen}
                              data-testid={TEST_ID.DROPDOWN_WRAPPER}
                              aria-labelledby={menuItemID}
                            >
                              <HeaderDropdown
                                headerMenuContent={headerMenuContent}
                                dropdownMenuContent={menuContent}
                                currentUrl={currentUrl}
                                dropdownWrapper={dropdownContainer}
                                onLinkClick={closeHeader}
                                hideMyAccountLinks={hideMyAccountLinks}
                                urls={urls}
                                onBlur={onLastLinkBlur}
                                onKeyDown={onInputKeyDown}
                              />
                            </DropdownWrapper>
                          )}
                      </Fragment>
                    );
                  })}
                  <>
                    <HeaderBarButton
                      aria-current={currentUrl === 'go/search' ? 'page' : undefined}
                      aria-expanded={dropdownContainer === CONTAINERS.CONTAINER_SEARCH && isDropdownOpen}
                      aria-label={`${searchMenuLabel} menu`}
                      data-testid={TEST_ID.BUTTON_SEARCH}
                      label={searchMenuLabel}
                      iconName={ICON_NAMES.Search}
                      onClick={() => handleSearchButtonClick(menuItemRef.current.length - 1)}
                      ref={menuItemRef.current[menuItemRef.current.length - 1]} // it's the last one added to the ref array
                      id={parseMenuItemlabel(searchMenuLabel)}
                      onBlur={() => {
                        if (pressedKeys.current[pressedKeys.current.length - 1] === 'Shift+Tab') {
                          closeHeader({ previousMenuItem: true });
                        }
                      }}
                      onKeyDown={event => {
                        const { key } = event;
                        if (event.getModifierState(KEY_MODIFIER) && key === KEYBOARD_KEYS.TAB) {
                          closeHeader();
                        }
                      }}
                    />
                    {dropdownContainer === CONTAINERS.CONTAINER_SEARCH && (
                      <DropdownWrapper
                        isExpanded={isDropdownOpen}
                        data-testid={TEST_ID.DROPDOWN_WRAPPER}
                        aria-labelledby={parseMenuItemlabel(searchMenuLabel)}
                      >
                        <styles.StyledSearchContainer>
                          <SearchPanel callback={() => closeHeader()} data-testid="desktop-search-panel" />
                        </styles.StyledSearchContainer>
                        <AccentStrip isBottom />
                      </DropdownWrapper>
                    )}
                  </>
                </styles.LeftLinks>
                {headerMenuContent && isLoggedIn ? (
                  <>
                    <HeaderBarButton
                      aria-current={currentUrl === urls.login ? 'page' : undefined}
                      aria-expanded={dropdownContainer === CONTAINERS.CONTAINER_ACCOUNT && isDropdownOpen}
                      aria-label="My Account"
                      data-testid={TEST_ID.BUTTON_DROPDOWN_MY_ACCOUNT}
                      hideUnderline
                      iconName={headerMenuContent.myProfileIcon?.icon}
                      onClick={() => (isDropdownOpen ? closeHeader() : handleEventAccountMenu())}
                      onFocus={() => {
                        if (isDropdownOpen) {
                          setIsDropdownOpen(false);
                        }
                      }}
                      ref={accountMenuButtonRef}
                      id={parseMenuItemlabel(headerMenuContent.loginLabel)}
                    />
                    {dropdownContainer === CONTAINERS.CONTAINER_ACCOUNT && (
                      <DropdownWrapper
                        isExpanded={isDropdownOpen}
                        data-testid={TEST_ID.DROPDOWN_WRAPPER}
                        aria-labelledby={parseMenuItemlabel(headerMenuContent.loginLabel)}
                      >
                        <HeaderDropdown
                          headerMenuContent={headerMenuContent}
                          dropdownMenuContent={menuContent}
                          currentUrl={currentUrl}
                          dropdownWrapper={dropdownContainer}
                          onLinkClick={closeHeader}
                          hideMyAccountLinks={hideMyAccountLinks}
                          urls={urls}
                          onBlur={onLastLinkBlur}
                          onKeyDown={onInputKeyDown}
                        />
                      </DropdownWrapper>
                    )}
                  </>
                ) : (
                  headerMenuContent.loginLabel && (
                    // login button
                    <HeaderBarButton
                      aria-current={currentUrl === urls.login ? 'page' : undefined}
                      aria-expanded={dropdownContainer === CONTAINERS.CONTAINER_ACCOUNT && isDropdownOpen}
                      aria-label="My Account"
                      data-testid={TEST_ID.BUTTON_LOGIN}
                      href={urls.login}
                      iconName={headerMenuContent.loginIcon?.icon}
                      label={headerMenuContent.loginLabel}
                      ref={accountMenuButtonRef}
                      id={parseMenuItemlabel(headerMenuContent.loginLabel)}
                      onFocus={() => {
                        if (isDropdownOpen) {
                          setIsDropdownOpen(false);
                        }
                      }}
                    />
                  )
                )}
              </styles.LinkWrapper>
            </styles.StyledContainer>
          </styles.HeaderBar>
        </styles.NavHeader>
      </styles.Root>
    </>
  );
};

GlobalHeaderDesktop.displayName = 'GlobalHeaderDesktop';
export default GlobalHeaderDesktop;
