import { useState } from 'react';
import { Cookies } from 'react-cookie';

import { COOKIES } from '@belong/constants';
import { decodeJwtToken } from '@belong/jwt';
import type { IUserIdToken } from '@belong/types';

import createProvider from '../_factory';

interface IProvider {
  user: IUserIdToken | null;
  setUser: (newValue: IUserIdToken) => void;
}

type hook = () => /* this hook takes no args */ IProvider;

const useUser: hook = () => {
  const cookies = new Cookies();
  const token = cookies.get(COOKIES.BELONG);
  let userData: IUserIdToken | null = null;

  if (token) {
    userData = decodeJwtToken<IUserIdToken>(token.idToken) || null;
  }

  const [user, setUser] = useState<IUserIdToken | null>(userData);

  return {
    user,
    setUser
  };
};

const { hook, provider } = createProvider<hook>(useUser, 'useUser');

export const Provider = provider;

export default hook;
