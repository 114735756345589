import { MaybeStyledCSS, StyledCSS } from '@belong/types/ui';
import { FONT_WEIGHT, FONT_COLOURS, media } from '@belong/themes';
import styled, { css } from 'styled-components';
import { Text } from '../Text';
import { ICopy } from './Copy.types';

export const Copy = styled(Text).attrs((props: ICopy) => ({
  hasColor: props.hasColor,
  variant: props.variant || 'medium',
  as: props.as ? props.as : 'p'
}))<ICopy>`
  ${({ isBold, hasColor }): MaybeStyledCSS =>
    isBold
      ? css`
          font-weight: ${FONT_WEIGHT.SEMI_BOLD};
          color: ${hasColor === FONT_COLOURS.BASE && FONT_COLOURS.STRONG};
        `
      : css`
          font-weight: ${FONT_WEIGHT.REGULAR};

          > strong {
            font-weight: ${FONT_WEIGHT.SEMI_BOLD};
            color: ${hasColor === FONT_COLOURS.BASE && FONT_COLOURS.STRONG};
          }
        `}

  ${({ theme, hasColor }) =>
    !hasColor &&
    css`
      color: ${theme.foregroundColor};
      > strong {
        color: ${theme.foregroundColor};
      }
    `};

  ${({ variant, hasColor, theme }): StyledCSS => {
    switch (variant) {
      case 'small':
        return css`
          font-size: 1.5rem;
          line-height: 2rem;

          ${!hasColor &&
          css`
            &,
            & > strong {
              color: ${theme.foregroundColorLight};
            }
          `};
        `;

      default:
      case 'medium':
        return css`
          font-size: 1.7rem;
          line-height: 2.4rem;
        `;

      case 'large':
        return css`
          font-size: 1.7rem;
          line-height: 2.4rem;

          ${media('md')`
            font-size: 2rem;
            line-height: 2.8rem;
          `}
        `;
    }
  }}
`;

Copy.displayName = 'Copy';
