import { useCallback, useEffect, useState } from 'react';

import type { IVisibility } from '@belong/types';

export const useBackToTopVisibility = (threshold: number): IVisibility => {
  const [isHidden, setIsHidden] = useState(threshold > 0);

  const handleEvent = useCallback(() => {
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const foldsScrolled = window.scrollY / screenHeight;

    setIsHidden(threshold > foldsScrolled);
  }, [threshold]);

  useEffect(() => {
    window.addEventListener('scroll', handleEvent);
    window.addEventListener('resize', handleEvent);

    return () => {
      window.addEventListener('scroll', handleEvent);
      window.addEventListener('resize', handleEvent);
    };
  }, [handleEvent]);

  return { isHidden };
};
