import { BORDER_RADIUS, COLOURS, isFocused, width } from '@belong/themes';
import { StyledCSS, ITestable } from '@belong/types';
import styled, { css } from 'styled-components';
import TextInputUnstyled from '../TextInputUnstyled';
import { ITextInput } from './TextInput.types';

export const baseStyles = css`
  padding: 1.1rem 1.2rem;
  border: 0.1rem solid;
  border-radius: ${BORDER_RADIUS.INPUT};
  transition:
    0.2s background-color ease,
    0.2s border-color ease;
`;

const extractProps = props => ({ type: props.type || 'text' });
export const TextInputWrapper = styled.div<Pick<ITextInput, 'inputSymbolPrefix'> & ITestable>`
  ${props =>
    props.inputSymbolPrefix &&
    css`
      position: relative;
      display: inline-block;
      &:before {
        content: '${props.inputSymbolPrefix}';
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    `}
`;

export const colourVariations = {
  // default
  default: {
    backgroundColor: COLOURS.GREY_100,
    borderColor: COLOURS.GREY_600,
    color: COLOURS.GREY_600,
    hover: {
      backgroundColor: COLOURS.GREY_100,
      borderColor: COLOURS.GREY_600,
      color: COLOURS.BLACK
    },
    focus: {
      backgroundColor: COLOURS.GREY_100
    },
    isError: {
      color: COLOURS.BLACK,
      backgroundColor: COLOURS.LIGHT_RED,
      borderColor: COLOURS.DARK_RED
    }
  },
  // isWhiteOnGrey variation
  isWhiteOnGrey: {
    backgroundColor: COLOURS.WHITE,
    borderColor: COLOURS.GREY_600,
    color: COLOURS.GREY_600,
    hover: {
      backgroundColor: COLOURS.GREY_100,
      borderColor: COLOURS.GREY_600,
      color: COLOURS.BLACK
    },
    focus: {
      backgroundColor: COLOURS.GREY_100
    },
    isError: {
      color: COLOURS.BLACK,
      backgroundColor: COLOURS.LIGHT_RED,
      borderColor: COLOURS.DARK_RED
    }
  },
  // onDarkBG variation
  onDarkBG: {
    backgroundColor: COLOURS.GREY_700,
    borderColor: COLOURS.GREY_400,
    color: COLOURS.GREY_400,
    hover: {
      backgroundColor: COLOURS.GREY_700,
      borderColor: COLOURS.GREY_300,
      color: COLOURS.GREY_400
    },
    focus: {
      backgroundColor: COLOURS.GREY_700,
      borderColor: COLOURS.GREY_300,
      color: COLOURS.GREY_400
    },
    isError: {
      color: COLOURS.GREY_400,
      backgroundColor: COLOURS.GREY_700,
      borderColor: COLOURS.ALT_RED
    }
  }
};

/**
 * Note: By default type of TextInput is 'text'
 */

const getVariant = (props: ITextInput) => {
  let variant = colourVariations.default;
  if (props.isWhiteOnGrey) {
    variant = colourVariations.isWhiteOnGrey;
  }
  if (props.onDarkBG) {
    variant = colourVariations.onDarkBG;
  }
  return variant;
};

export const TextInput = styled(TextInputUnstyled)
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => !['onDarkBG'].includes(prop) && defaultValidatorFn(prop)
  })
  .attrs(extractProps)<ITextInput>`
  ${(props: ITextInput): StyledCSS<ITextInput> => {
    const variant = getVariant(props);

    return css`
      border-color: ${variant.borderColor};
      color: ${variant.color};
      display: block;
      text-align: left;
      max-width: 100%;
      ${baseStyles};
      background-color: ${variant.backgroundColor};
      ${width};

      &:hover {
        background-color: ${variant.hover.backgroundColor};
        border-color: ${variant.hover.borderColor};
        color: ${variant.hover.color};
      }

      &:focus {
        background-color: ${variant.focus.backgroundColor};
        ${isFocused()};
      }

      // prevent safari autofill styles
      &::-webkit-contacts-auto-fill-button,
      &::-webkit-credentials-auto-fill-button {
        visibility: hidden;
        display: none !important;
        pointer-events: none;
        height: 0;
        width: 0;
        margin: 0;
      }

      ${props.isFocused &&
      css`
        background-color: ${variant.focus.backgroundColor};
        ${isFocused()};
      `}

      ${!!props.inputSymbolPrefix &&
      css`
        padding-left: 2rem;
      `}

    ${props.isError &&
      css`
        color: ${variant.isError.color};
        background-color: ${variant.isError.backgroundColor};
        border-color: ${variant.isError.borderColor};
        &,
        &:hover {
          background-color: ${variant.isError.backgroundColor};
          border-color: ${variant.isError.borderColor};
        }
      `}
    `;
  }}
`;

export default TextInput;
