import { Cookies } from 'react-cookie';

import { COOKIES } from '@belong/constants';

import { FEATURES, OVERRIDE_COOKIE } from '../constants';
import localToggles from '../data/local.json';
import type { IFeatureToggles } from '../types';

/**
 * Check for toggle overrides in local.json or toggle-override cookie and apply new
 * settings.
 *
 * Example cookie:
 *  Name: toggle-overrides, Value: 'planned-outage-notification=on'
 *  Note:
 *    override state can be 'true' or 'on'
 *    any other values are considered as 'false'
 */
export const applyOverrides = (
  correlationId: string,
  featuresMap: IFeatureToggles,
  cookie?: string
): IFeatureToggles => {
  const values = { ...featuresMap };

  const parsedCookie = new Cookies(cookie);
  const browserTesting = parsedCookie.get(COOKIES.AUTOMATED_BROWSER_TESTING);

  if (browserTesting !== 'playwright' && process.env.NODE_ENV === 'development') {
    Object.assign(values, localToggles);
  }

  const toggleOverrides = parsedCookie.get(OVERRIDE_COOKIE) || '';
  const toggles = toggleOverrides.split(/\s*,\s*/).filter(Boolean);

  if (toggles && toggles.length > 0) {
    const featureNames = Object.values(FEATURES);
    toggles.forEach(override => {
      const [name, state] = override.split('=');
      if (name && featureNames.includes(name)) {
        const value = ['on', 'true'].includes(state.toLowerCase());
        // eslint-disable-next-line no-console
        console.info(
          `${correlationId} %c🚨 Override FeatureToggle: [${name}: ${value}]`,
          'color:darkred; font-weight:600; background-color:#8FEAEA; padding:2px 6px'
        );
        values[name] = value;
      }
    });
  }

  return values;
};
