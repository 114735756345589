import { ICON_NAMES } from '@belong/types';
import { parseLinkData } from '../../helpers/parseLinkData';

export interface INavLink {
  id: string;
  label: string;
  href: string;
  navLinkIcon?: ICON_NAMES;
  'aria-label'?: string;
}

export const FNavLink = (data: any): INavLink => ({
  id: data.sys.id,
  label: data.fields.label,
  'aria-label': data.fields.a11yLabel,
  navLinkIcon: data.fields.navLinkIcon && data.fields.navLinkIcon,
  ...parseLinkData(data.fields.url)
});
