import styled, { css } from 'styled-components';

import { FONT_COLOURS, getAspectRatio, getPaddingTop } from '@belong/themes';
import { IMAGE_ASPECT_RATIO, MaybeStyledCSS } from '@belong/types';
import { Copy } from '../../styles/Typography/Copy';

export const Container = styled.span<{ useOriginalImageSize?: boolean }>`
  display: block;
  ${({ useOriginalImageSize }): MaybeStyledCSS =>
    useOriginalImageSize
      ? css`
          line-height: 0;
        `
      : ''};
`;

export const AspectRatioContainer = styled.span<{ ratio: IMAGE_ASPECT_RATIO }>`
  aspect-ratio: ${({ ratio }): string => getAspectRatio(ratio)};
  display: block;
  position: relative;
  width: 100%;

  @supports not (aspect-ratio: 16 / 9) {
    &::before {
      display: block;
      content: '';
      width: 100%;

      // control aspect ratio (default to 16:9)
      padding-top: ${({ ratio }): string => getPaddingTop(ratio)};
    }
  }

  img,
  iframe {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;

export const Caption = styled(Copy).attrs({
  as: 'span',
  hasColor: FONT_COLOURS.FINE_PRINT,
  variant: 'small'
})`
  display: block;
  margin-top: 0.8rem;
`;
