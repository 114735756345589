import React, { FunctionComponent } from 'react';

import { COLOURS } from '@belong/themes';
import type { IFooterMenu } from '@belong/types';

import Container from '../../layout/Container';
import { RichText } from '../../styles/Typography/RichText/RichText';
import * as GFooter from './GlobalFooter.styles';
import NavLinksSocial from '../../actions/NavLinksSocial';
import { GLOBAL_FOOTER } from '../../../helpers/testIds';
import { Column, Columns, Stack } from '../../ui';
import GlobalFooterNavLinkGroup from './GlobalFooterNavLinkGroup';

const GlobalFooter: FunctionComponent<IFooterMenu> = ({
  footerMenuItems,
  socialLinks,
  firstNationsAcknowledgement,
  belongAbnDetails
}) => {
  return (
    <GFooter.Footer data-testid={GLOBAL_FOOTER}>
      <Container>
        <Stack space="xlarge">
          <Columns collapseBelow="lg">
            <Column width={{ lg: '2/3' }}>
              <Columns collapseBelow="sm" space={'xlarge'}>
                {footerMenuItems &&
                  footerMenuItems.map((menuItem): JSX.Element | undefined => {
                    const navLinkGroup = menuItem.navGroup1?.[0];
                    return (
                      navLinkGroup && (
                        <Column key={navLinkGroup.id} width={{ sm: '1/3' }}>
                          <GlobalFooterNavLinkGroup navLinkGroup={navLinkGroup} />
                        </Column>
                      )
                    );
                  })}
              </Columns>
            </Column>
            <Column width={{ lg: '1/3' }} alignX={{ lg: 'right' }}>
              <nav aria-label="Social media">
                <GFooter.FooterMenuSocialLinksWrapper>
                  {socialLinks &&
                    socialLinks.map(link => (
                      <li key={link.label}>
                        <NavLinksSocial {...link} />
                      </li>
                    ))}
                </GFooter.FooterMenuSocialLinksWrapper>
              </nav>
            </Column>
          </Columns>
          <GFooter.CompanyInformationColumns>
            <Column width={{ lg: '7/12' }}>
              <Columns collapseBelow="sm" space={'xlarge'}>
                <Column width={{ sm: '1/2' }}>
                  <RichText {...firstNationsAcknowledgement} hasColor={COLOURS.GREY_400} p="copySmall" />
                </Column>
                <Column width={{ sm: '1/2' }}>
                  <RichText {...belongAbnDetails} hasColor={COLOURS.GREY_400} p="copySmall" />
                </Column>
              </Columns>
            </Column>
          </GFooter.CompanyInformationColumns>
        </Stack>
      </Container>
    </GFooter.Footer>
  );
};

GlobalFooter.displayName = 'GlobalFooter';
export default GlobalFooter;
