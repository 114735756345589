import { getCookie as readCookie } from 'cookies-next';
import type { GetServerSidePropsContext, NextPageContext } from 'next';

import { COOKIES } from '@belong/constants';
import { logger } from '@belong/logging/logger';

export const getCookie = <T>(
  name: (typeof COOKIES)[keyof typeof COOKIES],
  context?: NextPageContext | GetServerSidePropsContext,
  options?: { parse?: boolean }
): T | undefined => {
  const ctxOptions = context?.req && context?.res ? { req: context.req, res: context.res } : undefined;
  const cookie = ctxOptions ? readCookie(name, { ...ctxOptions }) : readCookie(name);

  if (typeof cookie === 'string') {
    try {
      return options?.parse ? JSON.parse(cookie) : cookie;
    } catch (e) {
      logger.error(`Unable to parse cookie ${name}`, e);
      return undefined;
    }
  }

  return cookie as T;
};
