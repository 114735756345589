import React, { useEffect } from 'react';
import useProducts from '@belong/providers/products';
import { getConfig } from '@belong/configs/next/config';
import { legacyNavigation } from '@belong/analytics';

export interface IProps {
  page: any;
  shouldRun: boolean;
}

const trackNavigation = (data: any): void => {
  const payload = legacyNavigation({ data, workspace: getConfig().publicRuntimeConfig.workspace });
  const eventName = 'trackNavigation';

  window.dispatchEvent(new CustomEvent(eventName, { detail: payload }));
};

const AnalyticTracker: React.FC<IProps> = ({ shouldRun, page }) => {
  const { products } = useProducts();
  const { pageData } = page || { pageData: {} };
  useEffect(() => {
    if (shouldRun) {
      // Warning: pageMeta is the required object name for legacy tracking
      trackNavigation({ products, pageMeta: pageData });
    }
  }, [pageData?.pageUrl, products?.length, shouldRun]);

  return null;
};

export default AnalyticTracker;
