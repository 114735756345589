import * as React from 'react';
import { Document } from '@contentful/rich-text-types';
import { ICON_NAMES } from './icons';
import { TBreakpointKeys } from './theme';

/**
 * Inspired by @see {app/index.d.ts}
 */

/**
 * This is a much more self-explanatory type name when appearing
 * throughout the codebase to represent the return type of css`...`
 * tagged template literals from styled-components->css()
 */
export type StyledCSS<P = any> = any | P;

/**
 * Where you want to only output some styled css if a function passes some assertion
 */
export type MaybeStyledCSS = StyledCSS | undefined | false;

export type MediaMapResult = any | undefined | false;

export enum IMAGE_FORMAT {
  jpg = 'jpg',
  png = 'png',
  svg = 'svg',
  webp = 'webp'
}

export interface IImage {
  src: string;
  alt: string;
  contentType: ImageContentType;
}

export interface IVideo {
  src: string;
  alt: string;
  contentType: string;
}

export enum IMAGE_ASPECT_RATIO {
  square = '1:1',
  letterbox = '16:9',
  photo = '3:2'
}

type Fit = 'pad' | 'fill' | 'scale' | 'crop' | 'thumb';

type Focus =
  | 'center'
  | 'top'
  | 'right'
  | 'left'
  | 'bottom'
  | 'top_right'
  | 'top_left'
  | 'bottom_right'
  | 'bottom_left'
  | 'face'
  | 'faces';

export type ContentfulImageOptions = {
  w?: number;
  h?: number;
  q?: number;
  fm?: ImageContentType;
  fit?: Fit;
  f?: Focus;
};

export type BasicImageSource = [string, ContentfulImageOptions];
export type ResponsiveImageSource = { [k in TBreakpointKeys]?: BasicImageSource };
export type ResponsiveImageOptions = { [k in TBreakpointKeys]?: ContentfulImageOptions };
export type ImageSource = BasicImageSource | ResponsiveImageSource;
export type ImageContentType = keyof typeof IMAGE_FORMAT;

export interface IRichText {
  html: Document;
  contentId?: string;
}

export interface ILinkItem extends React.LiHTMLAttributes<HTMLLIElement>, React.PropsWithChildren {
  label: string;
  href: string;
  id?: string;
  icon?: ICON_NAMES;
  ctaIcon?: ICON_NAMES;
  caption?: string; // new field
  asUrl?: string;
  onClick?: (evt: React.MouseEvent<any>) => void;
  actionType?: string;
  target?: string;
  status?: string;
  a11yLabel?: string;
}

export interface ILinkList extends React.AllHTMLAttributes<HTMLUListElement> {
  items: ILinkItem[];
  groupName?: string;
}

export enum OUTER_SPACING {
  COMPACT = 'COMPACT',
  STANDARD = 'STANDARD',
  LOOSE = 'LOOSE'
}

export interface IVisibility {
  isHidden: boolean;
}
