import { ENVS } from '@belong/types/envs';

// Will be true for all environments where the code is compiled (only false in local dev)
export const isProd = process.env.NODE_ENV?.toUpperCase() === ENVS.PRODUCTION;

let clientLogEndpoint;
export const getClientLogEndpoint = (): string => clientLogEndpoint;
export const setClientLogEndpoint = (value: string): void => {
  clientLogEndpoint = value;
};

export const CLIENT_LOG_PREFIX = '[ClientLog]';

export enum LOG_LEVEL {
  DEBUG = 'debug',
  WARN = 'warn',
  ERROR = 'error',
  INFO = 'info'
}

// Allow these log levels everywhere
export const RESERVED_LOGGERS = [LOG_LEVEL.WARN, LOG_LEVEL.INFO, LOG_LEVEL.ERROR];
