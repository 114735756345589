import { COLOURS } from '@belong/themes';
import styled from 'styled-components';

export const Svg = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg'
})`
  width: 14.4rem;
  height: 2.4rem;
  color: ${COLOURS.BELONG_BLUE};
`;
