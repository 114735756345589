import React from 'react';
import { progressColourVariants, progressBackgroundColourVariants } from '@belong/types';
import { Root, IndeterminateProgressBar, DeterminateBar } from './LinearProgress.styles';
import { ILinearProgressProps } from './LinearProgress.types';

export enum LINEAR_PROGRESS_TEST_IDS {
  ROOT = 'LINEAR_PROGRESS_ROOT',
  DETERMINATE = 'DETERMINATE',
  INDETERMINATE = 'INDETERMINATE'
}

export const LinearProgress: React.FC<ILinearProgressProps> = ({
  className,
  completed,
  isThin = false,
  colour = progressColourVariants.blue,
  hasBackground = progressBackgroundColourVariants.grey,
  borderRadius = false,
  animateBar = false,
  animateBackground = false,
  stepPosition = undefined
}) => {
  const isDeterminate = completed !== undefined;

  return (
    <Root
      isThin={isThin}
      className={className}
      isDeterminate={isDeterminate}
      borderRadius={borderRadius}
      hasBackground={hasBackground}
      data-testid={LINEAR_PROGRESS_TEST_IDS.ROOT}
    >
      {isDeterminate ? (
        <DeterminateBar
          completed={completed}
          colour={colour}
          borderRadius={borderRadius}
          animateBar={animateBar}
          data-testid={LINEAR_PROGRESS_TEST_IDS.DETERMINATE}
          stepPosition={stepPosition}
        />
      ) : (
        <IndeterminateProgressBar
          colour={colour}
          borderRadius={borderRadius}
          animateBackground={animateBackground}
          data-testid={LINEAR_PROGRESS_TEST_IDS.INDETERMINATE}
        />
      )}
    </Root>
  );
};

LinearProgress.displayName = 'LinearProgress';
