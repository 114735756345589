import type { IImage } from '@belong/types';
import { cleanContentType } from '../helpers/cleanContentType';

// TBC: hook this up to the IContentfulImage type
export const FImage = (data: any): IImage => {
  const { fields } = data;
  const src = fields ? fields.file.url : data.url;
  const alt = fields ? fields.description : data.description;
  const contentType = cleanContentType(fields ? fields.file.contentType : data.contentType);
  return {
    src,
    alt: alt ?? '',
    contentType
  };
};
