import { FONT_WEIGHT, FONT_COLOURS, media, textShimmer } from '@belong/themes';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Text } from '../Text';
import { IDisplay } from './Display.types';

// TODO: Eliminate the use of heading level to determine font style. This is a semantic error
const getAsPropFromProps = ({ as, isSmall }: IDisplay): keyof JSX.IntrinsicElements => {
  if (as) {
    return as;
  }

  if (isSmall) {
    return 'h2';
  }

  return 'h1';
};

export const Display = styled(Text).attrs((props: IDisplay) => ({
  hasColor: props.hasColor,
  as: getAsPropFromProps(props)
}))<IDisplay>`
  font-weight: ${FONT_WEIGHT.BOLD};
  overflow-wrap: anywhere;

  ${({ isSmall }): FlattenSimpleInterpolation =>
    isSmall
      ? css`
          font-size: 2.8rem;
          line-height: 3.2rem;

          ${media('md')`
            font-size: 4.0rem;
            line-height: 4.8rem;
          `};
        `
      : css`
          font-size: 2.8rem;
          line-height: 3.2rem;

          ${media('md')`
            font-size: 4.8rem;
            line-height: 5.6rem;
          `};
        `};

  ${({ hasColor }) => (hasColor === FONT_COLOURS.LIGHT ? textShimmer : '')};

  color: ${({ hasColor, theme }) =>
    hasColor === FONT_COLOURS.LIGHT
      ? 'transparent'
      : theme.foregroundColorDark}; // use the theme color when present or transparent for shimmer affected

  font-size: ${({ isSmall }) => (isSmall ? 3.2 : 4.4)}rem;
  line-height: ${({ isSmall }) => (isSmall ? 1.12 : 1.1)};
  letter-spacing: 0.2px; // both types, not dependent on isSmall

  ${media('md')`
    line-height: 1;
    font-size: ${({ isSmall }) => (isSmall ? 4.8 : 6)}rem;
    letter-spacing: -1px;
  `};
`;
