import styled from 'styled-components';

const LIST_PADDING = '0.8rem 0 0.8rem 3.2rem';

export const ListItem = styled.li`
  color: ${({ theme }) => theme.foregroundColor}; // use the theme color when present
  margin-bottom: 0.8rem;
  padding-left: 0.8rem;
  text-align: left;

  & a {
    color: ${({ theme }) => theme.linkColor};
    vertical-align: top;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const UnorderedList = styled.ul`
  display: inline-block;
  padding: ${LIST_PADDING};

  ${ListItem} {
    list-style-type: disc;
    overflow-wrap: break-word;
  }
`;

export const OrderedList = styled.ol`
  display: inline-block;
  padding: ${LIST_PADDING};

  ${ListItem} {
    list-style-type: decimal;

    ${ListItem} {
      list-style-type: lower-alpha;
    }
  }
`;

export const UnstyledList = styled.ul`
  padding: 0;

  ${ListItem} {
    list-style-type: none;
    margin-bottom: 0;
    padding: 0;
  }
`;
