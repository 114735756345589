import * as React from 'react';

import { INavLinkProps } from '@belong/types';
import { Container } from '../../layout/Container';
import { Root, List, ListItem, Link } from './Breadcrumbs.styles';

interface IProps {
  links: INavLinkProps[];
}

const Breadcrumbs: React.FC<IProps> = (props: IProps) => {
  return (
    <Root data-testid="global-header-breadcrumbs" aria-label="Breadcrumb">
      <Container>
        <List>
          {props.links.map((link, idx, arr) => {
            const isSecondLast = idx === arr.length - 2;
            const { label, ...linkProps } = link;

            return (
              <ListItem isSecondLast={isSecondLast} key={label}>
                <Link {...linkProps} aria-current={link.isActive ? 'page' : undefined}>
                  {label}
                </Link>
              </ListItem>
            );
          })}
        </List>
      </Container>
    </Root>
  );
};

Breadcrumbs.displayName = 'Breadcrumbs';

export default Breadcrumbs;
