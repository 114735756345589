import { FormikErrors } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import { isObject, isUndefined } from '@belong/utils/validation';
import { AnalyticsEvents } from '../index';
import { ANALYTICS_STRINGS } from './constants';
import { buttonClick } from './commonEvents';

export const removeFromArray = <T>(arr: T[], itemToRemove: T): T[] => {
  return arr.filter(item => item !== itemToRemove);
};

export const combineAllObjects = <T>(...objects: T[]): T => {
  const base = {};
  Object.assign(base, ...objects);
  return base as T;
};

export const removeUndefinedFromObject = (object: object, deep = false): object => {
  // CloneDeep copies the functions' references, while structuredClone throws an error
  // when trying to clone an object with function attributes
  const cleanObject = cloneDeep(object);
  Object.keys(cleanObject).forEach(key => {
    if (isUndefined(cleanObject[key])) {
      delete cleanObject[key];
    } else if (deep && isObject(cleanObject[key])) {
      cleanObject[key] = removeUndefinedFromObject(cleanObject[key], deep);
    }
  });
  return cleanObject;
};

interface ITrackBlur {
  analyticsEvents: AnalyticsEvents;
  value: string;
  label: string;
  errorTracking: {
    errors: FormikErrors<any>;
    errorMessage: {
      required: string | string[];
      invalid: string | string[];
    };
  };
}

/**
 * @type {ITrackBlur}
 * Generic function to simplify onBlur tracking events.
 * Use the React Synthetic event onBlur and if used within Formik,
 * you must call Formik's handleBlur with the event prior to
 * calling this function. This requires the analyticsEvents functions from
 * the Analytics hook
 */
export const trackTextInputBlur = ({ value, label, errorTracking, analyticsEvents }: ITrackBlur): void => {
  const {
    errors,
    errorMessage: { required, invalid }
  } = errorTracking;
  const hasError = !!Object.keys(errors).length;
  const hasValue = !!value.length;
  const analyticsDataOnError = {
    category: ANALYTICS_STRINGS.CATEGORY.FORM,
    action: ANALYTICS_STRINGS.ACTION.INPUT_BLUR_ERROR,
    label
  };

  /**
   * Formik updates errors on blur from second action even though the UI updates
   * This condition makes sure that when neither `value` and `error` are empty
   * We should pass an error analytics
   */
  if (!hasError && !hasValue) {
    return analyticsEvents.send({
      ...analyticsDataOnError,
      text: required
    });
  }

  /**
   * When there is an error for the field
   */
  if (hasError) {
    analyticsEvents.send({
      ...analyticsDataOnError,
      text: invalid
    });
  }

  /**
   * When we have a valid value for input and no error.
   */
  if (hasValue && !hasError) {
    analyticsEvents.send({
      category: ANALYTICS_STRINGS.CATEGORY.FORM,
      action: ANALYTICS_STRINGS.ACTION.VALUE_ENTERED,
      label,
      text: label
    });
  }
};

type ITrackFocus = Pick<ITrackBlur, 'label' | 'analyticsEvents'>;

/**
 *
 * @type {ITrackFocus}
 * Used to track a focus action on an input. Takes the label and analyticsEvents
 * functions from the Analytics hook
 */
export const trackFocusInput = ({ label, analyticsEvents }: ITrackFocus): void => {
  analyticsEvents.send({
    category: ANALYTICS_STRINGS.CATEGORY.FORM,
    action: ANALYTICS_STRINGS.ACTION.FIELD_FOCUS,
    label,
    text: label
  });
};

interface ITrackButtonClick {
  label: string;
  text: string;
  analyticsEvents: AnalyticsEvents;
}

/**
 * @type {ITrackButtonClick}
 * Tracks button clicks
 * @param label
 * The value must communicate the intention of the element.
 * @param text
 * The exact button or link text from the element clicked
 */
export const trackButtonClick = ({ label, text, analyticsEvents }: ITrackButtonClick): void => {
  analyticsEvents.send(
    buttonClick({
      label,
      text
    })
  );
};
