import styled from 'styled-components';

import { FONT_COLOURS, isFocused, media } from '@belong/themes';

import withLink from '../../hocs/withLink';
import { Copy } from '../../styles/Typography/Copy';

export const NavLinkWrapper = styled.ul`
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
  gap: 3.2rem 0;

  li {
    list-style: none;
    display: inline-block;
    width: 100%;

    ${media('md')` 
      width: initial;
      margin-right: 4rem;
    `}
  }
`;

export const LinkItem = withLink(styled(Copy)`
  width: 100%;
  text-decoration: none;
  color: ${FONT_COLOURS.LIGHT};

  :focus {
    ${isFocused()}
  }

  :hover,
  :active {
    color: ${FONT_COLOURS.ACTIVE};
    text-decoration: underline;
  }
`);
