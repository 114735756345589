import React from 'react';

import { isBrowser } from '@belong/utils/device';
import { setBlueHost } from '@belong/utils/requestOrigin';

export const useBlueGreen = (host: string) => {
  React.useMemo(() => {
    setBlueHost(isBrowser() ? window.location.host : host);
  }, [host]);
};
