import { ResponsiveProp, ResponsiveRangeProps, TBreakpointKeys } from '@belong/types';

const breakpointKeys = ['xs', 'sm', 'md', 'lg', 'xl'] as TBreakpointKeys[];

export const getResponsiveFullObject = <P>(
  obj?: Partial<Record<TBreakpointKeys, P>> | null
): Record<TBreakpointKeys, P> | null => {
  if (obj === null) {
    return null;
  }
  return breakpointKeys.reduce(
    (fullObj, breakpoint, idx) => ({
      ...fullObj,
      [breakpoint]: obj?.[breakpoint] ?? fullObj[breakpointKeys[Math.max(idx - 1, 0)]]
    }),
    {} as any
  );
};

export const responsiveValue = <P>(value: ResponsiveProp<P>): Partial<Record<TBreakpointKeys, P>> | null => {
  if (!value) {
    return null;
  }

  if (typeof value === 'object') {
    return value;
  }
  return {
    xs: value,
    sm: value,
    md: value,
    lg: value,
    xl: value
  };
};

/**
 * Given the `[below, atAndAbove]` props this function returns a responsiveProp that
 * is `true` for each screen size that fits within the range specified by (below, atAndAbove)
 * pair and is `false` for other screen sizes.
 * If neither below nor atAndAbove provided it returns false for all breakpoints
 * If below < atAndAbove it means two disjoint ranges: [0 - indexOf(below)] and [indexOf(atAndAbove), 5].
 * @param below
 * @param atAndAbove
 *
 */
export const resolveResponsiveRange = ({ below, atAndAbove }: ResponsiveRangeProps = {}): ResponsiveProp<boolean> => {
  const startIndex = atAndAbove ? breakpointKeys.indexOf(atAndAbove) : breakpointKeys.length;
  const endIndex = below ? breakpointKeys.indexOf(below) - 1 : -1;

  if (startIndex <= endIndex) {
    const range = breakpointKeys.slice(startIndex, endIndex + 1);

    return breakpointKeys.reduce(
      (valueObj, breakpoint) => ({ ...valueObj, [breakpoint]: range.indexOf(breakpoint) >= 0 }),
      {}
    );
  }
  // startIndex > endIndex, we have two disjoint ranges:
  const range1 = breakpointKeys.slice(0, endIndex + 1);
  const range2 = breakpointKeys.slice(startIndex, breakpointKeys.length);
  return breakpointKeys.reduce(
    (valueObj, breakpoint) => ({
      ...valueObj,
      [breakpoint]: range1.indexOf(breakpoint) >= 0 || range2.indexOf(breakpoint) >= 0
    }),
    {}
  );
};
