import styled from 'styled-components';

export default styled.input.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['width'].includes(prop) && defaultValidatorFn(prop)
})`
  display: inline-block;
  border: none;
  border-radius: 0;
  padding: 0;
  width: 100%;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  text-align: inherit;
  line-height: normal;
  -webkit-appearance: none;
  text-decoration: none;
`;
