import { FONT_COLOURS, media } from '@belong/themes';
import { Column, Columns, Stack, Display } from '@belong/ui-components';
import styled from 'styled-components';
import { ISearchPanelProps } from '../../types';
import { SEARCH_TEST_IDS } from '../../test/utils/testIds';
import { SearchInput } from '../../SearchInput';

const StyledDisplay = styled(Display)`
  text-align: center;
  ${media('xs', 'md')`
    text-align: left;
  `};
`;

export const SearchPanel: React.FC<ISearchPanelProps> = ({
  callback,
  'data-testid': testId = SEARCH_TEST_IDS.SEARCH_PANEL
}) => {
  return (
    <Columns alignX="center" data-testid={testId}>
      <Column width={{ xs: '1', lg: '3/4', xl: '1/2' }}>
        <Stack space="xlarge">
          <StyledDisplay as="h2" hasColor={FONT_COLOURS.LIGHT} id="search-title">
            Search Belong
          </StyledDisplay>
          <SearchInput callback={callback} searchOnRedirect data-testid={`${testId}-${SEARCH_TEST_IDS.INPUT_ROOT}`} />
        </Stack>
      </Column>
    </Columns>
  );
};

SearchPanel.displayName = 'SearchPanel';
