import { LOG_LEVEL } from './config';
import writeLog from './writeLog';

export * from './combineLogs';
export * from './config';
export * from './maskers';

// convenience methods
export const logger = {
  debug: writeLog.bind(null, LOG_LEVEL.DEBUG),
  log: writeLog.bind(null, LOG_LEVEL.DEBUG),
  warn: writeLog.bind(null, LOG_LEVEL.WARN),
  error: writeLog.bind(null, LOG_LEVEL.ERROR),
  info: writeLog.bind(null, LOG_LEVEL.INFO)
};
