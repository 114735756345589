import { deleteCookie as destroyCookie } from 'cookies-next';
import type { GetServerSidePropsContext, NextPageContext } from 'next';

import { COOKIES } from '@belong/constants';

export const deleteCookie = (
  name: (typeof COOKIES)[keyof typeof COOKIES],
  context?: NextPageContext | GetServerSidePropsContext,
  options?: any
): void => {
  const ctxOptions = context?.req && context?.res ? { req: context.req, res: context.res } : undefined;

  destroyCookie(name, { ...options, ...ctxOptions });
};
