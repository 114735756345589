import { ReactElement } from 'react';
import { AlignX, AlignY, ITestable, ResponsiveProp, SpacesKeys, TBreakpointKeys } from '@belong/types';
import { IColumnProps } from './Column';

export type ReverseType = 'never' | 'always' | 'whenCollapsed' | 'whenNotCollapsed';

// null, boolean and undefined do not render in JSX
type ColumnsChild = ReactElement<IColumnProps> | null | boolean | undefined | '';
export interface IColumnsProps extends ITestable {
  /**
   * The horizontal spacing between Column children. When the Column children collapse
   * this becomes the vertical spacing between them.
   */
  space?: ResponsiveProp<SpacesKeys>;
  children?: ColumnsChild | Array<ColumnsChild>;
  /**
   * When the screen size is smaller than a breakpoint it might not make sense to have
   * Column children sitting horizontally next to each other due to the lack of enough
   * screen real estate. In such scenarios use `collapseBelow` to force Columns to
   * stack up on top of each other vertically.
   */
  collapseBelow?: TBreakpointKeys;
  /**
   * The horizontal alignment of the columns. This is useful when the total width
   * of Column children is less than 100% of Columns. With this prop then can
   * be aligned left, right or centre.
   */
  alignX?: ResponsiveProp<AlignX>;

  /**
   * Different Column children very likely will have different heights based on
   * their content. With this property we can vertically align the Column children
   * to the bottom, top or centre.
   */
  alignY?: ResponsiveProp<AlignY>;
  /**
   * There are use cases when Column children collapse we might want to show them
   * in reverse order. Use `reverse` prop for those cases with `whenCollapsed` value.
   * this prop also provides options for no reverse at all, always reverse children,
   * or reverse children on large screen as well.
   */
  reverse?: ReverseType;

  /**
   * A11Y recommends that we use `ul` and `li` for lists. This prop allows us to
   * render the Columns as `ul` and the Column children as `li` elements.
   * The default remains div for the majority of use cases
   */
  as?: 'div' | 'ul';

  /**
   * A11Y recommends role for various elements. This prop allows us to
   * render the Columns with a role. Default is no role
   */
  role?: string;
}

export const columnsDefaultProps: Required<Omit<IColumnsProps, 'children' | 'role'>> = {
  alignX: 'left',
  alignY: 'top',
  collapseBelow: 'xs',
  'data-testid': 'columns',
  reverse: 'never',
  space: 'none',
  as: 'div'
};
