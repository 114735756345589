import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { COLOURS, FONT_COLOURS } from '@belong/themes';
import { IStatusIndicator, STATUS_INDICATOR_STATUSES, STATUS_INDICATOR_VARIANT } from '@belong/types';

import { Icon, ICON_NAMES } from '../../Icon';
import { Copy } from '../../styles/Typography/Copy';
import { hextToRgba } from './hexToRgb';
import { VisuallyHidden } from '../../styles/Typography/VisuallyHidden';

/**
 * Use the controls available in the sidebar to configure your own <code>StatusIndicator</code>
 */
export const StatusIndicator: React.FC<IStatusIndicator> = ({
  status,
  children,
  type = STATUS_INDICATOR_VARIANT.DEFAULT,
  withIcon = false,
  iconName,
  a11yPrefixStatusIndicator = `Status: `,
  ...props
}) => {
  const noStatusLight = type !== STATUS_INDICATOR_VARIANT.NO_STATUS_LIGHT;
  return (
    <>
      <VisuallyHidden>{a11yPrefixStatusIndicator}</VisuallyHidden>

      <StatusIndicatorWrapper type={type} status={status}>
        <IconWrapper withIcon={withIcon}>
          {withIcon && noStatusLight && <StatusIcon status={status} iconName={iconName} />}

          <StatusIndicatorText variant="small" isBold status={status} as="span" {...props}>
            {!withIcon && noStatusLight && <StatusIndicatorLight status={status} type={type} />}
            {children}
          </StatusIndicatorText>
        </IconWrapper>
      </StatusIndicatorWrapper>
    </>
  );
};

StatusIndicator.displayName = 'StatusIndicator';

const StyledIcon = styled(Icon)``;

const IconWrapper = styled.div<{ withIcon: boolean }>`
  ${props =>
    props.withIcon &&
    css`
      display: flex;
      ${StatusIndicatorText} {
        text-align: left;
        padding-top: 0.2rem;
      }
      ${StyledIcon} {
        margin-right: 0.4rem;
      }
    `}
`;

const statusIconStyles = {
  [STATUS_INDICATOR_STATUSES.INFO]: { icon: ICON_NAMES.Info, color: FONT_COLOURS.MESSAGE },
  [STATUS_INDICATOR_STATUSES.SUCCESS]: { icon: ICON_NAMES.TickCircle, color: FONT_COLOURS.SUCCESS },
  [STATUS_INDICATOR_STATUSES.WARNING]: { icon: ICON_NAMES.Warning, color: FONT_COLOURS.WARNING },
  [STATUS_INDICATOR_STATUSES.DANGER]: { icon: ICON_NAMES.Danger, color: FONT_COLOURS.ERROR },
  [STATUS_INDICATOR_STATUSES.INACTIVE]: { icon: ICON_NAMES.Info, color: FONT_COLOURS.FINE_PRINT }
};

const StatusIcon = props => {
  const { icon, color } = statusIconStyles[props.status];
  return <StyledIcon name={props.iconName ?? icon} hasColor={color} size="xsmall" />;
};

StatusIcon.displayName = 'StatusIcon';

const StatusIndicatorText = styled(Copy)<IStatusIndicator>`
  color: ${(props: IStatusIndicator): string => BACKGROUND_COLOUR_MAP[props.status]};
`;

const StatusIndicatorWrapper = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['type'].includes(prop) && defaultValidatorFn(prop)
})<{
  type: STATUS_INDICATOR_VARIANT;
  status: STATUS_INDICATOR_STATUSES;
}>`
  ${props =>
    props.type !== STATUS_INDICATOR_VARIANT.DEFAULT &&
    css`
      display: inline-block;
      border-radius: 10rem;
      padding: ${(p: any) =>
        p.type === STATUS_INDICATOR_VARIANT.NO_STATUS_LIGHT
          ? '0.8rem 2.4rem 0.8rem 2.4rem'
          : '0.8rem 1.6rem 0.8rem 1.2rem'};
      background-color: ${STATUS_PILL_BACKGROUND_COLOUR[props.status]};
    `}
`;

const STATUS_LIGHT_WIDTH = '0.6rem';
const pulseShadow = (status: IStatusIndicator['status']) => keyframes`
  0% {
    box-shadow: 0 0 0 0 ${hextToRgba(BACKGROUND_COLOUR_MAP[status], 0.75)};
  }
  100% {
    box-shadow: 0 0 0.3rem ${STATUS_LIGHT_WIDTH} ${hextToRgba(BACKGROUND_COLOUR_MAP[status], 0)};
  }
`;

const StatusIndicatorLight = styled.span<IStatusIndicator>`
  display: inline-block;
  width: ${STATUS_LIGHT_WIDTH};
  height: ${STATUS_LIGHT_WIDTH};
  margin-right: 0.8rem;
  vertical-align: middle;
  font-size: 1.5rem;
  border-radius: 50%;
  background-color: ${(p: IStatusIndicator): string => BACKGROUND_COLOUR_MAP[p.status]};

  @media (prefers-reduced-motion: no-preference) {
    animation: ${p => pulseShadow(p.status)} 2s ease-in-out 5s;
  }
`;

const STATUS_PILL_BACKGROUND_COLOUR = {
  [STATUS_INDICATOR_STATUSES.INFO]: COLOURS.LIGHT_BLUE,
  [STATUS_INDICATOR_STATUSES.SUCCESS]: COLOURS.LIGHT_GREEN,
  [STATUS_INDICATOR_STATUSES.WARNING]: COLOURS.LIGHT_ORANGE,
  [STATUS_INDICATOR_STATUSES.DANGER]: COLOURS.LIGHT_RED,
  [STATUS_INDICATOR_STATUSES.INACTIVE]: COLOURS.GREY_100
};

const BACKGROUND_COLOUR_MAP = {
  [STATUS_INDICATOR_STATUSES.INFO]: COLOURS.DARK_BLUE,
  [STATUS_INDICATOR_STATUSES.SUCCESS]: COLOURS.DARK_GREEN,
  [STATUS_INDICATOR_STATUSES.WARNING]: COLOURS.DARK_ORANGE,
  [STATUS_INDICATOR_STATUSES.DANGER]: COLOURS.DARK_RED,
  [STATUS_INDICATOR_STATUSES.INACTIVE]: COLOURS.GREY_500
};
