export function hexToRgb(hex: string): { r: number; b: number; g: number } | null {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const longHex = hex.replace(shorthandRegex, (_m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(longHex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

export function hextToRgba(hex: string, alpha = 1): string | null {
  const rgb = hexToRgb(hex);
  if (rgb === null) {
    return null;
  }
  return `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, ${alpha})`;
}
