import { IInfoCallout, IInfoCalloutItem } from '@belong/types';
import { FSystemIcon } from '../elements/elementSystemIcon.factory';
import { FRichText } from '../richText.factory';

export const FInfoCallout = (data: any): IInfoCallout => {
  const { informationRows } = data.fields;

  const items: IInfoCalloutItem[] = [];
  informationRows.forEach(item => {
    const row = {
      iconName: FSystemIcon(item.fields.icon).name,
      richText: FRichText(item.fields.richText)
    };
    items.push(row);
  });
  return {
    items
  };
};
