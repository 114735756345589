import { ISpacingProps } from '../../theme';
import { IText } from './Text.types';

export enum HEADING_VARIANTS {
  xSmall = 'xSmall',
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export const VARIANT_TO_HEADING_LEVEL = {
  [HEADING_VARIANTS.xSmall]: 'h6',
  [HEADING_VARIANTS.small]: 'h5',
  [HEADING_VARIANTS.medium]: 'h4',
  [HEADING_VARIANTS.large]: 'h3'
};

export type THeadingVariant = keyof typeof HEADING_VARIANTS;
export type THeadingLevel = (typeof VARIANT_TO_HEADING_LEVEL)[THeadingVariant];

export interface IHeading extends IText, ISpacingProps {
  variant?: THeadingVariant;
  type?: string;
}
