import { useState, useEffect, CSSProperties } from 'react';
import { BREAKPOINTS } from '@belong/themes';
import { useCanStartChat } from './useCanStartChat';
import { loadWasConnected } from './storage';

export type ChatFrameSharedState = {
  iframeStyles?: CSSProperties;
  isCovering?: boolean;
};

const SHARED_MESSAGE_TYPE = 'belong-iframe-share';

/**
 * Send state changes to window.parent via postMessage.
 */
export const useSendStateToParentFrame = (state?: ChatFrameSharedState): void => {
  useEffect(() => {
    window.parent?.postMessage({ type: SHARED_MESSAGE_TYPE, state });
  }, [state]);
};

/**
 * Listen to state changes that are sent via postMessage.
 */
export const useStateFromChildFrame = (initial?: ChatFrameSharedState): ChatFrameSharedState | undefined => {
  const [state, setState] = useState(initial);

  useEffect(() => {
    const handleMessage = (e: MessageEvent): void => {
      if (e.data.type === SHARED_MESSAGE_TYPE) {
        setState(e.data.state);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return state;
};

type UseMediaQueryOptions = { targetWindow?: Window | null };

/**
 * Needed a way to query matchMedia on window.parent (existing packages are hardcoded for 'window').
 */
export const useMediaQuery = (query: string, { targetWindow }: UseMediaQueryOptions = {}): boolean => {
  const [matches, setMatches] = useState(() => {
    // Ensure query is evaluated first render, with SSR-safe 'window'
    const safeWindow = targetWindow || (typeof window !== 'undefined' ? window : undefined);
    return !!safeWindow?.matchMedia(query).matches;
  });

  useEffect(() => {
    const queryResult = (targetWindow || window).matchMedia(query);
    const handleChange = (): void => setMatches(queryResult.matches);
    handleChange();

    queryResult.addEventListener('change', handleChange);
    return () => queryResult.removeEventListener('change', handleChange);
  }, [query, targetWindow]);

  return matches;
};

const useParent = (): Window | undefined => (typeof window !== 'undefined' ? window.parent : undefined);

export const useIsParentLarge = (): boolean => {
  const parentWindow = useParent();
  return useMediaQuery(`(min-width: ${BREAKPOINTS.lg}px)`, { targetWindow: parentWindow });
};

export const useIsParentMobile = (): boolean => {
  const parentWindow = useParent();
  return useMediaQuery(`(max-width: ${BREAKPOINTS.sm - 1}px)`, { targetWindow: parentWindow });
};

export const useShouldRenderChat = (): boolean => {
  const [shouldRender, setShouldRender] = useState<boolean>(false);
  const newChatsAllowed = useCanStartChat();

  useEffect(() => {
    // Each route change decide whether chat should be rendered
    const wasConnected = loadWasConnected();
    setShouldRender(newChatsAllowed || wasConnected);
  }, [newChatsAllowed]);

  return shouldRender;
};
