import { createContext, useContext } from 'react';

export const CorrelationIdContext = createContext<string | undefined>(undefined);

export const useCorrelationId = (): string => {
  let correlationId = useContext(CorrelationIdContext);
  if (!correlationId) {
    // throw new Error('useCorrelationId must be used within a <CorrelationIdContext.Provider>');
    correlationId = '00000000-0000-0000-0000-000000000000';
  }

  return correlationId;
};
