import { IIcon, IRichText } from '@belong/types';
import { FRichText } from './richText.factory';
import { FSystemIcon } from './elements/elementSystemIcon.factory';

export interface IReadOnlyRow {
  identifier: string;
  title?: IRichText;
  heading?: string;
  value?: string;
  description?: IRichText;
  icon?: IIcon;
  available: boolean;
}

export const FReadOnlyRow = ({ fields, sys }: any): IReadOnlyRow => {
  return {
    identifier: sys.id,
    title: fields.title && FRichText(fields.title),
    heading: fields.heading,
    value: fields.value,
    description: fields.description && FRichText(fields.description),
    icon: fields.icon && FSystemIcon(fields.icon),
    available: fields.available
  };
};
