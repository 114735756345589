import React from 'react';
import slugify from 'slugify';

import { INavLinksSocial } from '@belong/types';
import { useAnalyticsEvents } from '@belong/providers/analyticsEvents';
import { buttonClick } from '@belong/analytics';

import { A11yCopy } from '../../styles/Typography/A11yCopy';
import { SocialLink, StyledIcon } from './NavLinksSocial.styles';

export const NavLinksSocial: React.FC<INavLinksSocial> = ({ label, socialIcon, ...linkProps }: INavLinksSocial) => {
  const analyticsEvents = useAnalyticsEvents();

  const handleClick = () => {
    analyticsEvents.send(
      buttonClick({
        label: slugify(label, { replacement: '-', remove: /_&\+!#\*/, lower: true }).trim(),
        text: label,
        otherProps: { component: 'footer' }
      })
    );
  };

  return (
    <SocialLink {...linkProps} onClick={handleClick} aria-label={`Link to ${label}`}>
      <StyledIcon name={socialIcon} size="smallplus" />
      <A11yCopy>{label}</A11yCopy>
    </SocialLink>
  );
};

NavLinksSocial.displayName = 'NavLinksSocial';
export default NavLinksSocial;
