import { FONT_COLOURS, CALLOUT_FLAIR_COLOURS, FLAIRS } from '@belong/themes';
import styled from 'styled-components';
import { ButtonUnstyled, Icon } from '@belong/ui-components';
import { AGENT_HEADER_HEIGHT, LOGO_PADDING, StyledContainer, Z_INDEX_HEADER_BAR } from '../styles';

export const StyledAgentHeader = styled.div`
  background: ${CALLOUT_FLAIR_COLOURS[FLAIRS.PASSION_POP]};
  z-index: ${Z_INDEX_HEADER_BAR};
  position: relative;
`;

export const Container = styled(StyledContainer)`
  display: flex;
  justify-content: space-between;
  height: ${AGENT_HEADER_HEIGHT};
  padding-left: ${LOGO_PADDING};
  padding-right: ${LOGO_PADDING};
`;

export const LogoutLink = styled(ButtonUnstyled)`
  color: ${FONT_COLOURS.STRONG};
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

export const StyledIcon = styled(Icon)`
  vertical-align: middle;
  margin-right: 0.8rem;
`;
