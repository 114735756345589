import { FONT_COLOURS, ICON_SIZES } from '@belong/themes';
import type { IIcon } from '@belong/types';
import { assertContentType } from '../../helpers/assertContentType';

export const FSystemIcon = (data: any): IIcon => {
  assertContentType(data, 'elementSystemIcon');
  const { icon, colour, size } = data.fields;
  return {
    name: icon,
    hasColor: FONT_COLOURS[colour] || colour,
    size: size && ICON_SIZES[size.toLowerCase()]
  };
};
