import React from 'react';
import { BUTTON_VARIANTS } from '@belong/themes';
import { Button } from '../../actions/Button';

const CONTENT_404 = {
  title: 'No page here',
  message: (
    <>
      <span>We can&apos;t find the page you&apos;re looking for. Check the URL or go to the&nbsp;</span>
      <Button variant={BUTTON_VARIANTS.QUATERNARY} href="/">
        Belong homepage
      </Button>
    </>
  )
};

const CONTENT_500 = {
  title: 'Technical error',
  message: (
    <span>
      It&apos;s not you, it&apos;s us. Take&nbsp;a break while we sort our wires out, and try again it a little bit.
    </span>
  )
};

export const ERROR_PAGE_CONTENT = {
  404: CONTENT_404,
  500: CONTENT_500
};

export const ERROR_PAGE_META = {
  description: 'Error Page',
  flowName: 'Not Applicable',
  identifier: 'ErrorPage',
  journeyName: 'Engage',
  keywords: 'error, error page',
  pageName: 'Error Page',
  pageTitle: 'Error | Belong'
};
