import { jwtDecode, type JwtPayload } from 'jwt-decode';

import { logger } from '@belong/logging';

export const decodeJwtToken = <T>(token: string): T | undefined => {
  try {
    return jwtDecode<T>(token);
  } catch (err) {
    logger.error('Error - unable to jwtDecode token string');
    logger.error(err);
    return undefined;
  }
};

export const hasTokenExpired = (token: string): boolean => {
  const jwtToken = decodeJwtToken<JwtPayload>(token);

  if (!jwtToken?.exp) {
    return true;
  }

  return Date.now() > jwtToken.exp * 1000;
};
