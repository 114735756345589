import styled from 'styled-components';

import { FONT_COLOURS, SPACES, cssUnit } from '@belong/themes';

import { Copy } from '../../styles/Typography/Copy';
import Icon from '../../Icon/Icon';

type StyledErrorProps = {
  onDarkBG: boolean;
};

export const StyledError = styled(Copy)
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => !['onDarkBG'].includes(prop) && defaultValidatorFn(prop)
  })
  .attrs<StyledErrorProps>(props => ({
    isBold: false,
    variant: 'medium',
    as: 'div',
    hasColor: props.onDarkBG ? FONT_COLOURS.ERROR_ON_DARK : FONT_COLOURS.ERROR
  }))<StyledErrorProps>`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  margin-right: ${SPACES.xsmall}${cssUnit};
  display: block;
`;
