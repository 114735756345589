import React from 'react';
import useToggle from 'react-use/lib/useToggle';

import { ICON_NAMES } from '@belong/types';
import { Button } from '../../actions/Button';
import CollapsibleItem from '../../actions/CollapsibleItem';
import { Icon } from '../../Icon';

import { IRegionalCountriesList, IRegionalList } from './types';
import { RegionalCountries } from './RegionalCountries';
import * as styles from './RegionalCountriesList.styles';

const breakCountriesByRegion = (countries: string[]) => {
  let current: IRegionalList;

  return countries.reduce((prev: IRegionalList[], c) => {
    // This is a region
    if (c.substring(0, 1) === '*') {
      current = {
        regionName: c.substring(1),
        countries: []
      };
      prev.push(current);
      return prev;
    }

    if (current) {
      current.countries.push(c);
    }

    return prev;
  }, []);
};

export const RegionalCountriesList: React.FC<IRegionalCountriesList> = ({
  countries,
  showCountriesLinkLabel,
  hideCountriesLinkLabel,
  noCollapse = false
}) => {
  const [isCountriesOpened, toggleIsCountriesOpened] = useToggle(false);
  const regionalList = breakCountriesByRegion(countries);

  const countryListToggleLabel = isCountriesOpened ? hideCountriesLinkLabel : showCountriesLinkLabel;

  const regions = (
    <styles.RegionsWrapper className={noCollapse ? 'regional-countries-list' : undefined}>
      {regionalList.map((region: IRegionalList) => {
        return <RegionalCountries key={region.regionName} {...region} />;
      })}
    </styles.RegionsWrapper>
  );

  if (noCollapse) {
    return regions;
  }

  return (
    <styles.Wrapper className="regional-countries-list">
      <Button type="button" variant="quaternary" onClick={toggleIsCountriesOpened} aria-expanded={isCountriesOpened}>
        <styles.CountriesToggleButtonLabel>
          <styles.LabelText>{countryListToggleLabel}</styles.LabelText>
          <Icon name={isCountriesOpened ? ICON_NAMES.ChevronUp : ICON_NAMES.ChevronDown} />
        </styles.CountriesToggleButtonLabel>
      </Button>
      <CollapsibleItem isOpened={isCountriesOpened}>{regions}</CollapsibleItem>
    </styles.Wrapper>
  );
};

RegionalCountriesList.displayName = 'RegionalCountriesList';
