export const NBN_SESSION_KEYS = {
  PRODUCTS: 'nbnProducts',
  ORDER: 'nbnOrder',
  STORE: 'nbnStore',
  SQ_DONE: 'sqDone',
  SQ_MODEL: 'sqModel',
  NBN_ORDER: 'nbnOrder',
  NBN_PROMO: 'nbnPromo',
  SQ_REFERRAL: 'sqReferral',
  SQ_STEPS: 'step-address-lookup'
} as const;

export const AGENT_SESSION_KEYS = {
  NOTIFICATION_CHANNEL: 'notificationChannel',
  ORDER_ID: 'orderId'
} as const;

export const SESSION_KEYS = {
  ...NBN_SESSION_KEYS,
  ...AGENT_SESSION_KEYS,
  USER_SESSION: 'userSession'
} as const;
