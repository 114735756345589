import { helpers, Node, Document } from '@contentful/rich-text-types';
import { IDictionary } from '@belong/types';

export interface IReplaceFunction {
  (inputValue: string): string;
}

export interface IApplyDictionary<T> {
  (dictionary: IDictionary | IReplaceFunction, input: T): T;
}

/**
 * Determines if this dictionary is in the shape of a function vs a static
 * lookup map.
 */
export const isReplaceFunction = (dictionary: IDictionary | IReplaceFunction): dictionary is IReplaceFunction =>
  typeof dictionary === 'function';

/**
 * Applies a dictionary of type `IDictionary` to a Contentful rich text node.
 * If this is a text node it will apply the dictionary to the text of the node.
 * If this node has children it will apply the dictionary to them as well. If
 * there is no text or children the node is returned as-is. Does not mutate the
 * given node.
 */
export const applyDictionaryToNode: IApplyDictionary<Node> = (dictionary, node) => {
  if (helpers.isText(node)) {
    return {
      ...node,
      value: applyDictionaryToString(dictionary, node.value)
    };
  }
  if (helpers.isInline(node) || helpers.isBlock(node)) {
    return {
      ...node,
      content: applyDictionaryToNodeList(dictionary, node.content)
    };
  }
  return node;
};

/**
 * Applies a dictionary of type `IDictionary` to a list of Contentful rich text
 * nodes. Returns a new array of nodes.
 */
export const applyDictionaryToNodeList: IApplyDictionary<Node[]> = (dictionary, nodes) => {
  return nodes.map(node => applyDictionaryToNode(dictionary, node));
};

/**
 * Applies a dictionary of type `IDictionary` to a Contentful rich text
 * document. Returns a new document.
 */
export const applyDictionaryToDocument: IApplyDictionary<Document> = (dictionary, document) => {
  return applyDictionaryToNode(dictionary, document) as Document;
};

/**
 * Applies a dictionary of type `IDictionary` to a string.
 *
 * @example
 *
 * applyDictionaryToString({ cost: 50, 'unit:dollar': '$' }, 'Your total is {{unit:dollar}}{{cost}}')
 * // => "Your total is $50"
 */
export const applyDictionaryToString: IApplyDictionary<string> = (dictionary, string) => {
  return string.replace(/{{\s*([\w:]*?)\s*}}/g, (input, key) => {
    if (isReplaceFunction(dictionary)) {
      return String(dictionary(key));
    }
    return key in dictionary ? String(dictionary[key] ?? '') : input;
  });
};
