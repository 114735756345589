import React, { Children, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { AlignX, alignXToFlex, ITestable, ResponsiveProp, SpacesKeys, TBreakpointKeys } from '@belong/types';
import { mediaMap } from '@belong/themes';
import { Divider, responsiveValue } from '@belong/ui-core';
import { alignXToDisplay, breakpointKeys, isChildHidden, unioniseResponsiveProps } from './stack.utils';

export type StackWidth = 'full' | 'content';
// todo: consider using the selector "& > * + *" to select all StackChild but the first one.
export interface IStackProps extends PropsWithChildren<ITestable> {
  /**
   * The horizontal alignment of the children. Specially useful when the children
   * have a 'content' width and don't take up the whole row width.
   */
  alignX?: ResponsiveProp<AlignX>;

  /**
   * If set to true, will put dividers between the children without changing the
   * vertical space between them.
   */
  divider?: boolean;
  /**
   * The T-shirt sized vertical spacing added by Stack between its children.
   */
  space?: ResponsiveProp<SpacesKeys>;

  width?: StackWidth;
}

type IStackChildProps = Pick<IStackProps, 'alignX' | 'space' | 'divider'> & {
  hideDivider?: ResponsiveProp<boolean>;
  isHidden?: ResponsiveProp<boolean>;
};

const StackChild = styled.div<IStackChildProps>`
  /* since it is flex we need to make sure children have full width */
  width: 100%;

  /* 
  To provide the required spacing, we use padding-top in StackChild. If dividers
  are added, the padding-top will be half of the space and margin-bottom will be set
  on the divider, leading to equal spacing around it.
   */
  ${p =>
    mediaMap(
      responsiveValue(p.space),
      space => css`
        padding-top: ${p.divider ? p.theme.spaces[space] / 2 : p.theme.spaces[space]}${p.theme.cssUnit};
        & > [data-testid='stack-divider'] {
          margin-bottom: ${p.divider ? p.theme.spaces[space] / 2 : 0}${p.theme.cssUnit};
          /* the dividers (hr) should have 100% width */
          width: 100%;
        }
        /* 
        todo: remove this once all browsers we support, support :has()
        This is for old Safaris that do not support :has. Instead of hiding
        StackChild if the child is empty
         */
        & > [data-testid='stack-divider']:only-child {
          border-top: none;
          height: 0;
          margin-bottom: -${p.divider ? p.theme.spaces[space] / 2 : p.theme.spaces[space]}${p.theme.cssUnit};
        }
      `
    )};

  /* 
  For backwards compatibility when people just wrap some components in a Stack
  (alignX == 'left' by default) we set its display to "block". By default, people
  expect a div to be display: block by default. However, once the alignX is set to
  center or right, we need to switch to display: flex to be able to achieve the alignment.
   */
  /* 
  If a child renders falsy (null rendering child) or is Hidden, we need to hide its StackChild wrapper. 
  This makes sure there is no unwanted extra vertical space because of those hidden elements.
  */
  ${p =>
    mediaMap(
      unioniseResponsiveProps<AlignX, boolean>(responsiveValue(p.alignX), responsiveValue(p.isHidden)),
      ([alignX, isHidden]) => css`
        align-items: ${alignX === 'left' ? 'initial' : alignXToFlex[alignX]};
        flex-direction: ${alignX === 'left' ? 'initial' : 'column'};
        display: ${isHidden ? 'none' : alignXToDisplay[alignX]};
      `
    )};

  /*
  In some cases, such as the first (non-hidden) rendering child, we need to hide the divider.
  Note that not always 'isHidden' and 'hideDivider' have the same value, we might need to hide
  a divider for a child even if that child is not hidden (like the first child).
   */
  ${p =>
    mediaMap(
      responsiveValue(p.hideDivider),
      hideDivider => css`
        & > [data-testid='stack-divider'] {
          display: ${hideDivider ? 'none' : 'block'};
          /*
             todo: remove this once all browsers we support, support :has()
           For old Safari with no :has() support, we do not hide the hr, rather we have it
           with negative margins to compensate for the fact that StackChild canno be 
           display:none. 
         */
          &:only-child {
            display: block;
          }
        }
      `
    )};

  /*
  If the child is rendering null, we should hide it.
   */
  &:empty {
    display: none;
  }

  /*
  Currently ':has' is not supported in firefox so it removes both selectors. 
  TODO: Add a workaround for firefox until ':has' is supported
  */
  &:has(> [data-testid='stack-divider']:only-child) {
    display: none;
  }
`;

const Wrapper = styled.div<Pick<IStackProps, 'space' | 'divider' | 'width'>>`
  display: block;
  width: ${p => (p.width === 'content' ? 'auto' : '100%')};
  /*
  To provide spacing between children we use padding-top in each stack-child. However,
  there should be no spacing before the first child. We achieve this by providing a
  negative margin-top in a ::before pseudo element.
   */
  ${p =>
    mediaMap(
      responsiveValue(p.space),
      space => css`
        &::before {
          content: '';
          display: table;
          margin-bottom: -${p.divider ? p.theme.spaces[space] / 2 : p.theme.spaces[space]}${p.theme.cssUnit};
        }
      `
    )};
`;

/**
 * This component provides vertical spacing between its children. It does that
 * by simply wrapping each child in a StackChild component, which in turn has a
 * padding-top equal to the space requested by "space" prop.
 * It can also horizontally align its children.
 * Stack can also provide a horizontal divider between its children while respecting
 * the requested space between them.
 * Most of the complexity of this component is to deal with the case where a child
 * is either rendering falsy (e.g. null) or is an instance of Hidden component, i.e.
 * hides its children on some breakpoints.
 * @param alignX
 *   Sets the horizontal alignment of the Stack children, left, center, or right.
 * @param children
 * @param dataId
 * @param divider
 *   If true, Stack will show a divider between the children.
 * @param space
 *   The space that Stack puts between its children. This is a responsive prop.
 * @param others
 *   Let other props to pass through, e.g. id.
 * @constructor
 */
export const Stack: React.FC<IStackProps> = ({
  alignX = 'left',
  children,
  'data-testid': dataId = 'stack',
  divider,
  space = { xs: 'medium', md: 'large' },
  ...others
}) => {
  /*
  Stack needs to know which child is the first rendering child, since it might
  have some leading Hidden children. firstVisibleChild is the index of the first
   visible child of Stack on each breakpoint. It is initially set to -1.
   For each child then we figure out whether the child is hidden or not on each
   breakpoint. If the child is not hidden and firstVisibleChild is still -1 (unset)
   for any given breakpoint, it means this child will be the first child on that breakpoint.
   So we set firstVisibleChild[breakpoint] to the index of the current child (curIdx).
   */
  const firstVisibleChild = breakpointKeys.reduce(
    (acc, breakpoint) => ({
      ...acc,
      [breakpoint]: -1
    }),
    {} as Record<TBreakpointKeys, number>
  );
  return (
    <Wrapper data-testid={dataId} space={space} divider={divider} {...others}>
      {Children.map(children, (child, curIdx) => {
        const childHidden = isChildHidden(child);
        breakpointKeys.forEach(breakpoint => {
          if (firstVisibleChild[breakpoint] === -1 && !childHidden[breakpoint]) {
            firstVisibleChild[breakpoint] = curIdx;
          }
        });

        /*
        If the current child is the first visible child on a breakpoint, hide its divider
        on that breakpoint, since in each StackChild, we first render a Divider and then
        the actual child, and we need to hide it for the first visible child.
         */

        return (
          <StackChild
            hideDivider={breakpointKeys.reduce((acc, breakpoint) => {
              acc[breakpoint] = firstVisibleChild[breakpoint] === curIdx;
              return acc;
            }, {})}
            isHidden={childHidden}
            alignX={alignX}
            space={space}
            divider={divider}
            data-testid="stack-child"
          >
            {divider && <Divider data-testid="stack-divider" noMargin />}
            {child}
          </StackChild>
        );
      })}
    </Wrapper>
  );
};

Stack.displayName = 'Stack';
