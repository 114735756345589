import React, { FC } from 'react';
import slugify from 'slugify';

import { INavLink } from '@belong/types';
import { useAnalyticsEvents } from '@belong/providers/analyticsEvents';
import { buttonClick } from '@belong/analytics';

import { LinkItem } from './NavLinkFooter.styles';

export const NavLinkFooter: FC<INavLink> = ({ label, ...linkProps }: INavLink) => {
  const analyticsEvents = useAnalyticsEvents();

  const handleClick = () => {
    analyticsEvents.send(
      buttonClick({
        label: slugify(label, { replacement: '-', remove: /_&\+!#\*/, lower: true }).trim(),
        text: label,
        otherProps: { component: 'footer' }
      })
    );
  };

  return (
    <LinkItem {...linkProps} onClick={handleClick}>
      {label}
    </LinkItem>
  );
};

NavLinkFooter.displayName = 'NavLinkFooter';
export default NavLinkFooter;
