import type { INavLinksSocial } from '@belong/types';
import { parseLinkData } from './parseLinkData';

export const parseSocialNavLink = (data: any): INavLinksSocial => {
  const { label, socialIcon, url } = data.fields;

  return {
    label,
    socialIcon,
    ...parseLinkData(url)
  };
};
