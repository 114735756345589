import { isExternalUrl } from '@belong/utils/url';
import { ANALYTICS_STRINGS } from './constants';

export const pageView = (
  pageData: any,
  config: { workspace: string },
  pageAuthenticated = false,
  isPageLoad = false
): Record<string, any> => {
  const { pathname, hostname } = window?.location || {};
  return {
    category: ANALYTICS_STRINGS.CATEGORY.PAGEVIEW,
    action: ANALYTICS_STRINGS.ACTION.PAGE_LOAD,
    label: typeof pageData?.pageUrl === 'string' ? pageData?.pageUrl : pageData?.pageUrl?.fields?.url || pathname,
    pageTitle: pageData?.pageTitle,
    pageLoadType: isPageLoad ? 'Load' : 'Refresh',
    journey: pageData?.journeyName,
    flow: pageData?.flowName,
    pageName: pageData?.pageName,
    pageId: pageData?.page?.identifier,
    pageAuthenticated: pageAuthenticated ? 'yes' : 'no',
    // eslint-disable-next-line camelcase
    _unmapped__page: pageData?.page,
    workspace: config.workspace,
    hostname,
    currentUrl: pathname,
    contentfulEnv: process.env.CONTENTFUL_ENVIRONMENT
  };
};

export const buttonClick = ({
  label,
  text,
  otherProps,
  destination = 'internal'
}: {
  label: string;
  text?: string;
  otherProps?: Record<string, any>;
  destination?: 'internal' | 'external'; // whether offsite or not
}): Record<string, any> => {
  return {
    category:
      destination === 'external' ? ANALYTICS_STRINGS.CATEGORY.EXTERNAL_LINK : ANALYTICS_STRINGS.CATEGORY.INTERNAL_LINK,
    action: ANALYTICS_STRINGS.ACTION.CLICK,
    label,
    text,
    ...otherProps
  };
};

/**
 * When a user clicks on an `Element > Link` which is embedded within a
 * RichText
 */
export const embeddedLinkClick = (href, label): Record<string, any> => ({
  category: isExternalUrl(href) ? ANALYTICS_STRINGS.CATEGORY.EXTERNAL_LINK : ANALYTICS_STRINGS.CATEGORY.INTERNAL_LINK,
  action: ANALYTICS_STRINGS.ACTION.CLICK,
  label,
  component: ANALYTICS_STRINGS.COMPONENT.BUTTON
});

/**
 * @deprecated -- used for legacy tracking of Public pages.
 *
 * Please migrate to using `pageView` instead.
 */
export const legacyNavigation = ({ data, workspace }: { data: any; workspace: string }) =>
  ({
    ...data,
    action: 'replace currentPage',
    workspace
  }) as const;

export const formError = ({
  action,
  label,
  text
}: {
  action: string;
  label: string;
  text: string;
}): Record<string, any> => {
  return {
    category: 'form',
    action,
    label,
    text
  };
};
