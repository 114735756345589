import React, { FunctionComponent } from 'react';
import useAnalyticsEvents from '@belong/providers/analyticsEvents';
import { BUTTON_VARIANTS, BUTTON_WIDTHS } from '@belong/themes';
import ButtonGroup from '../../../styles/ButtonGroup';
import { Button } from '../../../actions/Button';
import { IFeedbackCapture } from '../FeedbackCapture';
import { TEST_ID } from '../constants';
import { IFeedbackCaptureStep } from '.';

interface IInitialStep extends IFeedbackCaptureStep {
  optionLabels: IFeedbackCapture['optionLabels'];
}

const CLICK_EVENT = {
  category: 'page engagement',
  component: 'feedback'
};

export const BUTTON_PROPS = {
  variant: BUTTON_VARIANTS.SECONDARY,
  width: { xs: BUTTON_WIDTHS.full, sm: BUTTON_WIDTHS.half, md: BUTTON_WIDTHS.min }
};

const InitialStep: FunctionComponent<IInitialStep> = ({ next, optionLabels }) => {
  const analytics = useAnalyticsEvents();
  const handleNo = () => {
    analytics.send({ ...CLICK_EVENT, action: 'select no', label: optionLabels.no });
    next?.();
  };

  const handleYes = () => {
    analytics.send({ ...CLICK_EVENT, action: 'select yes', label: optionLabels.yes });
    next?.();
  };

  return (
    <ButtonGroup data-testid={TEST_ID.INITIAL_STEP} hasControl={{ xs: ['column', 'center', false], sm: ['row'] }}>
      <Button {...BUTTON_PROPS} data-testid={TEST_ID.INITIAL_STEP_CTA_YES} onClick={handleYes}>
        {optionLabels.yes}
      </Button>
      <Button {...BUTTON_PROPS} data-testid={TEST_ID.INITIAL_STEP_CTA_NO} onClick={handleNo}>
        {optionLabels.no}
      </Button>
    </ButtonGroup>
  );
};

InitialStep.displayName = 'InitialStep';

export default InitialStep;
