import { MaybeStyledCSS } from '@belong/types/ui';
import { CONTAINER_MAX_WIDTHS, GRID_GUTTERS, mediaMap } from '@belong/themes';
import styled, { css } from 'styled-components';
import { IContainer } from './Container.types';

export const Container = styled.div.attrs(({ isPadded }: IContainer) => ({
  isPadded: typeof isPadded === 'boolean' ? isPadded : true
}))<IContainer>`
  margin-left: auto;
  margin-right: auto;

  ${({ isPadded }): MaybeStyledCSS =>
    isPadded &&
    mediaMap(
      GRID_GUTTERS,
      (gutter: string) => css`
        padding-left: calc(2 * ${gutter});
        padding-right: calc(2 * ${gutter});
      `
    )}

  ${({ isFullWidth }): MaybeStyledCSS =>
    !isFullWidth &&
    mediaMap(
      CONTAINER_MAX_WIDTHS,
      (maxWidth: string) => css`
        max-width: ${maxWidth};
      `
    )}
`;

Container.displayName = 'Container';

export default Container;
