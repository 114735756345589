import { FONT_WEIGHT, media, spacingMixin } from '@belong/themes';
import { IHeading, THeadingLevel, VARIANT_TO_HEADING_LEVEL } from '@belong/types';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Text } from '../Text';

// TODO: Eliminate the use of heading level to determine font style. This is a semantic error
export const getAsPropFromVariant = ({ as, variant }: IHeading): THeadingLevel => {
  if (as) {
    return as;
  }

  if (!variant || !VARIANT_TO_HEADING_LEVEL[variant]) {
    return VARIANT_TO_HEADING_LEVEL.large; // fallback
  }

  return VARIANT_TO_HEADING_LEVEL[variant];
};

/**
 * The `Heading` component is used to render headings.
 */
export const Heading = styled(Text).attrs((props: IHeading) => ({
  hasColor: props.hasColor,
  variant: props.variant || 'large',
  as: getAsPropFromVariant(props)
}))<IHeading>`
  ${spacingMixin};

  color: ${({ hasColor, theme }) => hasColor ?? theme.foregroundColorDark};

  text-transform: none;

  ${({ variant }): FlattenSimpleInterpolation => {
    switch (variant) {
      case 'xSmall':
        return css`
          font-size: 1.7rem;
          letter-spacing: 0;
          line-height: 1.4;
          font-weight: ${FONT_WEIGHT.SEMI_BOLD};
          text-transform: none;

          ${media('md')`
            font-size: 2rem;
            line-height: 1.4;
      `}
        `;

      case 'small':
        return css`
          font-size: 2rem;
          line-height: 1.4;
          font-weight: ${FONT_WEIGHT.SEMI_BOLD};
          letter-spacing: 0;

          ${media('md')`
            font-size: 2.4rem;
            line-height: 1.3333;
            letter-spacing: 0;
          `}
        `;

      case 'medium':
        return css`
          font-size: 2.4rem;
          line-height: 1.33;
          font-weight: ${FONT_WEIGHT.BOLD};
          letter-spacing: 0.2px;

          ${media('md')`
            font-size: 3.2rem;
            line-height: 1.12;
            letter-spacing: 0.2px;
          `}
        `;

      default:
      case 'large':
        return css`
          font-size: 2.8rem;
          line-height: 1.14;
          font-weight: ${FONT_WEIGHT.BOLD};
          letter-spacing: 0.2px;

          ${media('md')`
            font-size: 4rem;
            line-height: 1.2;
            letter-spacing: -0.5px;
          `}
        `;
    }
  }};
`;
