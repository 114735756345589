import { COLOURS, spacingMixin } from '@belong/themes';
import styled from 'styled-components';

const cellStyle = `
  padding: 0.8rem;
  padding: 0.8rem;
  height: 100%;
`;

export const TableViewportScroller = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

export const Th = styled.th`
  ${cellStyle};
  border-top: solid 1px ${COLOURS.GREY_600};
  border-right: 1px solid ${COLOURS.GREY_600};
  border-bottom: 1px solid ${COLOURS.GREY_600};
  background: ${COLOURS.GREY_200};
`;

export const Td = styled.td`
  ${cellStyle};
  border-right: 1px solid ${COLOURS.GREY_600};
  border-bottom: 1px solid ${COLOURS.GREY_600};
`;

/**
 * The Table component can be used in Contentful for displaying tabular data.
 */
export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  ${spacingMixin};

  // Need table height and cell height in order for cell contents to fill cells
  height: 1px;

  & tr th:first-child,
  & tr td:first-child {
    border-left: 1px solid ${COLOURS.GREY_600};
  }

  & tr:first-child th:first-child {
    border-top-left-radius: 0.8rem;
  }

  & tr:first-child th:last-child {
    border-top-right-radius: 0.8rem;
  }

  & tr:last-child td:first-child {
    border-bottom-left-radius: 0.8rem;
  }

  & tr:last-child td:last-child {
    border-bottom-right-radius: 0.8rem;
  }
`;
