import * as React from 'react';

import { type ITestable } from '@belong/types';
import { Inner, Outer } from './VideoEmbed.styles';

interface IProps extends ITestable {
  videoId: string; // like "youtube:4bCf3sjxaKx" or "vimeo:119294481"
}

const VideoEmbed: React.FC<IProps> = ({ videoId, 'data-testid': testId }: IProps) => {
  const youtubeId = videoId.replace('youtube:', '');

  return (
    <Outer data-testid={testId || `video-embed-${videoId}`}>
      <Inner>
        <iframe
          title={`Embedded YouTube video ${youtubeId}`}
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${youtubeId}?rel=0`}
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Inner>
    </Outer>
  );
};

VideoEmbed.displayName = 'VideoEmbed';

export { VideoEmbed };
