import React from 'react';

import type { ILinkItem } from '@belong/types';
import { StyledSkipLink } from './SkipLink.styles';

export type ISkipLinkProps = Pick<ILinkItem, 'href'> & {
  children: React.ReactNode;
};

/**
 * The SkipLink component can be used to create a shortcut to navigate directly to a target within the page.
 *
 * The SkipLink becomes visible by clicking on some empty space in the page, and then hitting the "tab" key. If the user
 * does not navigate with the keyboard, they won't see the link.
 */
export const SkipLink: React.FC<ISkipLinkProps> = ({ children, href }) => (
  <StyledSkipLink href={href}>{children}</StyledSkipLink>
);

SkipLink.displayName = 'SkipLink';
