import React, { FunctionComponent } from 'react';
import { ALERT_LEVELS, ALERT_POSITION } from '@belong/types';
import styled from 'styled-components';
import Alert from '../../Alert';
import { IFeedbackCapture } from '../FeedbackCapture';
import { TEST_ID } from '../constants';
import { IFeedbackCaptureStep } from '.';

const AnimAlert = styled(Alert)`
  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: appear 300ms ease-in;
`;

interface ICompleteStep extends IFeedbackCaptureStep {
  successMessage: IFeedbackCapture['successMessage'];
}

const CompleteStep: FunctionComponent<ICompleteStep> = ({ successMessage }) => {
  return (
    <AnimAlert
      data-testid={TEST_ID.COMPLETE_STEP}
      id="feedback-alert"
      type={ALERT_LEVELS.SUCCESS}
      position={ALERT_POSITION.INLINE}
    >
      {successMessage}
    </AnimAlert>
  );
};

CompleteStep.displayName = 'CompleteStep';

export default CompleteStep;
