import styled from 'styled-components';

import { COLOURS, FONT_WEIGHT, isFocused, media } from '@belong/themes';

import withLink from '../../hocs/withLink';
import { Icon } from '../../Icon/Icon';
import { ListItem as BaseListItem } from '../../styles/List';

export const Label = styled.span`
  display: block;
  color: ${COLOURS.DARK_BLUE};
`;

export const ListItem = styled(BaseListItem)`
  border-bottom: 1px solid ${COLOURS.GREY_300};
  &:last-child {
    border-bottom: none;
  }
`;

/*
 * There is an issue when using the Icon within the Columns where it doesn't align correctly and adds extra padding below
 * the icon, setting vertical-align: top fixes this issue
 */
export const RightIcon = styled(Icon)`
  vertical-align: top;
  margin-left: 0.8rem;
  margin-bottom: 0.2rem;
  width: 1.2em; /* icon size is relative to font-size */
  height: 1.2em;
`;

export const StyledIcon = styled(Icon)`
  vertical-align: top;
  color: ${COLOURS.GREY_600};
  text-align: center;
`;

export const Link = withLink(styled.a`
  display: block;
  padding: 1.6rem 0;
  text-decoration: none;
  width: 100%;
  color: ${COLOURS.GREY_600};
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  line-height: 2.4rem;
  font-size: inherit;
  letter-spacing: 0px;
  cursor: pointer;

  ${media('xl')`
   padding: 2rem 0;
 `};

  &:hover {
    ${Label} {
      color: ${COLOURS.DARK_BLUE};
      text-decoration: underline;
    }
  }
  &:focus {
    ${isFocused()};
    svg {
      color: ${COLOURS.DARK_BLUE};
    }
  }
`);
