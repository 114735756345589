import React from 'react';
import { IInfoCallout } from '@belong/types';
import { RichText } from '../../styles/Typography/RichText/RichText';
import { Icon } from '../../Icon';
import * as Styles from './InfoCallout.styles';

export const TEST_ID = {
  INFO_CALLOUT: 'info-callout-container',
  ITEM_KEY_PREFIX: 'info-callout-item-',
  ICON_PREFIX: 'info-callout-icon-',
  RICH_TEXT_PREFIX: 'info-callout-richText-'
};

/**
 * Renders N number of icon + richtext rows
 */
export const InfoCallout: React.FC<IInfoCallout> = ({ items, dictionary }) => {
  if (items.length <= 0) {
    return null;
  }

  return (
    <Styles.ContentBlock data-testid={TEST_ID.INFO_CALLOUT}>
      {items.map((item, key) => {
        return (
          <Styles.ContentContainer
            data-testid={`${TEST_ID.ITEM_KEY_PREFIX}${key + 1}`}
            key={`${TEST_ID.ITEM_KEY_PREFIX}${key + 1}`}
          >
            <Icon
              data-testid={`${TEST_ID.ICON_PREFIX}${key + 1}`}
              name={item.iconName}
              size={'small'}
              className="label-icon"
            />
            <RichText
              {...item.richText}
              data-testid={`${TEST_ID.RICH_TEXT_PREFIX}${key + 1}`}
              dictionary={dictionary}
              alignment={{
                xs: 'left'
              }}
              spacing="small"
            />
          </Styles.ContentContainer>
        );
      })}
    </Styles.ContentBlock>
  );
};

InfoCallout.displayName = 'InfoCallout';
