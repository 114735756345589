import type { ColoursKey, FlairsKey, IMoleculeNavLinkGroup } from '@belong/types';
import { FRichText } from '../richText.factory';
import { parseNavLink } from '../../helpers/parseNavLink';

export const FMoleculeNavLinkGroup = (data: any): IMoleculeNavLinkGroup => {
  const { label, navLinks, labelColour, additionalHelpText } = data.fields;

  const parseLabelColour = (key: string): ColoursKey | FlairsKey => {
    switch (key) {
      case 'Blue':
        return 'BELONG_BLUE';
      case 'White':
        return 'WHITE';
      case 'Rainbow':
        return 'FOIL_SLICK';
      default:
        return 'BELONG_BLUE';
    }
  };

  return {
    id: data.sys.id,
    label,
    labelColour: parseLabelColour(labelColour),
    navLinks: navLinks.map(parseNavLink),
    additionalHelpText: additionalHelpText && FRichText(additionalHelpText)
  };
};
