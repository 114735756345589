import { IconSizeType } from '@belong/types';

export const ICON_SIZES: IconSizeType = {
  xxsmall: 'xxsmall',
  xsmall: 'xsmall',
  small: 'small',
  smallplus: 'smallplus',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge'
};
