import { FC, forwardRef } from 'react';
import { IButton, ICON_NAMES } from '@belong/types';
import { BUTTON_VARIANTS, FONT_COLOURS } from '@belong/themes';
import { logger } from '@belong/logging';
import StyledButton, {
  LoadingAnimation,
  ContentContainer,
  StyledIcon,
  SelectedIcon,
  FocusRing,
  ChildrenContainer
} from './Button.styles';

import { A11yCopy } from '../../styles/Typography/A11yCopy';
import { LOADING_BUTTON_PROGRESS, BUTTON_TEST_ID } from '../../../helpers/testIds';

export const ButtonBase: React.FC<IButton> = (
  {
    variant = 'primary',
    width = 'default',
    a11yLabel,
    ariaLabelledBy,
    label, // to exclude from HTML attributes as it is a parsing error for WCAG 2.0 validator
    children,
    isLoading,
    disabled,
    selected,
    isSmall,
    icon,
    target,
    currentSelected,
    hasSelectedIconColor = FONT_COLOURS.SUCCESS,
    withFocusRing = true,
    isSmallFontSize = false,
    hasIconLeft = false,
    iconOnly = false,

    ...otherProps
  }: IButton,
  ref
) => {
  const isPrimary = variant === BUTTON_VARIANTS.PRIMARY;
  const isQuaternary = variant === BUTTON_VARIANTS.QUATERNARY;

  if ('href' in otherProps && 'type' in otherProps && (otherProps as any).type === 'button') {
    // eslint-disable-next-line no-console
    console.warn(`type='button' on an anchor tag, this will cause display issues on Safari`);
  }

  if ((iconOnly || hasIconLeft) && (!icon || !isPrimary)) {
    logger.warn('iconOnly can only be set when icon is provided and variant is BUTTON_VARIANTS.PRIMARY');
  }

  const testid = otherProps['data-testid'] || BUTTON_TEST_ID[variant.toUpperCase()];

  const showIcon = icon && (isQuaternary || isPrimary);

  return (
    <StyledButton
      ref={ref}
      variant={variant}
      width={width}
      className="button"
      {...otherProps}
      data-testid={testid}
      data-state={isLoading ? 'loading' : undefined}
      isSmall={isSmall}
      isSmallFontSize={isSmallFontSize}
      data-button-variant={variant}
      aria-label={a11yLabel}
      aria-labelledby={ariaLabelledBy}
      target={target}
      isLoading={isLoading}
      disabled={isLoading || disabled}
      /** @DEPRECATED - selected and currentSelected are deprecated for Button and will be removed by design team */
      selected={selected}
      currentSelected={currentSelected}
      hasSelectedIconColor={hasSelectedIconColor}
      hasIconLeft={variant === 'primary' ? hasIconLeft : false}
      iconOnly={variant === 'primary' ? iconOnly : false}
    >
      {isLoading && (isPrimary || variant === BUTTON_VARIANTS.SECONDARY) && <LoadingAnimation />}
      {isLoading && (
        <A11yCopy>
          <progress data-testid={LOADING_BUTTON_PROGRESS} max={100}>
            {children}
          </progress>
        </A11yCopy>
      )}
      <ContentContainer aria-hidden={!!a11yLabel}>
        {showIcon && iconOnly && isPrimary ? (
          // Icon only rendering for primary button
          <>
            <StyledIcon
              name={icon!}
              variant={BUTTON_VARIANTS.PRIMARY}
              iconOnly={iconOnly}
              data-testid={BUTTON_TEST_ID.BUTTON_ICON}
            />
            <ChildrenContainer className="iconOnly" data-testid={BUTTON_TEST_ID.BUTTON_CHILDREN}>
              {children}
            </ChildrenContainer>
          </>
        ) : (
          // Rendering for everything else
          <>
            {showIcon && hasIconLeft && isPrimary && (
              <StyledIcon
                className="icon"
                name={icon!}
                variant={BUTTON_VARIANTS.PRIMARY}
                data-testid={BUTTON_TEST_ID.BUTTON_ICON}
                hasIconLeft
              />
            )}
            <ChildrenContainer className="children" data-testid={BUTTON_TEST_ID.BUTTON_CHILDREN}>
              {children}
            </ChildrenContainer>
            {showIcon && !hasIconLeft && (isQuaternary || isPrimary) && (
              <StyledIcon className="icon" name={icon!} variant={variant} data-testid={BUTTON_TEST_ID.BUTTON_ICON} />
            )}
            {/** @deprecated This is deprecated and unused. Design system team will remove */}
            {(selected || currentSelected) && <SelectedIcon name={ICON_NAMES.Tick} hasColor={hasSelectedIconColor} />}
          </>
        )}
      </ContentContainer>
      {!isQuaternary && withFocusRing && <FocusRing variant={variant} />}
    </StyledButton>
  );
};
ButtonBase.displayName = 'ButtonBase';

export const ButtonWithRef = forwardRef(ButtonBase as any) as FC<IButton>;
