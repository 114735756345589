import { useEffect, useState } from 'react';
import { isBrowser } from '@belong/utils/device';
import { BREAKPOINTS } from '@belong/themes';
import createProvider from '../_factory';

type BreakpointKey = keyof typeof BREAKPOINTS;

interface IProvider {
  isClient: boolean;
  isServer: boolean;
  currentBreakpoint: BreakpointKey;
  isMobileViewport: boolean;
  isDesktopViewport: boolean;
  isLoading: boolean;
}

type hook = (/* this hook takes no args */) => IProvider;

const useRuntime: hook = () => {
  const [isClient, setIsClient] = useState(isBrowser());
  const [isServer, setIsServer] = useState(!isBrowser());
  const [currentBreakpoint, setCurrentBreakpoint] = useState<BreakpointKey>('xs');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsClient(isBrowser());
    setIsServer(!isBrowser());
  }, []);

  useEffect(() => {
    let mediaQueryLists: { [key: string]: MediaQueryList } = {};

    // as the mediaQueryLists only dispatch an event when their match value actually changes,
    // we need to check all of the mediaQueryLists to find the highest matching breakpoint.
    const handleBreakpointChange = (): void => {
      const breakpoint = Object.entries(mediaQueryLists)
        .filter(([, val]) => val.matches)
        .map(([key]) => key)
        .pop() as BreakpointKey;

      setCurrentBreakpoint(breakpoint);
    };

    mediaQueryLists = Object.entries(BREAKPOINTS).reduce((obj, [key, value]) => {
      const monitor = window?.matchMedia(`(min-width: ${value / 16}em)`);
      if (!monitor) {
        return obj;
      }

      if ('addEventListener' in monitor && typeof monitor.addEventListener === 'function') {
        monitor.addEventListener('change', handleBreakpointChange);
      } else if ('addListener' in monitor && typeof monitor.addListener === 'function') {
        monitor.addListener(handleBreakpointChange);
      }

      if (monitor.matches) {
        setCurrentBreakpoint(key as BreakpointKey);
        setIsLoading(false);
      }
      return { ...obj, [key]: monitor };
    }, {});

    return (): void => {
      // remove listeners and delete stored objects
      Object.values(mediaQueryLists).forEach(monitor => {
        if ('removeEventListener' in monitor && typeof monitor.removeEventListener === 'function') {
          monitor.removeEventListener('change', handleBreakpointChange);
        } else if ('removeListener' in monitor && typeof monitor.removeListener === 'function') {
          monitor.removeListener(handleBreakpointChange);
        }
      });
      mediaQueryLists = {};
    };
  }, []);

  return {
    isClient,
    isServer,
    currentBreakpoint,
    isDesktopViewport: currentBreakpoint === 'md' || currentBreakpoint === 'lg' || currentBreakpoint === 'xl',
    isMobileViewport: currentBreakpoint === 'xs' || currentBreakpoint === 'sm',
    isLoading
  };
};

const { hook, provider } = createProvider<hook>(useRuntime, 'useRuntime');

export const Provider = provider;

export default hook;
