import React from 'react';

import type { IFooterMenu } from '@belong/types';
import { GlobalFooter } from '@belong/ui-components';

const GlobalFooterContainer: React.FC<IFooterMenu> = props => {
  if (!props) {
    return null;
  }

  return <GlobalFooter {...props} />;
};

export default GlobalFooterContainer;
