import { CONTAINER_MAX_WIDTHS, GRADIENTS, media } from '@belong/themes';
import styled, { css } from 'styled-components';
import { Container } from '../../layout/Container';

const ANIMATION_TIME_DROPDOWN = '0.5s';

export const StyledContainer = styled(Container)`
  padding-top: 4.2rem;
  padding-bottom: 5.6rem;
  padding-left: 3.2rem;
  padding-right: 2.4rem;

  margin: 0 5.8rem;
  // for LG breakpoint expand to screen width, no larger than xl/12 cols, for
  // XL breakpoint use default max-width
  max-width: ${CONTAINER_MAX_WIDTHS.xl};
  ${media('xl')`
    margin-left: auto;
    margin-right: auto;

  `}
`;

export const DropdownWrapper = styled.section<{ isExpanded: boolean }>`
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  transition: all ${ANIMATION_TIME_DROPDOWN} ease;
  background-color: black;
  transform: translateY(-5%);
  opacity: 0.5;
  visibility: hidden;
  z-index: -1;
  ${(props): any =>
    props.isExpanded &&
    css`
      visibility: visible;
      transform: translateY(100%);
      opacity: 100%;
    `}
`;

export const AccentStrip = styled.div`
  height: 0.8rem;
  background: ${GRADIENTS.ACCENT_STRIP_GLOBAL_HEADER};
`;

export const ContentWrapper = styled.div<{ showContent: boolean }>`
  display: none;
  pointer-events: none;

  ${(props): any =>
    props.showContent &&
    css`
      display: block;
      pointer-events: all;
    `};
`;
