import styled from 'styled-components';

/**
 * Ensure this component stretches to fill the available space. Without it,
 * the footer wil touch the header when there is nothing rendered in the <main>.
 */
export const ContentStretcher = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
