import { COLOURS, FLAIR_COLOURS } from '@belong/themes';
import styled, { css, keyframes } from 'styled-components';
import {
  PROGRESS_COLOUR_VARIANTS,
  PROGRESS_BACKGROUND_COLOUR_VARIANTS,
  progressColourVariants,
  progressBackgroundColourVariants,
  MaybeStyledCSS
} from '@belong/types';

export const PROGRESS_BAR_HEIGHT = '0.8rem';
export const PROGRESS_BAR_HEIGHT_THIN = '0.4rem';
const PROGRESS_BAR_HEIGHT_THICK = '1.2rem';

const colours = {
  [progressColourVariants.blue]: COLOURS.BELONG_BLUE,
  [progressColourVariants.foil_slick]: FLAIR_COLOURS.FOIL_SLICK,
  [progressColourVariants.orange]: COLOURS.ALT_ORANGE
};

const backgroundColours = {
  [progressBackgroundColourVariants.white]: COLOURS.WHITE,
  [progressBackgroundColourVariants.transparent]: 'transparent',
  [progressBackgroundColourVariants.grey]: COLOURS.GREY_200
};

export const Root = styled.div<{
  isThin: boolean;
  isDeterminate: boolean;
  borderRadius: boolean;
  hasBackground: PROGRESS_BACKGROUND_COLOUR_VARIANTS;
}>`
  position: relative;
  overflow: hidden;
  height: ${({ isThin, isDeterminate }): string => {
    if (isDeterminate) {
      return isThin ? PROGRESS_BAR_HEIGHT : PROGRESS_BAR_HEIGHT_THICK;
    }

    return isThin ? PROGRESS_BAR_HEIGHT_THIN : PROGRESS_BAR_HEIGHT;
  }};
  background-color: ${({ hasBackground }) => backgroundColours[hasBackground]};
  color: ${COLOURS.BELONG_BLUE_DARK};
  border-radius: ${({ borderRadius }) => (borderRadius ? '8px' : 'initial')};
`;

const BounceInFromRight = completed => keyframes`
  0% {
    width: 100%;
  }
  60% {
    width: ${completed - 1}%;
  }
  80% { width: ${completed + 1}%; }
  100% { width: ${completed}%; }
`;

export const DeterminateBar = styled.div<{
  completed: number;
  colour: PROGRESS_COLOUR_VARIANTS;
  borderRadius: boolean;
  animateBar: boolean;
  stepPosition?: number;
}>`
  width: ${({ animateBar, completed }) => (animateBar ? '100%' : `${completed}%`)};
  height: 100%;
  background: ${({ colour }) => colours[colour]};
  border-radius: ${({ borderRadius }) => (borderRadius ? '8px' : 'initial')};

  ${({ stepPosition, completed }): MaybeStyledCSS => {
    if (stepPosition) {
      return css`
        margin-left: ${stepPosition * completed}%;
        @media not (prefers-reduced-motion: reduce) {
          transition: margin-left 0.4s ease;
        }
      `;
    }
  }};

  ${({ animateBar, completed }) =>
    animateBar &&
    css`
      animation: ${BounceInFromRight(completed)} 1s ease-in-out;
      animation-fill-mode: forwards;
    `};
`;

const indeterminateBar1 = keyframes`
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
`;

const indeterminateBar2 = keyframes`
  0% {
    left: -200%;
    right: 100%; 
  }
  
  60% {
    left: 107%;
    right: -8%; 
  }
  
  100% {
    left: 107%;
    right: -8%; 
  }
`;

const indeterminateBackground1 = keyframes`
  0% {
    left: 0;
    right: 0;
  }
  
  100% {
    left: 100%;
    right: -100%;
  }
`;

const indeterminateBackground2 = keyframes`
  0% {
    left: -100%;
    right: 100%;
  }
  
  100% {
    left: 0;
    right: 0;
  }
`;

export const IndeterminateProgressBar = styled.div<{
  colour: PROGRESS_COLOUR_VARIANTS;
  borderRadius: boolean;
  animateBackground: boolean;
}>`
  &::before {
    content: '';
    position: absolute;
    width: auto;
    left: 0;
    bottom: 0;
    top: 0;
    transition: transform 0.2s linear;
    transform-origin: left;
    background: ${({ colour }) => colours[colour]};
    animation: ${indeterminateBar1} 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    border-radius: ${({ borderRadius }) => (borderRadius ? '8px' : 'initial')};

    ${({ animateBackground }) =>
      animateBackground &&
      css`
        animation: ${indeterminateBackground1} 1s linear infinite alternate;
        border-radius: initial;
      `};
  }

  &::after {
    content: '';
    position: absolute;
    width: auto;
    left: 0;
    bottom: 0;
    top: 0;
    background: ${({ colour }) => colours[colour]};
    transition: transform 0.2s linear;
    transform-origin: left;
    animation: ${indeterminateBar2} 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 1.15s;
    border-radius: ${({ borderRadius }) => (borderRadius ? '8px' : 'initial')};

    ${({ animateBackground }) =>
      animateBackground &&
      css`
        transform: rotateY(3.142rad);
        transform-origin: initial;
        animation: ${indeterminateBackground2} 1s linear infinite alternate;
        animation-delay: 0s;
        border-radius: initial;
      `};
  }
`;
