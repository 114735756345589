import { BROADCAST_CHANNELS, SESSION_KEYS } from '@belong/constants';
import { isBrowser } from '@belong/utils';

import { isLoginMessage } from './types';
import type { AuthenticationMessage } from './types';

export const postAuthenticationMessage = (authenticationMessage: AuthenticationMessage): void => {
  if (!isBrowser()) {
    return;
  }

  const channel = new BroadcastChannel(BROADCAST_CHANNELS.AUTHENTICATION);
  const message = { ...authenticationMessage };

  if (isLoginMessage(message)) {
    message.payload = btoa(message.payload);
    window.sessionStorage.setItem(SESSION_KEYS.USER_SESSION, message.payload);
  }

  channel.postMessage(message);
  channel.close();
};
