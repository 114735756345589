import fetch from 'isomorphic-unfetch';
import { jwtDecode } from 'jwt-decode';

import { COOKIES } from '@belong/constants';
import type { IUserIdToken, ObjectValues } from '@belong/types';

import { getClientLogEndpoint, LOG_LEVEL } from './config';

/**
 *  Scrape caller information
 *  Extract stack entries and traverse back 4 steps to get the calling process name
 *  @example
 *  stack:
 *    Error
 *      at getCaller (logger.ts?61ff:32)
 *      at formatLog (logger.ts?61ff:46)
 *      at writeLog (logger.ts?61ff:81)
 *      at ContentfulSection (VM13205 ContentfulSection.tsx:48)
 *  getCaller() // 'ContentfulSection'
 */
export const getCaller = (steps = 3): string => {
  const entries = new Error().stack?.match(/at (\S+)/g);
  if (entries && entries.length > 2) {
    const caller = entries[steps].slice(3);
    // clean up compiled method names in prod
    return caller.replace(/(.*_(?=.+\.))/, '');
  }

  return 'unknown process';
};

export const isObject = (variable: any): boolean =>
  variable && typeof variable === 'object' && variable.constructor === Object;

export const isBrowser = (): boolean => {
  return typeof window !== 'undefined' && typeof window.document !== 'undefined';
};

export const addSessionInfo = (message: string): string => {
  const sessionId = getClientSessionId();
  return sessionId ? `${sessionId} ${message.replaceAll(sessionId, '').trim()}` : message;
};

export const postToServer = (level: LOG_LEVEL, message: string): Promise<any> =>
  fetch(getClientLogEndpoint(), {
    method: 'POST',
    body: JSON.stringify({ level, message }),
    headers: { 'Content-Type': 'application/json' }
  }).catch(() => null);

export const getPageUrl = (): string => {
  try {
    // falls through to catch block if on the server
    const { pathname, search, hash } = window.location;
    return `${pathname}${search}${hash}`;
  } catch (err) {
    return '';
  }
};

export const getClientSessionId = (): string => {
  let correlationId: string = '';

  try {
    // falls through to catch block if on the server
    const authCookie = getCookie(COOKIES.BELONG);
    correlationId = JSON.parse(decodeURIComponent(authCookie))?.correlationId;
  } catch (err) {
    // not logged in
  }

  try {
    return correlationId || getCookie(COOKIES.SESSION);
  } catch (err) {
    return '';
  }
};

export const getClientAuthId = (): string => {
  try {
    // falls through to catch block if:
    // - on the server
    // - user not logged in
    // - decoding jwt fails
    const authCookie = getCookie(COOKIES.BELONG);

    const { idToken } = JSON.parse(decodeURIComponent(authCookie));
    return jwtDecode<IUserIdToken>(idToken)?.sub || '';
  } catch (err) {
    return '';
  }
};

export const getCookie = (name: ObjectValues<typeof COOKIES>): string => {
  const cookie = document.cookie.split(';').find(c => c.trim().startsWith(`${name}=`));
  const [, value] = cookie?.split('=') || [];
  return value || '';
};
