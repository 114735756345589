import type { IVideo } from '@belong/types';

export const FVideo = (data: any): IVideo => {
  const { fields } = data;
  const src = fields ? fields.file.url : data.url;
  const alt = fields ? fields.description : data.description;
  const contentType = fields ? fields.file.contentType : data.contentType;

  return {
    src,
    alt: alt ?? '',
    contentType
  };
};
