import { IFooterMenu, GlobalFooterConfigItem } from '@belong/types';
import { FMenuItem } from './organisms/organismMenuItem.factory';
import { parseSocialNavLink } from '../helpers/parseSocialNavLink';
import { FRichText } from './richText.factory';

export const FFooterMenu = (data: GlobalFooterConfigItem): IFooterMenu => {
  const { footerMenuItems, socialLinks, firstNationsAcknowledgement, belongAbnDetails } = data;
  return {
    footerMenuItems: footerMenuItems.map(menuItem => FMenuItem(menuItem)),
    socialLinks: socialLinks.map(parseSocialNavLink),
    firstNationsAcknowledgement: FRichText(firstNationsAcknowledgement),
    belongAbnDetails: FRichText(belongAbnDetails)
  };
};
