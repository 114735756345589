import React from 'react';
import { ButtonVariantValue, IImage, ILink, IIcon, IButton } from '@belong/types';

export enum MODAL_STATES {
  DEFAULT = 'default',
  LOADING_PRIMARY = 'loading_primary',
  LOADING_SECONDARY = 'loading_secondary',
  SUCCESS = 'success',
  ERROR = 'error'
}

interface IModalLink extends ILink {
  onClick?: () => void;
}

export interface IModalShellState {
  state?: MODAL_STATES;
}

export interface IModalShellProps {
  children?: React.ReactNode;
  isLoadingPrimary?: boolean;
  isLoadingSecondary?: boolean;
  hasHeading?: boolean;
}

export interface IModalShell extends IModalShellProps {
  image?: IImage;
  topIcon?: IIcon;
  primaryBtnLabel?: string;
  secondaryBtnLabel?: string;
  primaryAction?: () => void;
  secondaryAction?: () => void;
  primaryLink?: IModalLink;
  secondaryLink?: IModalLink;
  primaryBtnVariant?: ButtonVariantValue;
  secondaryBtnVariant?: ButtonVariantValue;
}

export interface IModal extends Partial<IModalComponentConfig> {
  isOpen: boolean;
  onClose: () => void;
  ariaLabelClose: string;
  ariaDialogLabelledBy?: string;
  ariaDialogDescribedby?: string;
  defaultHeading?: React.ReactNode;
  defaultContent?: React.ReactNode;
  successContent?: React.ReactNode;
  errorContent?: React.ReactNode;
  defaultObject?: IModalShell;
  successObject?: IModalShell;
  errorObject?: IModalShell;
  primaryBtnLabelInProgress?: string;
}

export interface IModalComponentConfig {
  /**
   * This is to allow the Modal to be used outside of a Next context.
   * The Button component that was previously hardcoded within the Modal
   * had some Next-specific behavior, and therefore pulled in Next deps
   * even if used outside of a Next app (i.e. Chat app).
   */
  ButtonComponent: React.ComponentType<IButton>;
}
