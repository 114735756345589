import styled from 'styled-components';

import { Copy } from '../../styles/Typography/Copy';

export const Label = styled(Copy).attrs(() => ({
  isBold: true,
  variant: 'medium',
  as: 'label'
}))<{ htmlFor: string }>`
  &[for] {
    cursor: pointer;
  }
`;
