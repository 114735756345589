import { MaybeStyledCSS } from '@belong/types';
import { THEME_VARIATIONS } from '.';

export const borderColorLight = (props): MaybeStyledCSS =>
  props.theme.borderColorLight || THEME_VARIATIONS.Light.borderColorLight;

export const outlineColor = (props): MaybeStyledCSS => props.theme.outlineColor || THEME_VARIATIONS.Light.outlineColor;

export const backgroundColor = (props): MaybeStyledCSS =>
  props.theme.backgroundColor || THEME_VARIATIONS.Light.backgroundColor;

export const backgroundColorLight = (props): MaybeStyledCSS =>
  props.theme.backgroundColorLight || THEME_VARIATIONS.Light.backgroundColorLight;

export const linkColor = (props): MaybeStyledCSS => props.theme.linkColor || THEME_VARIATIONS.Light.linkColor;

export const foregroundColor = (props): MaybeStyledCSS =>
  props.theme.foregroundColor || THEME_VARIATIONS.Light.foregroundColor;

export const foregroundColorDark = (props): MaybeStyledCSS =>
  props.theme.foregroundColorDark || THEME_VARIATIONS.Light.foregroundColorDark;

export const foregroundColorLight = (props): MaybeStyledCSS =>
  props.theme.foregroundColorLight || THEME_VARIATIONS.Light.foregroundColorLight;
