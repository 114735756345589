import type { ITheme } from '@belong/types';

import { THEME_VARIATIONS } from './theme-variations';
import { BREAKPOINTS, SPACES, cssUnit } from './helpers';

export * from './helpers';
export * from './GlobalStyles';
export * from './theme-variations';
export * as themeUtils from './theme-variations/utils';

// include the light theme as a default within the THEME
export const THEME: ITheme = {
  breakpoints: { ...BREAKPOINTS },
  spaces: SPACES,
  cssUnit,
  ...THEME_VARIATIONS.Light
};
