import React, { Children } from 'react';

import { Column } from './Column';
import { ColumnsVisual } from './ColumnsVisual';
import { IColumnsProps, columnsDefaultProps } from './Columns.types';

export const Columns: React.FC<IColumnsProps> = ({ children, ...rest }) => {
  Children.forEach(children, child => {
    if (React.isValidElement(child) && child.type !== Column) {
      throw new Error('Only Column is allowed as a child of Columns');
    }
  });

  return (
    <ColumnsVisual {...columnsDefaultProps} {...rest}>
      {children}
    </ColumnsVisual>
  );
};

Columns.displayName = 'Columns';
