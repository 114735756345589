import { COLOURS, BORDER_RADIUS } from '@belong/themes';
import { AccordionRowVariant, MaybeStyledCSS, StyledCSS } from '@belong/types';
import styled, { css } from 'styled-components';

export const Item = styled.li`
  list-style: none;
`;

export const Wrapper = styled.ul<{ variant?: AccordionRowVariant; roundedCorners?: boolean }>`
  padding: 0;

  ${({ roundedCorners }): MaybeStyledCSS =>
    roundedCorners &&
    css`
      border-top-left-radius: ${BORDER_RADIUS.CARD};
      border-top-right-radius: ${BORDER_RADIUS.CARD};
      border-bottom-left-radius: ${BORDER_RADIUS.CARD};
      border-bottom-right-radius: ${BORDER_RADIUS.CARD};
      overflow: hidden;
    `}

  ${({ variant }): StyledCSS => {
    switch (variant) {
      case 'slim':
        return css`
          ${Item} + ${Item} {
            border-top: 0.1rem solid ${COLOURS.GREY_500};
          }

          ${Item}:only-child {
            border-bottom: 0.1rem solid ${COLOURS.GREY_500};
          }
        `;

      default:
        return css`
          ${Item} + ${Item} {
            border-top: 0.1rem solid ${COLOURS.GREY_300};
          }

          ${Item}:only-child {
            border-top: 0.1rem solid ${COLOURS.GREY_300};
            border-bottom: 0.1rem solid ${COLOURS.GREY_300};
          }
        `;
    }
  }}
`;
