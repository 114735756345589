import React, { useRef } from 'react';
import { ICON_NAMES, ITestable } from '@belong/types';
import * as Search from './Searchbox.styles';
import { ITextInput } from '../TextInput';
import Icon from '../../Icon/Icon';
import { A11yCopy } from '../../styles/Typography/A11yCopy';

export interface ISearchbox extends ITextInput, ITestable {
  a11yClear: string;
  handleClear: React.MouseEventHandler<HTMLButtonElement>;
  hasSelection: boolean;
  id?: string;
  isBusy?: boolean;
  a11yBusy?: string;
  searchIcon?: boolean;
  expandIcon?: boolean;
  refProp?: React.RefObject<HTMLInputElement>;
}

/**
 * If you are looking for typeahead functionality, have a look to Combobox.
 */
const Searchbox: React.FC<ISearchbox> = ({
  a11yClear,
  handleClear,
  a11yBusy,
  isBusy,
  searchIcon = false,
  expandIcon = false,
  hasSelection,
  refProp,
  ...inputProps
}: ISearchbox) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { id, value } = inputProps;
  const idBusy = isBusy ? `${id}Busy` : undefined;
  const describedBy = inputProps['aria-describedby'];
  const shouldShowClearBtn = !!value?.toString().trim() || hasSelection;
  const newInputProps = {
    ...inputProps,
    'aria-describedby': isBusy ? `${describedBy || ''} ${idBusy}` : describedBy
  };

  // typescript refuses to take 2 interfaces for searchbox
  // so I console.error if the props are incorrect
  if (isBusy && (!id || !a11yBusy)) {
    // eslint-disable-next-line no-console
    console.error('Searchbox is missing "id" or "a11yBusy" when "isBusy" === true');
  }

  return (
    <Search.Wrapper>
      {isBusy && <Search.Progress />}
      {searchIcon && (
        <Search.IconWrapper
          onClick={() => {
            const ref = refProp || inputRef;
            if (ref && ref.current) {
              ref.current.focus();
            }
          }}
        >
          <Icon name={ICON_NAMES.Search} />
        </Search.IconWrapper>
      )}
      <Search.Input
        ref={refProp || inputRef}
        id={id}
        searchIcon={searchIcon}
        aria-invalid={newInputProps.isError}
        {...newInputProps}
      />
      {shouldShowClearBtn && (
        <Search.ClearBtn onClick={handleClear} onDarkBG={newInputProps.onDarkBG}>
          Clear
        </Search.ClearBtn>
      )}
      {!shouldShowClearBtn && expandIcon && <Search.ExpandIcon />}
      {isBusy && (
        <A11yCopy id={idBusy} aria-live="polite">
          {a11yBusy}
        </A11yCopy>
      )}
    </Search.Wrapper>
  );
};

Searchbox.displayName = 'Searchbox';
export default Searchbox;
