import { getClientLogEndpoint, isProd, LOG_LEVEL, RESERVED_LOGGERS } from './config';
import { postToServer, addSessionInfo, isBrowser, getClientAuthId, getPageUrl } from './utils';
import { combineLogs } from './combineLogs';

/**
 * Decide whether to allow a log to be processed
 * ERROR: Allow everywhere
 * WARN: Allow everywhere
 * LOG: Allow only in non-prod
 * DEBUG: Allow only in non-prod
 */
const isEnabled = (level: LOG_LEVEL): boolean => {
  if (RESERVED_LOGGERS.includes(level)) {
    return true;
  }
  return !isProd;
};

/**
 * Decide whether to try to send a client log back to the server
 * Note - if client logging is enabled, the messages won't appear in the browser console
 * Prerequisites:
 * - logging endpoint has been set
 * - in browser
 * - in production
 * - only errors & info
 */
const isClientLoggingEnabled = (level: LOG_LEVEL): boolean => {
  if (!getClientLogEndpoint()) {
    return false;
  }
  if (!isBrowser()) {
    return false;
  }
  if (!isProd) {
    return false;
  }
  return [LOG_LEVEL.INFO, LOG_LEVEL.ERROR].includes(level);
};

const getTimeStamp = (): string => {
  // UTC DateTime
  return new Date().toISOString();
};

/**
 * Format a log message to inject extra info.
 * Note: Turns off verbose logging for local dev
 */
const formatLog = (msg: string, level: LOG_LEVEL): string => {
  if (isProd) {
    // filter out empty strings and return
    return [getTimeStamp(), level.toUpperCase(), getPageUrl(), getClientAuthId(), addSessionInfo(msg.trim())]
      .filter(Boolean)
      .join(' ');
  }
  return msg.trim();
};

const isFormattedLog = (message: string): boolean => {
  // Expect formatted message to start with ISO timestamp (2021-01-01T00:00:00.000Z)
  return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z\s/.test(message);
};

/**
 * Basic logging
 * - Supports multiple args (like console.log)
 * - Automatically stringifies complex objects and errors
 * - Adds sessionId when on the client
 */
const writeLog = (level: LOG_LEVEL, ...args: any[]): Promise<any> | void => {
  let message = combineLogs(args);
  if (!isFormattedLog(message)) {
    // don't reformat client-logs
    message = formatLog(message, level);
  }

  // send client logs to the server (does NOT log to browser console)
  if (isClientLoggingEnabled(level)) {
    return postToServer(level, message);
  }

  if (!isEnabled(level)) {
    return;
  }

  try {
    /* eslint-disable-next-line no-console */
    console[level](message);
  } catch (err) {
    //
  }
};

export default writeLog;
