import { COLOURS, FONT_COLOURS } from '@belong/themes';
import styled, { css } from 'styled-components';
import { withLink, Icon } from '@belong/ui-components';

export const Wrapper = withLink(styled.button<{ align: 'left' | 'right' }>`
  align-items: center;
  background: transparent;
  border-radius: 0.8rem;
  border: 2px solid transparent;
  color: white;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  height: 3.8rem;
  justify-content: ${(props): any => props.align};
  text-decoration: none;
  width: 100%;
  // margins and padding used to set focus border position
  /* margin-bottom: 0.4rem; */

  ${({ align }): any => css`
    ${align === 'right'
      ? css`
          margin-left: 0;
          margin-right: 0;
          padding-left: 0;
          padding-right: 0;
        `
      : css`
          margin-left: -1rem;
          margin-right: -1rem;
          padding-left: 0.8rem;
          padding-right: 0.8rem;
        `}
  `}

  &[aria-current] {
    color: ${FONT_COLOURS.ACTIVE};
    font-weight: 700;
    &:hover {
      font-weight: 700;
    }
  }

  &:focus {
    border: 0.2rem solid ${COLOURS.BELONG_BLUE_DARK};
    color: ${FONT_COLOURS.ACTIVE};
    outline: none;
  }

  &&:focus * {
    color: ${FONT_COLOURS.ACTIVE};
  }

  &:hover {
    font-weight: 600;
    color: ${FONT_COLOURS.ACTIVE};
    text-decoration: underline;
  }
`);

export const StyledIcon = styled(Icon)`
  margin-right: 0.8rem;
`;
