import { COOKIES, NBN_SESSION_KEYS, SESSION_KEYS } from '@belong/constants';
import { clearAuthCookie, clearMfaEnrolCookie, clearCookie } from '@belong/cookies';
import type { IAuthenticators, IUserMeta } from '@belong/types';
import { ELEVATED_SCOPES, OOB_CHANNELS, READ_PROFILE_SCOPES } from '@belong/types';
import { logger } from '@belong/logging';
import { isBrowser, clearKeyFromSessionStorage } from '@belong/utils';

import { postAuthenticationMessage } from '../useAuthenticationListener';

interface IProfileResponse {
  isClassicMobileOnly: boolean;
  isNewStack: boolean;
  isFixedOnly: boolean;
  isClassicCombined: boolean;
}

export const usersStackProfile = (data: IUserMeta): IProfileResponse => {
  const { isClassicCustomer, mobileOctaneId, fixedOctaneId, isMigratedToDigitalStack } = data;
  const isClassicMobileOnly = !!(mobileOctaneId && !fixedOctaneId && isClassicCustomer && !isMigratedToDigitalStack);
  const isFixedOnly = !!(fixedOctaneId && !mobileOctaneId && isClassicCustomer && !isMigratedToDigitalStack);
  const isClassicCombined = !!(fixedOctaneId && mobileOctaneId && isClassicCustomer && !isMigratedToDigitalStack);
  const isNewStack = isMigratedToDigitalStack;

  return {
    isClassicMobileOnly,
    isNewStack,
    isFixedOnly,
    isClassicCombined // Mobile and Fixed are on Classic
  };
};

const checkForScopes = (scope: string, scopes: string[]): boolean => {
  return scopes.some(s => scope.includes(s));
};

export const isUserSteppedUp = (scope: string): boolean => {
  return checkForScopes(scope, ELEVATED_SCOPES);
};

export const canUserReadProfile = (scope: string): boolean => {
  return checkForScopes(scope, READ_PROFILE_SCOPES);
};

export const isUserMfaEnrolled = (authenticators: IAuthenticators): boolean => {
  return authenticators.some(
    authenticator =>
      authenticator.active &&
      (authenticator.oobChannel === OOB_CHANNELS.SMS || authenticator.oobChannel === OOB_CHANNELS.EMAIL)
  );
};

export const clearSession = (): void => {
  clearAuthCookie();
  clearMfaEnrolCookie();

  clearCookie(COOKIES.UNIQUE_IDENTIFIER);
  clearCookie(COOKIES.NBN_PROMO);
  clearCookie(COOKIES.SESSION);
  clearCookie(COOKIES.IDV_SESSION);

  clearKeyFromSessionStorage(SESSION_KEYS.NBN_PROMO);
  clearKeyFromSessionStorage(SESSION_KEYS.USER_SESSION);
  clearKeyFromSessionStorage(SESSION_KEYS.NBN_ORDER);
  clearKeyFromSessionStorage(SESSION_KEYS.STORE);
  clearKeyFromSessionStorage(SESSION_KEYS.SQ_STEPS);
  clearKeyFromSessionStorage(SESSION_KEYS.NOTIFICATION_CHANNEL);
};

export const logout = (redirectTo: string): void => {
  clearSession();

  // notify any other tabs & windows that logout has occurred
  postAuthenticationMessage({ action: 'logout' });

  // force a page change/refresh to clear browser memory and mobx stores
  if (isBrowser()) {
    window.location.assign(redirectTo);
  }
};

export const clearServiceQueryData = ({ storage }: { storage: Storage }): void => {
  [
    // Service qualification (SQ)
    NBN_SESSION_KEYS.SQ_DONE,
    NBN_SESSION_KEYS.SQ_MODEL,
    // Auto referral SQ
    'sqFlow',
    // Product choice
    NBN_SESSION_KEYS.PRODUCTS,
    // Nbn Journey nbn store
    NBN_SESSION_KEYS.STORE,
    // Nbn Journey nbn order
    NBN_SESSION_KEYS.NBN_ORDER,
    'nbnCustomer',
    // implicit promotions
    'nbnPromo'
  ].forEach(key => {
    storage.removeItem(key);
  });
};

export const clearAgentSession = (): void => {
  logger.info('Agent log out, clear agent session');
  clearSession();
  clearServiceQueryData({ storage: window.sessionStorage });
};

export const clearNbnStorage = (): void => {
  Object.values(NBN_SESSION_KEYS).forEach(storageKey => clearKeyFromSessionStorage(storageKey));

  clearServiceQueryData({ storage: window.sessionStorage });
  clearCookie(COOKIES.NBN_PROMO);
};
