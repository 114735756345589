import styled from 'styled-components';

import { Copy } from '../../styles/Typography/Copy';

export const Wrapper = styled.div`
  margin: 1rem 0;
`;

export const CountriesToggleButtonLabel = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const LabelText = styled.span`
  margin-right: 5px;
`;

export const RegionsWrapper = styled.span`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));

  .countries-column:first-child {
    margin-left: 0;
  }

  .countries-column:last-child {
    margin-right: 0;
  }
`;

export const RegionContainer = styled.span`
  display: flex;
  flex-direction: column;
  min-width: 11rem;
  max-width: 13rem;
  margin: 2rem 0;
  text-align: left;
`;

export const RegionName = styled(Copy)`
  margin-bottom: 8px;
`;

export const CountriesColumn = styled.span`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
