import React from 'react';
import styled from 'styled-components';
import { logger } from '@belong/logging';
import Head from 'next/head';
import Section, { ISection } from '../../styles/Section';
import ErrorGraphic from './assets/errorGraphic';
import { ERROR_PAGE_CONTENT, ERROR_PAGE_META } from './ErrorPageContent.data';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 1.6rem;
  }
`;

const Title = styled.h4`
  margin-top: 4rem;
`;

interface IErrorPage extends ISection {
  statusCode?: number;
}

export const TEST_ID = {
  section: 'SectionErrorPage',
  title: 'ErrorPage__title',
  message: 'ErrorPage__message'
};

const ErrorPageMeta: React.FC<{ statusCode: number }> = ({ statusCode }) => {
  return (
    <Head>
      <title key="title">{`${statusCode} ${ERROR_PAGE_META.pageTitle}`}</title>
      <meta name="description" key="description" content={ERROR_PAGE_META.description} />
      <meta name="format-detection" content="telephone=no" />
      <meta name="keywords" key="keywords" content={ERROR_PAGE_META.keywords} />
    </Head>
  );
};

ErrorPageMeta.displayName = 'ErrorPageMeta';
/**
 * Generic content to be shown on a 404 or 500 page across all apps
 */
export const ErrorPageContent: React.FC<IErrorPage> = ({ statusCode = 404, ...sectionProps }) => {
  logger.debug('ErrorPageContent', { statusCode });
  const content = ERROR_PAGE_CONTENT[statusCode || 404] || ERROR_PAGE_CONTENT[404];
  return (
    <Section data-testid={TEST_ID.section} hasPaddingTop hasPaddingBottom {...sectionProps}>
      <ErrorPageMeta statusCode={statusCode} />
      <Container>
        <ErrorGraphic />
        <Title data-testid={TEST_ID.title}>{content.title}</Title>
        <p data-testid={TEST_ID.message}>{content.message}</p>
      </Container>
    </Section>
  );
};
ErrorPageContent.displayName = 'ErrorPageContent';
