import { useContext, useState, useEffect, useCallback } from 'react';
import { Document } from '@contentful/rich-text-types';
import { applyDictionaryToString, applyDictionaryToDocument } from './dictionary-utils';
import { DictionaryContextType, DictionaryContext } from './context';

const getDictionaryValue = (dictionary: DictionaryContextType, entryName: string, defaultValue: string): string => {
  const hasProperty = Object.prototype.hasOwnProperty.call(dictionary, entryName);
  return String((hasProperty ? dictionary[entryName] : defaultValue) ?? defaultValue);
};

/**
 * Looks up an entry in the dictionary and reacts to changes.
 * If the entry is not found, will return a default value.
 */
export const useDictionaryValue = (entryName: string, defaultValue = ''): string => {
  const dictionary = useContext(DictionaryContext);
  const initialValue = getDictionaryValue(dictionary, entryName, defaultValue);
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    const newValue = getDictionaryValue(dictionary, entryName, defaultValue);
    setValue(newValue);
  }, [dictionary, entryName, defaultValue]);
  return value;
};

export type UseApplyDictionaryToStringResult = (input: string) => string;

/**
 * Returns a function which takes an input string and returns a new string with
 * the same content but with string interpolations applied based on the
 * dictionary context.
 *
 * @example
 *
 * const applyDictionaryToString = useApplyDictionaryToString();
 * const result = applyDictionaryToString('Hello {{firstName}}');
 * const output = <span>{result}</span>; // => Hello Jamie
 */
export const useApplyDictionaryToString = (): UseApplyDictionaryToStringResult => {
  const dictionary = useContext(DictionaryContext);
  const result = useCallback<UseApplyDictionaryToStringResult>(
    input => applyDictionaryToString(entryName => getDictionaryValue(dictionary, entryName, ''), input),
    [dictionary]
  );
  return result;
};

export type UseApplyDictionaryToDocumentResult = (input: Document) => Document;

/**
 * Returns a function which takes an input rich text document and returns a new
 * document with the same content but with string interpolations applied on all
 * text based on the dictionary context.
 *
 * @example
 *
 * const applyDictionaryToDocument = useApplyDictionaryToDocument();
 * const input = mockRichTextDocument([{ p: 'Hello {{firstName}}' }]);
 * const result = applyDictionaryToDocument(input);
 * const output = <RichTextBase>{result}</RichTextBase>; // => Hello Jamie
 */
export const useApplyDictionaryToDocument = (): UseApplyDictionaryToDocumentResult => {
  const dictionary = useContext(DictionaryContext);
  return useCallback<UseApplyDictionaryToDocumentResult>(
    input => applyDictionaryToDocument(entryName => getDictionaryValue(dictionary, entryName, ''), input),
    [dictionary]
  );
};
