import { RenderNode, RenderMark, RenderText } from '@contentful/rich-text-react-renderer';
import type { IDictionary, IHasAlignment, IHasColor, IRichText, ISpacingProps, ITestable } from '@belong/types';

export enum TEXT_NODES {
  display = 'display',
  displaySmall = 'displaySmall',
  headingLarge = 'headingLarge',
  headingMedium = 'headingMedium',
  headingSmall = 'headingSmall',
  headingXSmall = 'headingXSmall',
  copyLarge = 'copyLarge',
  copyMedium = 'copyMedium',
  copySmall = 'copySmall'
}

export type TTextNodes = keyof typeof TEXT_NODES;

export type TEntryNodes =
  | 'countryList'
  | 'elementColumns'
  | 'elementEmbeddedInformationModal'
  | 'elementLink'
  | 'elementSystemIcon'
  | 'moleculeAlert'
  | 'moleculeInformationCalloutRow'
  | 'moleculeLinkList'
  | 'moleculeMediaCaptionBlock'
  | 'organismFeedbackCapture'
  | 'sectionAccordionList';

export type TNodeKeys = TEntryNodes | TTextNodes;

export type TNODES = { [k in TNodeKeys]: (p: any, option?: any) => JSX.Element };

export interface IRichTextProps extends IRichText, IHasColor, ISpacingProps, IHasAlignment, ITestable {
  h1?: TTextNodes;
  h2?: TTextNodes;
  h3?: TTextNodes;
  h4?: TTextNodes;
  h5?: TTextNodes;
  h6?: TTextNodes;
  p?: TTextNodes;
  dictionary?: IDictionary;
  renderNode?: RenderNode;
  renderMark?: RenderMark;
  renderBlockEntries?: RenderNode;
  renderEmbeddedInlines?: RenderNode;
  renderText?: RenderText;
  id?: string;
  spacing?: 'small' | 'medium' | 'large';
  contentLength?: 'short' | 'long';
  headingId?: string;
}
