import React from 'react';

import { RegionContainer, CountriesColumn, RegionName } from './RegionalCountriesList.styles';

export interface IRegionalList {
  regionName: string;
  countries: string[];
}

export const RegionalCountries: React.FC<IRegionalList> = ({ regionName, countries }) => {
  return (
    <RegionContainer>
      <RegionName role="heading" isBold as="span">
        {regionName}
      </RegionName>
      <CountriesColumn className="countries-column" role="list">
        {countries.map((c: string) => {
          return (
            <span role="listitem" key={c}>
              {c}
            </span>
          );
        })}
      </CountriesColumn>
    </RegionContainer>
  );
};

RegionalCountries.displayName = 'RegionalCountries';
