import React, { useEffect, useState, useRef } from 'react';
import { formatSeconds } from '@belong/date-utils';
import { useIntersection } from '../../hooks/useIntersection';
import {
  Container,
  Video,
  PlayButton,
  ButtonLabel,
  HiddenTooltip,
  ControlLabel,
  TimeLabel,
  TimeSeparator,
  DurationLabel
} from './VideoContainer.styles';

const MAX_PLAY_TIMES = 3;
const MAX_DURATION_FOR_AUTOPLAY = 5;
const LONG_DURATION = 15;

export interface IVideoContainer {
  className?: string;
  src: string;
  contentType: string;
  alt?: string;
  'data-testid'?: string;
}

const VideoContainer: React.FC<IVideoContainer> = ({
  className,
  src,
  contentType,
  alt,
  ...otherProps
}: IVideoContainer) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState(true);

  const autoPlayTimesRef = useRef<number>(1);
  const onScreen = useIntersection(videoRef);

  useEffect(() => {
    const video = videoRef.current;

    if (!video) {
      return;
    }

    const loadedHandler = () => {
      const length = Math.floor(video.duration);

      // If duration is set, but over 5s, stop auto play
      if (length > MAX_DURATION_FOR_AUTOPLAY) {
        video.pause();
        video.removeAttribute('autoplay');
        setIsPlaying(false);
        autoPlayTimesRef.current = MAX_PLAY_TIMES + 1;
      }

      // When video meta is loaded, set the duration to state
      setDuration(length);
    };

    if (video.duration > 0) {
      loadedHandler();
      return;
    }

    video.addEventListener('loadedmetadata', loadedHandler);

    return () => {
      video.removeEventListener('loadedmetadata', loadedHandler);
    };
  }, []);

  useEffect(() => {
    const handleKeyboardShortcut = event => {
      // shortcut key is 'k'
      if (event.keyCode === 75) {
        handleVideoControl();
      }
    };

    document.addEventListener('keydown', handleKeyboardShortcut);
    return () => {
      document.removeEventListener('keydown', handleKeyboardShortcut);
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    // If video not loaded or duration not available, do nothing
    if (!video || Number.isNaN(duration) || duration <= 0) {
      return;
    }

    const stopAutoPlay = () => {
      video.removeAttribute('autoplay');
      video.removeEventListener('ended', finishHandler);
    };

    const finishHandler = () => {
      // Auto play for 3 times
      if (autoPlayTimesRef.current < MAX_PLAY_TIMES) {
        video.play();
      } else {
        stopAutoPlay();
        setIsPlaying(false);
      }

      autoPlayTimesRef.current += 1;
    };

    const pauseHandler = () => {
      // If user paused the video, stop auto play
      if (video.currentTime < video.duration) {
        stopAutoPlay();
      }

      setIsPlaying(false);
    };

    const playHandler = () => {
      setIsPlaying(true);
    };

    video.addEventListener('pause', pauseHandler);
    video.addEventListener('play', playHandler);

    if (duration <= MAX_DURATION_FOR_AUTOPLAY) {
      video.addEventListener('ended', finishHandler);
    }

    if (autoPlayTimesRef.current < MAX_PLAY_TIMES) {
      if (!onScreen) {
        video.pause();
      } else {
        video.play();
      }
    }

    return () => {
      video.removeEventListener('ended', finishHandler);
      video.removeEventListener('pause', pauseHandler);
      video.removeEventListener('play', playHandler);
    };
  }, [duration, onScreen]);

  useEffect(() => {
    const video = videoRef.current;

    if (!video) {
      return;
    }

    // Handler for video currentTime update, only trigger UI re-render every 1 second
    const timeUpdateHandler = () => {
      const newCurrent = Math.floor(video.currentTime);
      if (newCurrent !== currentTime) {
        setCurrentTime(newCurrent);
      }
    };

    video.addEventListener('timeupdate', timeUpdateHandler);

    return () => {
      video.removeEventListener('timeupdate', timeUpdateHandler);
    };
  }, [currentTime]);

  useEffect(() => {
    videoRef.current?.load();
  }, [src]);

  const handleVideoControl = () => {
    const video = videoRef.current;

    if (!video) {
      return;
    }

    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  const showControl = duration < LONG_DURATION;
  const controlLabel = !isPlaying ? 'Play' : 'Pause';
  const durationLabel = (
    <>
      {showControl && <ControlLabel>{controlLabel}</ControlLabel>}
      <TimeLabel>{formatSeconds(currentTime)}</TimeLabel>
      <TimeSeparator>|</TimeSeparator>
      <DurationLabel>{formatSeconds(duration)}</DurationLabel>
    </>
  );

  return (
    <Container className={className} onClick={handleVideoControl} {...otherProps}>
      <Video ref={videoRef} width="100%" height="100%" preload="metadata" autoPlay muted playsInline>
        <source src={src} type={contentType} />
      </Video>
      <HiddenTooltip role="tooltip" aria-label={alt} />
      <PlayButton
        type="button"
        aria-keyshortcuts="k"
        className={isPlaying ? 'playing' : ''}
        aria-label={`${controlLabel} keyboard shortcut k`}
        title={`${controlLabel} (k)`}
      >
        <ButtonLabel className="play-button-label" showControl={showControl}>
          {durationLabel}
        </ButtonLabel>
      </PlayButton>
    </Container>
  );
};

VideoContainer.displayName = 'VideoContainer';
export { VideoContainer };
