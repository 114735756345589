import { COLOURS, Z_INDEX, FONT_COLOURS, media, mediaMap } from '@belong/themes';
import styled, { css, keyframes } from 'styled-components';
import { createStatic } from 'styled-components-breakpoint';

import { ButtonWithRef } from '../../actions/Button/ButtonBase';
import Container from '../../layout/Container';
import { PictureContainer } from '../../media/PictureContainer';
import { MODAL_STATES, IModalShellState } from './Modal.types';
import UICard from '../../styles/UICard';

const customBreakpoints = createStatic({ xs: 320 });

const SVG_SIZE = {
  xs: '4.8rem',
  md: '7.2rem'
};

// Used for adding some padding to the close button
// It's too small
const CLOSE_BUTTON_PADDING = '0.8rem';

// When there is modal heading, need to adjust the close button position.
// Using px here to make sure button is always in the right top coner with
const CLOSE_BUTTON_POSITION = css`
  position: absolute;
  top: -16px;
  right: -8px;

  ${media('md')`
    top: -12px;
    right: -12px;
  `};
`;

const FadeIn = keyframes`
  from {
    transform: scale(0.25);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const FadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.25);
    opacity: 0;
  }
`;

export const Dialog = styled.dialog`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  border: none;
  padding: 0;
  z-index: ${Z_INDEX.MODAL};
  background: ${COLOURS.BLACK_OVERLAY};
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(2px);
`;

export const InnerContainer = styled(Container)`
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 1.6rem;
  overflow-y: auto;
`;

export const DialogBox = styled(UICard)<IModalShellState & { isOpen: boolean }>`
  position: relative;
  text-align: center;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  max-height: 100vh;

  ${customBreakpoints.xs`
    padding: 2.4rem 1.6rem;
  `}

  ${media('md')`
    padding: 2.4rem
  `};

  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  animation: ${({ isOpen }) => (isOpen ? FadeIn : FadeOut)} 0.2s linear;
  transition: visibility 0.2s linear;

  ${({ state }) =>
    state === MODAL_STATES.SUCCESS &&
    css`
      border-top: 0.4rem solid ${FONT_COLOURS.SUCCESS};
    `}

  ${({ state }) =>
    state === MODAL_STATES.ERROR &&
    css`
      border-top: 0.4rem solid ${FONT_COLOURS.ERROR};
    `}
`;

export const DialogBoxOverlay = styled.div`
  background-color: #ffffff80;
  position: absolute;
  top: -1px; /* also over the Root component border */
  right: -1px;
  bottom: -1px;
  left: -1px;
`;

// Use BUTTON_PADDING to adjust the close button position when there is no modal heading.
export const DialogHeader = styled.div<{ hasHeading?: boolean }>`
  position: absolute;
  top: -${CLOSE_BUTTON_PADDING};
  right: -${CLOSE_BUTTON_PADDING};
  margin: 1.6rem;
  padding: 0 1rem;

  ${customBreakpoints.xs`
    padding: 0 3rem;
  `};

  ${media('md')`
    margin: 2.4rem
  `};

  ${props =>
    props.hasHeading &&
    css`
      position: relative;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 0 1.6rem;

      ${media('md')`
        margin: 0 0 2.4rem;
      `};
    `}
`;

export const CloseButton = styled(ButtonWithRef)<{ hasHeading?: boolean }>`
  padding: ${CLOSE_BUTTON_PADDING};
  line-height: 0;
  background-color: transparent;
  border-color: transparent;

  &:hover,
  &:active {
    background-color: transparent;
    border-color: transparent;
  }

  ${props => props.hasHeading && CLOSE_BUTTON_POSITION}
`;

export const DialogBody = styled.div`
  max-height: 80vh;
  overflow: auto;

  > * + * {
    margin-top: 1.6rem;
  }
`;

export const ImageWrapper = styled.div`
  text-align: center;
`;

export const Image = styled(PictureContainer)`
  object-fit: contain;
  ${mediaMap(
    SVG_SIZE,
    (size: string) => css`
      width: ${size};
      height: ${size};
    `
  )}
`;

export const DialogFooter = styled.div`
  margin-top: 2.4rem;

  ${media('md')`
    margin-top: 3.2rem
  `};
`;
