import { ButtonVariantValue, IButton, MediaMapResult, ObjectValues, StyledCSS } from '@belong/types';

import {
  BORDER_RADIUS,
  BUTTON_VARIANTS,
  COLOURS,
  FONT_WEIGHT,
  isFocused,
  spacingMixin,
  FOCUS_RING_WIDTH,
  media,
  mediaMap
} from '@belong/themes';
import styled, { css } from 'styled-components';
import { LinearProgress } from '@belong/ui-core';
import ButtonUnstyled from '../ButtonUnstyled';
import Icon from '../../Icon/Icon';

const borderWidth = 0.2; // rems
const PADDING_SIZE = {
  // rems
  SMALL: {
    HORZ: 1.4,
    VERT: 0.6
  },
  DEFAULT: {
    HORZ: 2.2,
    VERT: 1
  }
};

export const iconOnlyButtonStyles = css`
  padding: 1rem;
  border-radius: 100%;
`;

export const iconOnlyLabelStyles = css`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const iconOnlyIconStyles = css`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0;
  margin-left: 0;
`;

export const BaseStyles = css<any>`
  ${spacingMixin};

  cursor: pointer;
  text-align: center;
  border-width: ${borderWidth}rem;
  border-style: solid;
  max-width: 100%;
  color: ${COLOURS.BLACK};
  position: relative;
  // To hide scroll bars when button loader running
  // Firefox
  scrollbar-width: none;
  // Everyone else
  ::-webkit-scrollbar {
    display: none;
  }

  &:focus {
    ${(): StyledCSS => isFocused()};
  }

  // Quat buttons get the regular rectangular focus outline.
  // Other button types have the custom <FocusRing> instead.
  &:focus {
    ${({ variant }) =>
      variant !== BUTTON_VARIANTS.QUATERNARY
        ? css`
            outline: none;
          `
        : ''};
  }
`;

const focusRingOffset = `${FOCUS_RING_WIDTH * 3}rem`;

export const BaseFontStyles = css`
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 2.4rem;
`;

export const LoadingAnimation = styled(LinearProgress)`
  position: absolute;
  left: -${borderWidth}rem;
  top: -${borderWidth}rem;
  width: calc(100% + ${borderWidth * 2}rem);
  height: calc(100% + ${borderWidth * 2}rem);

  border-radius: ${BORDER_RADIUS.BUTTON}; // firefox needs this
`;

export const ContentContainer = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const ChildrenContainer = styled.span`
  &.iconOnly {
    ${iconOnlyLabelStyles}
  }
`;

const SizeModifier = ({ isSmall }: IButton): StyledCSS<IButton> => css`
  padding: ${isSmall
    ? `${PADDING_SIZE.SMALL.VERT}rem ${PADDING_SIZE.SMALL.HORZ}rem`
    : `${PADDING_SIZE.DEFAULT.VERT}rem ${PADDING_SIZE.DEFAULT.HORZ}rem`};
`;

const WidthModifier = ({ width }: IButton): MediaMapResult =>
  mediaMap(width, (result: any) => {
    switch (result) {
      case 'full':
        return css`
          min-width: 100%;
        `;
      case 'half':
        return css`
          min-width: calc(50% - 0.8rem);
        `;
      case 'min':
        return css`
          min-width: 12rem;
        `;
      case 'default':
      default:
        return css`
          min-width: 0;
        `;
    }
  });

const PrimaryStyles = ({ disabled, isLightColor, isLoading, selected, iconOnly }: IButton): StyledCSS<IButton> => css`
  background-color: ${({ theme }) =>
    isLoading ? theme?.button?.primary.loading.backgroundColor : theme?.button?.primary.backgroundColor};
  border-color: ${({ theme }) => theme?.button?.primary.borderColor};
  color: ${({ theme }) => theme?.button?.primary.color};
  transition:
    0.2s background-color ease,
    0.2s border-color ease;
  border-radius: ${BORDER_RADIUS.BUTTON};

  ${isLoading &&
  css`
    cursor: not-allowed;
  `};

  ${!isLoading &&
  css`
    &:hover {
      background-color: ${({ theme }) => theme?.button?.primary.hover.backgroundColor};
      border-color: ${({ theme }) => theme?.button?.primary.hover.borderColor};
      color: ${isLightColor ? COLOURS.WHITE : ({ theme }) => theme?.button?.primary.hover.color};
    }

    &:active {
      background-color: ${({ theme }) => theme?.button?.primary.active.backgroundColor};
      border-color: ${({ theme }) => theme?.button?.primary.active.borderColor};
    }
  `};

  ${SizeModifier};
  ${WidthModifier};
  ${disabled &&
  css`
    &,
    &:hover {
      background-color: ${({ theme }) => theme?.button?.primary.disabled.backgroundColor};
      border-color: ${({ theme }) => theme?.button?.primary.disabled.borderColor};
      ${!isLoading &&
      css`
        color: ${({ theme }) => theme?.button?.primary.disabled.color};
      `}
    }
  `};

  ${selected &&
  css`
    cursor: default;
    background: ${({ theme }) => theme?.button?.primary.selected.background};
    border: ${({ theme }) => theme?.button?.primary.selected.border};

    &:hover {
      background-color: ${({ theme }) => theme?.button?.primary.selected.hover.backgroundColor};
      border: ${({ theme }) => theme?.button?.primary.selected.hover.border};
    }
    &:active {
      background-color: ${({ theme }) => theme?.button?.primary.selected.active.backgroundColor};
      border: ${({ theme }) => theme?.button?.primary.selected.active.border};
    }
  `}

  ${iconOnly &&
  css`
    ${iconOnlyButtonStyles}
  `}
`;

const SecondaryStyles = ({
  disabled,
  isLightColor,
  selected,
  isLoading,
  currentSelected
}: IButton): StyledCSS<IButton> => css`
  transition:
    0.2s background-color ease,
    0.2s border-color ease;
  background-color: ${({ theme }) =>
    isLoading ? theme?.button?.secondary.loading.backgroundColor : theme?.button?.secondary.backgroundColor};
  border-color: ${({ theme }) => theme?.button?.secondary.borderColor};
  color: ${isLightColor ? COLOURS.WHITE : ({ theme }) => theme?.button?.secondary.color};

  border-radius: 4rem;

  &:hover {
    background-color: ${({ theme }) => theme?.button?.secondary.hover.backgroundColor};
    border-color: ${({ theme }) => theme?.button?.secondary.hover.borderColor};
    color: ${isLightColor ? COLOURS.WHITE : ({ theme }) => theme?.button?.secondary.hover.color};
  }

  &:active {
    background-color: ${({ theme }) => theme?.button?.secondary.active.backgroundColor};
    border-color: ${({ theme }) => theme?.button?.secondary.active.borderColor};
    color: ${isLightColor ? COLOURS.WHITE : ({ theme }) => theme?.button?.secondary.active.color};
  }

  ${SizeModifier};
  ${WidthModifier};
  ${disabled &&
  css`
    &,
    &:hover {
      background-color: ${({ theme }) => theme?.button?.secondary.disabled.backgroundColor};
      border-color: ${({ theme }) =>
        isLoading ? theme?.button?.secondary.loading.borderColor : theme?.button?.secondary.disabled.borderColor};
      color: ${({ theme }) =>
        isLoading ? theme?.button?.secondary.loading.color : theme?.button?.secondary.disabled.color};
    }
  `};

  ${selected &&
  css`
    cursor: default;
    background: ${({ theme }) => theme?.button?.secondary.selected.background};
    border: ${({ theme }) => theme?.button?.secondary.selected.border};

    &:hover {
      background-color: ${({ theme }) => theme?.button?.secondary.selected.hover.backgroundColor};
      border: ${({ theme }) => theme?.button?.secondary.selected.hover.border};
    }
    &:active {
      background-color: ${({ theme }) => theme?.button?.secondary.selected.active.backgroundColor};
      border: ${({ theme }) => theme?.button?.secondary.selected.active.border};
    }
  `}

  ${currentSelected &&
  css`
    cursor: default;
    background: ${({ theme }) => theme?.button?.secondary.currentSelected.background};
    border: ${({ theme }) => theme?.button?.secondary.currentSelected.border};

    &:hover {
      background: ${({ theme }) => theme?.button?.secondary.currentSelected.hover.background};
      border: ${({ theme }) => theme?.button?.secondary.currentSelected.hover.border};
    }
    &:active {
      background: ${({ theme }) => theme?.button?.secondary.currentSelected.active.background};
      border: ${({ theme }) => theme?.button?.secondary.currentSelected.active.border};
    }
  `}
`;

const TertiaryStyles = ({ disabled, isLightColor }: IButton): StyledCSS<IButton> => css`
  padding: 1.2rem 0;
  border: none;

  span:not(:last-child) {
    background-image: linear-gradient(
      to top,
      ${({ theme }) => theme?.button?.tertiary.gradientColor} 0.2rem,
      transparent 0.21rem
    );

    color: ${isLightColor ? COLOURS.WHITE : ({ theme }) => theme?.button?.tertiary.color};
    background-repeat: no-repeat;
    background-size: 100% 2px;
    background-position: bottom left;
    display: inline;
    padding: 0.5rem 0;
    line-height: 3rem;
  }

  &:hover {
    > span:not(:last-child) {
      color: ${isLightColor ? COLOURS.BELONG_BLUE : ({ theme }) => theme?.button?.tertiary.hover.color};
      background-image: linear-gradient(
        to top,
        ${isLightColor ? COLOURS.BELONG_BLUE : ({ theme }) => theme?.button?.tertiary.hover.gradientColor} 0.2rem,
        transparent 0.21rem
      );
    }
  }

  &:active {
    > span:not(:last-child) {
      color: ${isLightColor ? COLOURS.WHITE : ({ theme }) => theme?.button?.tertiary.hover.color};
      background-image: linear-gradient(
        to top,
        ${isLightColor ? COLOURS.BELONG_BLUE : ({ theme }) => theme?.button?.tertiary.hover.gradientColor} 0.2rem,
        transparent 0.21rem
      );
    }
  }

  &:focus {
    > span:not(:last-child) {
      color: ${isLightColor ? COLOURS.WHITE : ({ theme }) => theme?.button?.tertiary.focus.color};
      background-image: linear-gradient(
        to top,
        ${isLightColor ? COLOURS.BELONG_BLUE : ({ theme }) => theme?.button?.tertiary.focus.gradientColor} 0.2rem,
        transparent 0.21rem
      );
    }
  }

  ${disabled &&
  css`
    &,
    &:hover {
      > span:not(:last-child) {
        background-image: linear-gradient(
          to top,
          ${({ theme }) => theme?.button?.tertiary.disabled.gradientColor} 0.2rem,
          transparent 0.21rem
        );
        color: ${({ theme }) => theme?.button?.tertiary.disabled.color};
      }
    }
  `}
`;

const QuaternaryStyles = ({ disabled, isLightColor, isSmallFontSize }: IButton): StyledCSS<IButton> => css`
  padding: 0;
  border: none;
  text-transform: none;
  text-decoration: underline;
  color: ${isLightColor ? COLOURS.BELONG_BLUE_LIGHT : ({ theme }) => theme?.button?.quaternary.color};
  font-size: inherit;
  letter-spacing: 0;
  text-align: inherit;
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};

  ${isSmallFontSize &&
  css`
    font-size: 1.5rem;
    ${media('md')`
      font-size: 1.7rem;
    `}
  `}

  :hover {
    text-decoration: none;
  }

  :hover {
    font-weight: ${isLightColor ? FONT_WEIGHT.BOLD : FONT_WEIGHT.SEMI_BOLD};
  }

  :focus {
    color: ${isLightColor ? COLOURS.WHITE : ({ theme }) => theme?.button?.quaternary?.focus?.color};
    text-decoration: ${isLightColor ? 'none' : 'underline'};
  }

  ${disabled &&
  css`
    &,
    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme?.button?.quaternary.disabled.color};
    }
  `}

  && > * {
    color: inherit;
  }
`;

export const getVariantStyles = (props: IButton): StyledCSS<any> => {
  switch (props.variant) {
    case 'secondary':
      return SecondaryStyles(props);
    case 'tertiary':
      return TertiaryStyles(props);
    case 'quaternary':
      return QuaternaryStyles(props);
    case 'primary':
    default:
      return PrimaryStyles(props);
  }
};

export const StyledIcon = styled(Icon)<{ variant?: ButtonVariantValue; hasIconLeft?: boolean; iconOnly?: boolean }>`
  ${({ hasIconLeft }) => css`
    margin-right: ${hasIconLeft ? '0.8rem' : '0'};
    margin-left: ${hasIconLeft ? '0' : '0.8rem'};
  `}

  margin-bottom: 0.2rem;
  vertical-align: middle;
  width: 1.2em; /* icon size is relative to font-size of button */
  height: 1.2em;

  ${({ variant }) =>
    variant === BUTTON_VARIANTS.PRIMARY &&
    css`
      margin-bottom: 0;
    `};

  ${({ iconOnly }) =>
    iconOnly &&
    css`
      ${iconOnlyIconStyles}
    `}
`;

export const SelectedIcon = styled(Icon)`
  margin-left: 0.8rem;
  margin-bottom: 0.8rem;
  vertical-align: middle;
  width: 1.2em; /* icon size is relative to font-size of button */
  height: 1.2em;
`;

const Button = styled(ButtonUnstyled)<IButton>`
  ${BaseStyles};
  ${BaseFontStyles};

  ${getVariantStyles};

  ${({ isLoading }) =>
    isLoading &&
    css`
      overflow-x: hidden;
    `};
`;

export const FocusRing = styled.span<Partial<IButton>>`
  display: none;

  ${Button}:focus && {
    position: absolute;
    display: block;
    width: calc(100% + (${focusRingOffset} * 2));
    height: calc(100% + (${focusRingOffset}) * 2);
    top: -${focusRingOffset};
    left: -${focusRingOffset};
    border: 0.2rem solid ${({ theme }) => theme.focusRingColor};
    border-radius: ${BORDER_RADIUS.BUTTON};

    // tertiary / quat buttons have no natural border so we have to make one
    ${({ variant }) =>
      [BUTTON_VARIANTS.TERTIARY, BUTTON_VARIANTS.QUATERNARY].includes(
        variant as ObjectValues<typeof BUTTON_VARIANTS>
      ) &&
      css`
        width: calc(100% + (${focusRingOffset} * 5));
        left: calc(-${focusRingOffset} * 2.5);
        height: calc(100%);
        top: 0;
      `};

    // quat button focus ring must be bigger than the text itself
    ${({ variant }) =>
      variant === BUTTON_VARIANTS.QUATERNARY &&
      css`
        padding-top: ${focusRingOffset};
        padding-bottom: ${focusRingOffset};
        box-sizing: content-box; // to ensure the padding is added to the height
        top: calc(-${focusRingOffset} * 1.5); // visually adjust upwards
        width: calc(100% + (${focusRingOffset} * 4));
      `};
  }
`;
FocusRing.displayName = 'FocusRing';

export default Button;
