import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { IRouteDefinition } from '@belong/types';
import { logClientRouteChange, logError } from './new-relic';
import { logger } from './logger';

const logUncaughtClientErrors = (event: ErrorEvent | PromiseRejectionEvent) => {
  const err = (event as ErrorEvent).error ?? (event as PromiseRejectionEvent).reason;
  logError(err);
  logger.error(err);
};

interface IGlobalErrorHandlerProps {
  appRoutes: IRouteDefinition[];
}

export const GlobalErrorHandler: React.FC<IGlobalErrorHandlerProps> = ({ appRoutes }) => {
  const router = useRouter();
  const routeChangeLogger = (nextPage: string) => logClientRouteChange(nextPage, appRoutes);

  useEffect(() => {
    window.addEventListener('error', logUncaughtClientErrors);
    window.addEventListener('unhandledrejection', logUncaughtClientErrors);
    router.events.on('routeChangeStart', routeChangeLogger);

    return () => {
      window.removeEventListener('error', logUncaughtClientErrors);
      window.removeEventListener('unhandledrejection', logUncaughtClientErrors);
      router.events.off('routeChangeStart', routeChangeLogger);
    };
  });
  return null;
};
