import { useId, FC } from 'react';
import { ICONS } from '@belong/web-icons/dist/dynamic';
import { IIcon, IStyledIcon } from '@belong/types';
import { hasColor } from '@belong/themes';
import styled from 'styled-components';

const iconSizes = {
  xxsmall: '1.6rem',
  xsmall: '2.0rem',
  small: '2.4rem',
  smallplus: '3.2rem',
  medium: '4.8rem',
  large: '7.2rem',
  xlarge: '10.0rem'
};

export const StyledIcon = styled.span<IStyledIcon>`
  --iconSize: ${({ size }) => iconSizes[size || 'small']};

  display: inline-block;
  flex-shrink: 0;
  width: var(--iconSize);
  height: var(--iconSize);
  ${hasColor};

  svg {
    width: var(--iconSize);
    height: var(--iconSize);

    &:not([data-preserve-color='true']) path {
      fill: currentColor;
    }
  }

  &.inline-icon {
    position: relative;
    top: 0.6rem;
  }
`;

export const Icon: FC<IIcon> = ({ name, ariaLabel, ...props }: IIcon) => {
  const SvgIcon = ICONS[name];
  const uniqueId = useId();

  return (
    <StyledIcon {...props}>
      <SvgIcon {...(ariaLabel && { 'aria-label': ariaLabel })} unique={uniqueId} />
    </StyledIcon>
  );
};

Icon.displayName = 'Icon';

export default Icon;
