import React from 'react';
import { ITestable } from '@belong/types';
import { FIELDSET_VERTICAL_SPACING, FONT_COLOURS, FONT_WEIGHT, isFocused } from '@belong/themes';
import styled, { css } from 'styled-components';
/*
  Fieldset sets a spacing of 0.8rem between its children.

  However, to have an accessible inline validation,
  the Error component is expected to be mounted
  while its content is dynamically generated

  So we do not apply the margin-top on the div[aria-live]
  but on its children instead.
*/

export interface IFieldset extends ITestable {
  /**
   * If given will render an invisible but screen-reader accessible `<legend>`
   */
  legend?: string;
  children?: React.ReactNode;
  isFocusable?: boolean;
  hideLegend?: boolean;
  forwardRef?: React.MutableRefObject<HTMLFieldSetElement | null>;
}

type TLegend = Pick<IFieldset, 'hideLegend'>;

const FieldsetElement = styled.fieldset`
  border: none;

  &:focus {
    ${isFocused()};
  }

  > * + *:not([aria-live]) {
    margin-top: ${FIELDSET_VERTICAL_SPACING};
  }

  > [aria-live] > * {
    margin-top: 0.8rem;
  }
`;

/**
 * This component aims to handle vertical spacing for form item.
 *
 * See also `InputsWrapper`
 */
const Legend = styled.legend<TLegend>`
  // Hide element from UI, if hideLegend is true, but remain visible to screen-reader: https://webaim.org/techniques/css/invisiblecontent/
  // Alternatively show if hideLegend is false
  ${({ hideLegend }) =>
    hideLegend
      ? css`
          position: absolute;
          left: -10000px;
          top: auto;
          width: 1px;
          height: 1px;
          overflow: hidden;
        `
      : css`
          position: relative;
          display: block;
          font-weight: ${FONT_WEIGHT.SEMI_BOLD};
          color: ${FONT_COLOURS.STRONG};
          font-size: 2.4rem;
          line-height: 3.2rem;
          margin-bottom: 0.8rem;
        `}
`;

export const Fieldset: React.FC<IFieldset> = ({
  legend = '',
  children,
  isFocusable = false,
  hideLegend,
  forwardRef,
  ...props
}: IFieldset) => (
  <FieldsetElement {...props} ref={forwardRef} tabIndex={isFocusable ? 0 : undefined}>
    {legend && (
      <Legend hideLegend={hideLegend} aria-hidden={hideLegend}>
        {legend}
      </Legend>
    )}
    {children}
  </FieldsetElement>
);

Fieldset.displayName = 'Fieldset';
