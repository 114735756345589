import React from 'react';

/**
 * A React hook returning the isIntersecting status of an element using the Interserction observer API.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 *
 * @param ref a React ref of the element to observe
 * @param options the Intersection observer options
 *
 * @returns isIntersecting of the element being observed, or false when the browser doesn't support Intersection observer
 */
export const useIntersection = (ref: React.RefObject<Element>, options?: IntersectionObserverInit): boolean => {
  const [isIntersecting, setIsIntersecting] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!window.IntersectionObserver) {
      return;
    }

    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return isIntersecting;
};
