import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import type { IEmbeddedInformationModal, ITheme } from '@belong/types';
import { DictionaryContext } from '@belong/contentful';
import useAnalyticsEvents from '@belong/providers/analyticsEvents';
import { ANALYTICS_STRINGS } from '@belong/analytics';

import Modal, { MODAL_STATES } from '../Modal';
import { Button } from '../../actions/Button';
import { RichText } from '../../styles/Typography/RichText/RichText';

/**
 * The **EmbeddedInformationModal** is a Modal component which is accessible via a Button component.
 *
 * It can only display information and does not provide the buttons/actions of a regular modal.
 *
 * It has been designed to be *embedded* within a RichText.
 */
const EmbeddedInformationModal: React.FunctionComponent<IEmbeddedInformationModal> = ({
  link,
  modalHeading,
  modalContents,
  image,
  leftAlignText
}: IEmbeddedInformationModal) => {
  const analyticsEvents = useAnalyticsEvents();
  const dictionary = React.useContext(DictionaryContext);

  const [isOpen, setIsOpen] = React.useState(false);

  const defaultObject = image && { image };

  const onClick = () => {
    setIsOpen(!isOpen);

    analyticsEvents.send({
      category: ANALYTICS_STRINGS.CATEGORY.PAGE_ENGAGEMENT,
      action: ANALYTICS_STRINGS.ACTION.OPEN_MODAL,
      text: link.label
    });
  };

  let content;

  if (modalContents) {
    content = <RichText html={modalContents} alignment={leftAlignText ? 'left' : undefined} />;
  } else {
    content = '';
  }

  // Add a ThemeProvider to clear possible foregroundColor and backgroundColor set from feedback/Alert
  return (
    <>
      <Button type="button" {...link} onClick={onClick} />
      {isOpen && (
        <ThemeProvider
          theme={(theme: ITheme) => ({
            ...theme,
            foregroundColor: null,
            backgroundColor: null
          })}
        >
          <Modal
            isOpen={isOpen}
            ariaLabelClose="Close modal"
            onClose={() => setIsOpen(false)}
            state={MODAL_STATES.DEFAULT}
            defaultHeading={modalHeading && <RichText html={modalHeading} h3="headingMedium" dictionary={dictionary} />}
            defaultContent={content}
            defaultObject={defaultObject}
          />
        </ThemeProvider>
      )}
    </>
  );
};

EmbeddedInformationModal.displayName = 'EmbeddedInformationModal';

export default EmbeddedInformationModal;
