import { IGlobalHeaderUrls, IPageAlert, USER_TYPE, IHeaderMenu } from '@belong/types';

export interface IGlobalHeaderDesktop {
  headerMenuContent: IHeaderMenu;
  currentUrl?: string;
  isLoggedIn: boolean;
  userType?: USER_TYPE;
  pageAlert?: IPageAlert;
  hideMyAccountLinks?: boolean;
  urls: IGlobalHeaderUrls;
  onExpanded?: (bool: boolean) => void;
}

export const CONTAINERS = {
  CONTAINER_MAIN: 'content-main',
  CONTAINER_ACCOUNT: 'content-account',
  CONTAINER_SEARCH: 'content-search'
} as const;

export type THeaderDropdownWrapper = (typeof CONTAINERS)[keyof typeof CONTAINERS];
