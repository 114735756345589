import { IHeaderMenu, GlobalHeaderConfigItem } from '@belong/types';
import { FMenuItem } from './organisms/organismMenuItem.factory';
import { FNavLink } from './elements';

export const FHeaderMenu = (data: GlobalHeaderConfigItem): IHeaderMenu => {
  const {
    identifier,
    a11ySkipToContent,
    a11yLabelNavigation,
    a11yOpenNavigation,
    a11yCloseNavigation,
    belongHome,
    headerMenuItems,
    loginLabel,
    loginIcon,
    myServicesLabel,
    myProfileLabel,
    myProfileIcon,
    logoutLabel,
    logoutIcon,
    maintenanceMode
  } = data;
  return {
    identifier,
    a11ySkipToContent,
    a11yLabelNavigation,
    a11yOpenNavigation,
    a11yCloseNavigation,
    belongHome: FNavLink(belongHome),
    headerMenuItems: headerMenuItems.map(menuItem => FMenuItem(menuItem)),
    loginLabel,
    loginIcon: loginIcon.fields,
    myServicesLabel,
    myProfileLabel,
    myProfileIcon: myProfileIcon.fields,
    logoutLabel,
    logoutIcon: logoutIcon.fields,
    maintenanceMode
  };
};
