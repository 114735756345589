import styled, { css } from 'styled-components';
import { AlignX, alignXToFlex, alignXToFlexReverse, alignYToFlex, TBreakpointKeys } from '@belong/types';
import { responsiveValue } from '@belong/ui-core';
import { media, mediaMap, smallerThan } from '@belong/themes';

import { IColumnsProps, columnsDefaultProps, ReverseType } from './Columns.types';

/**
 * If the reverse is set, flex-direction is reversed and hence justify-conent vlaues
 * flex-start and flex-end have opposite meaning to what we expect, e.g.
 * `flex-direction: row-reverse; justify-content: flex-end;` means the items will
 * be right aligned instead of left-aligned. However, in our design system language
 * when `alignX="right" we want the children to be right-aligned no matter what the
 * reverse value is.
 */
const getJustifyContent = (
  breakpoint,
  reverse: ReverseType = 'never',
  alignX: AlignX = columnsDefaultProps.alignX as AlignX,
  collapseBelow: TBreakpointKeys = columnsDefaultProps.collapseBelow
): (typeof alignXToFlex)[keyof typeof alignXToFlex] => {
  if (reverse === 'always') {
    return alignXToFlexReverse[alignX];
  }
  if (reverse === 'whenCollapsed') {
    if (smallerThan(breakpoint, collapseBelow)) {
      return alignXToFlexReverse[alignX];
    }
    return alignXToFlex[alignX];
  }
  if (reverse === 'whenNotCollapsed') {
    if (smallerThan(breakpoint, collapseBelow)) {
      return alignXToFlex[alignX];
    }
    return alignXToFlexReverse[alignX];
  }
  return alignXToFlex[alignX];
};
export const ColumnsVisual = styled.div<IColumnsProps>`
  display: flex;
  flex: 0 0 100%; /* do not allow the the width to change from 100%  */

  ${p =>
    mediaMap(
      responsiveValue(p.alignX),
      (alignX, breakpoint) => css`
        justify-content: ${getJustifyContent(breakpoint, p.reverse, alignX, p.collapseBelow)};
      `
    )};

  ${p =>
    mediaMap(
      responsiveValue(p.alignY),
      alignY => css`
        align-items: ${alignYToFlex[alignY || columnsDefaultProps.alignY]};
      `
    )};

  ${p => media('xs', p.collapseBelow || columnsDefaultProps.collapseBelow)`
    flex-direction: ${p.reverse === 'always' || p.reverse === 'whenCollapsed' ? 'column-reverse' : 'column'};

    & > * {
      width: 100%;
    }
  `};
  ${p => media(p.collapseBelow || columnsDefaultProps.collapseBelow)`
    flex-direction: ${p.reverse === 'always' || p.reverse === 'whenNotCollapsed' ? 'row-reverse' : 'row'};
  `};

  ${p =>
    mediaMap(
      responsiveValue(p.space),
      space => css`
        gap: ${p.theme.spaces[space || columnsDefaultProps.space]}${p.theme.cssUnit};
      `
    )};
`;

ColumnsVisual.displayName = 'ColumnsVisual';
