import React from 'react';

import { ICON_NAMES } from '@belong/types';

import { FONT_COLOURS } from '@belong/themes';
import * as styles from './styles';

export interface IHeaderBarLink {
  'aria-current'?: any;
  'aria-label'?: string;
  'aria-selected'?: any;
  'data-testid'?: string;
  asUrl?: string;
  hideUnderline?: boolean;
  href?: string;
  iconName?: ICON_NAMES;
  iconRight?: boolean;
  rotateIconOnHover?: boolean;
  label?: string;
  isRightLink?: boolean;
  onClick?: (e: React.MouseEvent<any>) => void;
  onMouseEnter?: (e: React.MouseEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onFocus?: (e: React.FocusEvent<any>) => void;
  onKeyDown?: (e: React.KeyboardEvent<any>) => void;
  id?: string;
}

export const HeaderBarButton = React.forwardRef(
  (
    { iconName, iconRight, label, href, rotateIconOnHover, isRightLink, onKeyDown, ...props }: IHeaderBarLink,
    ref: any
  ): JSX.Element => {
    const hasColor =
      props['aria-current'] || props['aria-selected'] || props['aria-expanded']
        ? FONT_COLOURS.ACTIVE
        : FONT_COLOURS.LIGHT;

    // the withLink HOC doesn't support refs, so there are two versions of
    // the wrapper component, so that when the HeaderBarLink functions as
    // a button, the ref will work.
    if (!href) {
      return (
        <styles.Wrapper ref={ref} {...props} onKeyDown={onKeyDown} isRightLink={isRightLink}>
          <styles.Primary hasColor={hasColor} as="span">
            {iconRight ? (
              <>
                {label}
                {iconName && (
                  <styles.StyledIcon
                    name={iconName}
                    hasColor={hasColor}
                    hasPadding={!!label}
                    iconRight
                    rotateIconOnHover
                  />
                )}
              </>
            ) : (
              <>
                {iconName && <styles.StyledIcon name={iconName} hasColor={hasColor} hasPadding={!!label} />}
                {label}
              </>
            )}
          </styles.Primary>
        </styles.Wrapper>
      );
    }
    return (
      <styles.WrapperWithLink href={href} {...props}>
        <styles.Primary hasColor={hasColor}>
          {iconRight ? (
            <>
              {label}
              {iconName && (
                <styles.StyledIcon
                  name={iconName}
                  hasColor={hasColor}
                  hasPadding={!!label}
                  iconRight
                  rotateIconOnHover
                />
              )}
            </>
          ) : (
            <>
              {iconName && <styles.StyledIcon name={iconName} hasColor={hasColor} hasPadding={!!label} />}
              {label}
            </>
          )}
        </styles.Primary>
      </styles.WrapperWithLink>
    );
  }
);

HeaderBarButton.displayName = 'HeaderBarButton';

export default HeaderBarButton;
