import { COLOURS, Z_INDEX, GRADIENTS, media, isFocused } from '@belong/themes';
import styled, { css } from 'styled-components';
import { ButtonUnstyled, withLink } from '@belong/ui-components';

export const HEIGHT = 56; // px
export const HEIGHT_WITH_UNIT = '5.6rem';

export const Root = styled.div`
  height: ${HEIGHT_WITH_UNIT};

  ${media('lg')`
  display: none;
`}
`;

export const NavHeader = styled.nav<{ isHidden?: boolean }>`
  position: fixed;
  z-index: ${Z_INDEX.GLOBAL_HEADER};
  left: 0;
  top: 0;
  width: 100%;
  transition: transform 0.3s ease;

  ${({ isHidden }): any =>
    isHidden &&
    css`
      transform: translateY(-100%);
    `}
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const HeaderBarMobile = styled.div`
  background-color: ${COLOURS.BLACK};
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const WrapperModal = styled.div``;

export const MenuButton = styled(ButtonUnstyled)`
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${HEIGHT_WITH_UNIT};
  width: ${HEIGHT_WITH_UNIT};
  cursor: pointer;

  &:focus {
    ${() => isFocused(true)}
  }
`;

export const LinkLogo = withLink<{ onClick: () => void }>(
  styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${HEIGHT_WITH_UNIT};
    padding-left: 2rem;
    padding-right: 2rem;
    text-decoration: none;
    max-width: 70vw;

    &:focus {
      ${() => isFocused(true)}
    }
  ` as any
);

export const SearchButton = styled(ButtonUnstyled)`
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${HEIGHT_WITH_UNIT};
  width: ${HEIGHT_WITH_UNIT};
  cursor: pointer;

  &:focus {
    ${() => isFocused(true)}
  }
`;

export const ModalAccessibilityHeader = styled.div`
  visibility: hidden;
`;

const offCanvasCommon = css`
  position: fixed;
  z-index: ${Z_INDEX.GLOBAL_HEADER};
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${COLOURS.BLACK};
  overflow-y: auto;
  will-change: transform, visibility;
  transition:
    transform 0.3s ease,
    visibility 0.3s ease;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const OffCanvasMenu = styled.section<{
  isExpanded: boolean;
}>`
  ${offCanvasCommon};

  transform: translateX(-100%);
  padding-top: 0.5rem;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      transform: translateX(0);
      visibility: visible;
    `}
`;

export const OffCanvasSearch = styled.section<{
  isExpanded: boolean;
}>`
  ${offCanvasCommon};

  transform: translateX(100%);
  padding-top: 0.5rem;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      transform: translateX(0);
      visibility: visible;
    `}
`;

export const OffCanvasLinkPanel = styled.section<{
  isCenter: boolean;
  offCanvasLeft: boolean;
}>`
  ${offCanvasCommon};

  transform: translateX(100%);
  padding-top: 0.75rem;

  ${({ isCenter }) =>
    isCenter &&
    css`
      transform: translateX(0);
      visibility: visible;
    `}
  ${({ offCanvasLeft }) =>
    offCanvasLeft &&
    css`
      transform: translateX(-100%);
      visibility: visible;
    `}
`;

export const AccentStrip = styled.div<{ originRight?: boolean; isExpanded: boolean; ref: any }>`
  height: 0.8rem;
  min-height: 0.8rem; // prevent item collapse on small screens
  background: ${GRADIENTS.ACCENT_STRIP_GLOBAL_HEADER};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${Z_INDEX.GLOBAL_HEADER + 1};
  transform: ${({ originRight }) => `translate3d(${originRight ? '100%' : '-100%'}, 0, 0)`};
  transition: transform 0.3s ease;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      transform: translate3d(0, 0, 0);
      visibility: visible;
    `}
`;

export const StyledHR = styled.hr`
  margin: 1.6rem 3rem;
  color: ${COLOURS.GREY_500};
`;

export const StyledSearch = styled.div`
  padding: 0 2rem;
`;
