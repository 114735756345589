import React from 'react';
import { IAccordionList } from './AccordionList.types';
import * as List from './AccordionList.styles';
import { ACCORDION_LIST } from '../../../helpers/testIds';

const AccordionList: React.FC<IAccordionList> = ({ items, ...otherProps }: IAccordionList) => {
  const testId = otherProps['data-testid'] || ACCORDION_LIST;
  const itemList = items || [];
  return (
    <List.Wrapper {...otherProps} data-testid={testId}>
      {itemList.map((item, index) => {
        const itemID = item.props.id || index;
        return (
          <List.Item key={itemID} data-testid={`${testId}_${index}`}>
            {item}
          </List.Item>
        );
      })}
    </List.Wrapper>
  );
};

AccordionList.displayName = 'AccordionList';
export default AccordionList;
