export const isObject = (variable: any): boolean => {
  if (variable === null || variable === undefined) {
    return false;
  }

  if (typeof variable === 'string') {
    return false;
  }

  return typeof variable === 'object' && variable.constructor === Object;
};

export const isUndefined = (variable: any): boolean => {
  return typeof variable === typeof undefined;
};

export const isDefined = <T>(data: T | undefined): data is T => !isUndefined(data) && !isNull(data);

export const isNull = (variable: any): boolean => {
  return variable === null;
};

export const isString = (variable: any): variable is string => {
  return typeof variable === 'string';
};

export const isNumber = (variable: any): boolean => {
  return typeof variable === 'number';
};

// excludes NaN
export const isRegularNumber = (value): boolean => typeof value === 'number' && !Number.isNaN(value);

export const hasValue = (variable: any): boolean => {
  return !isUndefined(variable) && !isNull(variable);
};

export const hasKey = (variable: Record<string, any>, value: any): boolean => {
  if (!isObject(variable)) {
    return false;
  }
  return Object.keys(variable).indexOf(value) !== -1;
};
