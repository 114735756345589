import type { IAuth } from './api/auth';
import { USER_TYPE } from './user';

export enum ENVS {
  DEVELOPMENT = 'DEVELOPMENT',
  AT = 'AT',
  SIT = 'SIT',
  SVT = 'SVT',
  PREPROD = 'PREPROD',
  PREVIEW = 'PREVIEW',
  PRODUCTION = 'PRODUCTION'
}

export type BelongEnvironment = keyof typeof ENVS;

export type TAgentAuth = Omit<IAuth, 'tokenType' | 'expiresIn' | 'userType'> & { userType: USER_TYPE };
