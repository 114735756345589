import { useState } from 'react';
import { IPage } from '@belong/types';

import createProvider from '../_factory';

export interface IPageMeta extends Omit<IPage, 'page' | 'pageUrl'> {
  pageUrl: string;
}

interface IProvider {
  pageMeta: IPageMeta | null;
  setPageMeta: (newValue: IPageMeta) => void;
}

type hook = (/* this hook takes no args */) => IProvider;

const usePageMeta: hook = () => {
  const [pageMeta, setPageMeta] = useState<IPageMeta | null>(null);

  return {
    pageMeta,
    setPageMeta
  };
};

const { provider, hook } = createProvider<hook>(usePageMeta, 'usePageMeta');

export const Provider = provider;

export default hook;
