import { IAccordionRow } from '@belong/types';
import { FSystemIcon } from './elements/elementSystemIcon.factory';
import { FReadOnlyRow } from './readOnlyRow.factory';
import { FRichText } from './richText.factory';

export const FAccordionRow = (data: any): IAccordionRow => {
  const { icon, label, headingLevel, body, listItems, backgroundColour, available } = data.fields;

  return {
    identifier: data.sys.id,
    icon: icon && FSystemIcon(icon),
    label,
    headingLevel,
    body: body && FRichText(body),
    listItems: listItems && listItems.map(FReadOnlyRow),
    hasBackground: backgroundColour && backgroundColour.toLowerCase(),
    available
  };
};
