import React from 'react';

import { ISectionWrapper, OUTER_SPACING } from '@belong/types';

import { COLOURS } from '@belong/themes';
import { SectionWrapper, Anchor } from './Section.styles';
import { IContainer } from '../../layout/Container';
import { Stack } from '../../ui/Stack/Stack';
import { SECTION_WRAPPER_TEST_ID } from '../../../helpers/testIds';

import SectionContainer from './SectionContainer';

export interface ISection extends IContainer, ISectionWrapper {
  className?: string;
  children: React.ReactNode;
  'data-testid'?: string;
  'data-contentid'?: string;
  'data-identifier'?: string;
  sectionRef?: React.MutableRefObject<HTMLDivElement | null>;
  hasContainer?: boolean;
  hasMargin?: boolean;
  [key: string]: unknown;
}

const backgroundMap: { [k: string]: string } = {
  transparent: 'transparent',
  white: COLOURS.WHITE,
  grey: COLOURS.GREY_100,
  darkGrey: COLOURS.GREY_200,
  black: COLOURS.BLACK
};

const Section: React.FC<ISection> = ({
  hasBackground,
  hasPaddingTop = false,
  hasPaddingBottom = false,
  hasMarginTop = false,
  hasContainer = true,
  isPadded,
  isFullWidth,
  id,
  className,
  children,
  containsCard,
  sectionRef,
  paddingSize = OUTER_SPACING.LOOSE,
  ...others
}: ISection) => {
  const wrapperProps = {
    className,
    hasPaddingTop,
    hasPaddingBottom,
    hasBackground: hasBackground ? backgroundMap[hasBackground] : 'default',
    hasMarginTop,
    paddingSize,
    'data-state': others['data-state']
  };

  return (
    <SectionWrapper
      {...wrapperProps}
      containsCard={containsCard}
      data-testid={others['data-testid'] || SECTION_WRAPPER_TEST_ID.ROOT}
      data-identifier={others['data-identifier']}
      data-contentid={others['data-contentid']}
      ref={sectionRef}
      {...others}
    >
      {id && <Anchor id={id} />}
      {/* todo: Section should not wrap its children in Stack. It should be done by the consumer of this component */}
      <SectionContainer hasContainer={hasContainer} isPadded={isPadded} isFullWidth={isFullWidth}>
        <Stack data-testid={SECTION_WRAPPER_TEST_ID.INNER_SECTION}>{children}</Stack>
      </SectionContainer>
    </SectionWrapper>
  );
};

Section.displayName = 'Section';
export default Section;
