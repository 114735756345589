import { logger } from '@belong/logging';

import { fetchToggles } from '../api';
import { IFeatureToggles } from '../types';
import { applyOverrides } from '../utils';

export const getFeatureToggles = async (
  url: string,
  cookie = '',
  isBlue = false,
  correlationId = ''
): Promise<IFeatureToggles> => {
  if (!url) {
    logger.warn(correlationId, `no feature toggles url provided: ${url}`);
    return applyOverrides(correlationId, {}, cookie);
  }

  try {
    const toggles = await fetchToggles(url, isBlue);
    return applyOverrides(correlationId, toggles, cookie);
  } catch (e) {
    logger.error(correlationId, 'Unable to load external feature toggles', e.message);
    return applyOverrides(correlationId, {}, cookie);
  }
};
