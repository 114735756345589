import { ILayoutControl } from '@belong/types';
import { applyFlexLayout } from '@belong/themes';
import styled from 'styled-components';

const ButtonGroup = styled.div<ILayoutControl>`
  ${applyFlexLayout};
`;

ButtonGroup.displayName = 'ButtonGroup';
export default ButtonGroup;
