import React from 'react';
import kebabCase from 'lodash/kebabCase';
import { IMoleculeNavLinkGroup } from '@belong/types';
import DropdownLink from '../DropdownLink';
import * as styles from './styles';
import { ARIA_CURRENT } from '../../../variables';
import { GLOBAL_HEADER_TEST_ID as TEST_ID } from '../../../testids';

export interface IDropdownLinkGroup {
  navLinkGroup: IMoleculeNavLinkGroup;
  currentUrl?: string;
  onLinkClick?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<any>) => void;
  onBlur?: () => void;
}

const DropdownLinkGroup = ({
  navLinkGroup,
  currentUrl,
  onLinkClick,
  onKeyDown,
  onBlur
}: IDropdownLinkGroup): JSX.Element => {
  return (
    <nav aria-label={navLinkGroup.label}>
      <styles.MenuList>
        {navLinkGroup.navLinks.map((navLink, idx) => (
          <li key={kebabCase(navLink.label).toLowerCase()}>
            <DropdownLink
              aria-current={currentUrl === navLink.href ? ARIA_CURRENT : undefined}
              data-testid={`${TEST_ID.DROPDOWN_BUTTON_PREFIX}${kebabCase(navLink.label).toLowerCase()}`}
              aria-label={navLink['aria-label']}
              href={navLink.href}
              iconName={navLink.navLinkIcon}
              label={navLink.label}
              onClick={onLinkClick}
              onKeyDown={onKeyDown}
              onBlur={navLinkGroup.navLinks.length - 1 === idx ? onBlur : undefined}
            />
          </li>
        ))}
      </styles.MenuList>
    </nav>
  );
};
DropdownLinkGroup.displayName = 'DropdownLinkGroup';
export default DropdownLinkGroup;
