import React from 'react';
import { IMoleculeNavLinkGroup } from '@belong/types';
import kebabCase from 'lodash/kebabCase';
import useRuntime from '@belong/providers/runtime';
import * as GFooter from './GlobalFooter.styles';
import NavLinkFooter from '../../actions/NavLinkFooter';

interface IGlobalFooterNavLinkGroup {
  navLinkGroup: IMoleculeNavLinkGroup;
}

const GlobalFooterNavLinkGroup = ({ navLinkGroup }: IGlobalFooterNavLinkGroup): JSX.Element => {
  const { currentBreakpoint } = useRuntime();

  return (
    <nav aria-label={navLinkGroup.label}>
      <GFooter.NavLinkGroupHeading variant={currentBreakpoint === 'xs' ? 'small' : 'xSmall'}>
        {navLinkGroup.label}
      </GFooter.NavLinkGroupHeading>
      <GFooter.NavLinkGroupLinks>
        {navLinkGroup.navLinks.map(navLink => (
          <li key={kebabCase(navLink.label).toLowerCase()}>
            <NavLinkFooter aria-label={navLink['aria-label']} href={navLink.href} label={navLink.label} />
          </li>
        ))}
      </GFooter.NavLinkGroupLinks>
    </nav>
  );
};
GlobalFooterNavLinkGroup.displayName = 'GlobalFooterNavLinkGroup';
export default GlobalFooterNavLinkGroup;
