import fetch from 'isomorphic-unfetch';

import { logger } from '@belong/logging';

import { CONSUMER_TAG } from '../constants';
import type { IFeatureToggles, IFeatureToggleSource } from '../types';

/**
 * Loads and parses the appropriate toggles for the current environment.
 * If running in a BLUE environment, grab the BLUE toggle state and fallback
 * to GREEN toggle state if not defined
 */

export const fetchToggles = async (url: string, isBlue = false): Promise<IFeatureToggles> => {
  const res = await fetch(url);

  if (!res.ok) {
    logger.error(`Fetching feature toggles from URL ${url} returned statusCode ${res.status}`);
    return {};
  }

  const toggles: IFeatureToggleSource[] = await res.json();

  return (toggles || [])
    .filter(toggle => toggle.tags.includes(CONSUMER_TAG))
    .reduce(
      (acc, { id, active, activeBlue }) => ({
        ...acc,
        [id]: isBlue ? activeBlue ?? active : active
      }),
      {}
    );
};
