import { isBrowser } from '../device';

export const getRawStringValueFromSession = (sessionKey: string): string | null => {
  if (!isBrowser()) {
    return null;
  }
  try {
    const value = window.sessionStorage.getItem(sessionKey);
    return value;
  } catch (e) {
    // Swallow
  }
  return null;
};

export function getValuesFromSessionStorage<TValues>(key: string): TValues | null {
  if (!isBrowser()) {
    return null;
  }
  try {
    const value = window.sessionStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
  } catch (err) {
    // Swallow
  }
  return null;
}

export function setValuesToSessionStorage<TValues>(key: string, values: TValues): void {
  if (!isBrowser()) {
    return;
  }
  try {
    const value = JSON.stringify(values);
    window.sessionStorage.setItem(key, value);
  } catch (err) {
    // Swallow
  }
}

export function clearKeyFromSessionStorage(key: string): void {
  if (!isBrowser()) {
    return;
  }
  try {
    window.sessionStorage.removeItem(key);
  } catch (err) {
    // Swallow
  }
}
