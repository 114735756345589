import { COLOURS, media } from '@belong/themes';
import styled from 'styled-components';
import { Columns } from '../../ui/Columns/Columns';
import { Heading } from '../../styles/Typography/Heading';

export const Footer = styled.footer`
  background-color: ${COLOURS.BLACK};
  padding: 5.6rem 0rem;
  margin-top: auto;
`;

export const FooterMenuSocialLinksWrapper = styled.ul`
  display: flex;
  justify-content: flex-start;
  border-top: 0.1rem solid ${COLOURS.GREY_500};
  padding-top: 1.6rem;
  margin-top: 3.2rem;

  flex-wrap: wrap;
  gap: 0 0.8rem;
  ${media('lg')`
    flex-wrap: nowrap;
  `}

  ${media('lg')`
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
  `}
  
  li {
    list-style: none;
    display: inline-block;
  }
`;

export const SocialLinksWrapper = styled.ul`
  display: flex;
  justify-content: flex-start;
  border-top: 0.1rem solid ${COLOURS.GREY_500};
  padding-top: 2.4rem;
  margin-top: 2.4rem;

  flex-wrap: wrap;
  gap: 0 2rem;
  ${media('md')`
    flex-wrap: nowrap;
    gap: 0;
  `}

  ${media('lg')`
    justify-content: flex-end;
    border-top: 0;
    padding-top: 0;
    margin-top: -1.2rem;
  `}

  li {
    list-style: none;
    display: inline-block;
  }
`;

export const InfoWrapper = styled.div`
  border-top: 0.1rem solid ${COLOURS.GREY_500};
  padding-top: 2.4rem;
  margin: 2.4rem 0;

  ul {
    width: 100%;
    margin: 0;
  }

  li {
    display: inline-block;
    list-style: none;
    width: 100%;
    margin-top: 1.6rem;

    ${media('md')`
      width: initial;
      margin-right: 4rem;
      margin-top: 3.2rem;
    `}
  }
`;

export const NavLinkGroupHeading = styled(Heading).attrs({ as: 'h3' })`
  color: ${COLOURS.WHITE};
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  margin-bottom: 1.6rem;
`;

export const NavLinkGroupLinks = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const CompanyInformationColumns = styled(Columns)`
  border-top: 0.1rem solid ${COLOURS.GREY_500};
  padding-top: 3.2rem;
  margin-top: -1rem;

  ${media('lg')`
    margin-top: unset;
  `}
`;
