import styled from 'styled-components';

import { getPaddingTop } from '@belong/themes';

export const Outer = styled.span`
  /**
   * any parents using 'display: flex' will give this element zero width. 
   * This will keep it running smoothly
   */
  width: 100%;
  position: relative;
  aspect-ratio: 16 / 9;
  display: block;

  @supports not (aspect-ratio: 16 / 9) {
    &::before {
      display: block;
      content: '';
      width: 100%;
      padding-top: ${(): string => getPaddingTop('16:9')}; /* Aspect ratio 16:9 */
    }
  }
`;

export const Inner = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  iframe {
    width: 100%;
    height: 100%;
  }
`;
