import { COLOURS } from '@belong/themes';
import styled from 'styled-components';

export const StickyAccountButton = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${COLOURS.BLACK};
  width: 100%;
  margin-top: auto;
  padding-bottom: 1.6rem;
`;
