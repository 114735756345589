import { ResponsiveProp, TBreakpointKeys } from '@belong/types';
import { isValidElement, ReactNode } from 'react';
import { getResponsiveFullObject, Hidden, resolveResponsiveRange } from '@belong/ui-core';

export const breakpointKeys: TBreakpointKeys[] = ['xs', 'sm', 'md', 'lg', 'xl'];

export const alignXToDisplay = {
  left: 'block',
  center: 'flex',
  right: 'flex'
};

/**
 * If the child is an instance of Hidden, or is a null rendering child this function
 * returns true. Otherwise, it returns false.
 * Since, being hidden is responsive, this function returns a responsiveProp<boolean>,
 * e.g. {xs: true, sm: true, md: false, lg: false, xl: false}
 */
export const isChildHidden = (child: ReactNode): ResponsiveProp<boolean> => {
  // If the child is not valid, null | undefined, or not a string or number, we treat it as hidden and return true.
  if (!isValidElement<any>(child) && typeof child !== 'string' && typeof child !== 'number') {
    return breakpointKeys.reduce((acc, bt) => ({ ...acc, [bt]: true }), {});
  }

  // If the child is a string or number or has no props, and is not null, and is not
  // an instance of Hidden, then it is not hidden, hence we return false.
  if (typeof child === 'string' || typeof child === 'number' || child?.type !== Hidden || !child?.props) {
    return breakpointKeys.reduce((acc, breakpoint) => ({ ...acc, [breakpoint]: false }), {});
  }

  const [atAndAbove, below] = [child.props.atAndAbove, child.props.below];
  return resolveResponsiveRange({ atAndAbove, below });
};

const isKeyInAny = (obj1, obj2, key): boolean => {
  return key in obj1 || key in obj2;
};
const containsBreakpoint = <K extends TBreakpointKeys>(obj: ResponsiveProp<any>, key: K): boolean =>
  typeof obj === 'object' && Object.prototype.hasOwnProperty.call(obj, key);

/**
 * This method is meant to be used when we want to pass 2 ResponsiveProps to
 * mediaMap. For example in Stack we need to decide on the value of the CSS rule
 * 'display' based on both values of isHidden: ResponsiveProp<boolean> and alignX: ResponsiveProp<AlignX>.
 * To achieve it, this method merges them into one ResponsiveProp where the values
 * of each provided breakpoint are merged in as an array:
 * obj1 = {xs: true, md: false}; obj2 = {xs: 'center', lg: 'left'};
 * will result in:
 * res = {xs: [true, 'center], md: [false, undefined], lg: [undefined, 'left']};
 * @param obj1
 * @param obj2
 */
export const unioniseResponsiveProps = <P1, P2>(
  object1?: Partial<Record<TBreakpointKeys, P1>> | null,
  object2?: Partial<Record<TBreakpointKeys, P2>> | null
): Partial<Record<TBreakpointKeys, [P1, P2]>> => {
  const obj1 = getResponsiveFullObject<P1>(object1);
  const obj2 = getResponsiveFullObject<P2>(object2);
  const availableBreakpoints = breakpointKeys.filter(
    breakpoint => containsBreakpoint(obj1, breakpoint) || containsBreakpoint(obj2, breakpoint)
  );
  return availableBreakpoints.reduce(
    (res, key) =>
      isKeyInAny(obj1, obj2, key)
        ? {
            ...res,
            [key]: [obj1?.[key], obj2?.[key]]
          }
        : res,
    {} as Partial<Record<TBreakpointKeys, [P1, P2]>>
  );
};
