import styled from 'styled-components';
import { COLOURS } from '@belong/themes';

export interface IDividerProps {
  noMargin?: boolean;
  noMarginTop?: boolean;
  noMarginBottom?: boolean;
}

export const Divider = styled.hr<IDividerProps>`
  border: none;
  border-top: 0.1rem solid ${COLOURS.GREY_300};
  margin-top: ${p => (p.noMarginTop || p.noMargin ? '0' : '1em')};
  margin-bottom: ${p => (p.noMarginBottom || p.noMargin ? '0' : '1em')};
  margin-inline: 0;
`;

Divider.displayName = 'Divider';
