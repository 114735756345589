import Router from 'next/router';
import fetch from 'isomorphic-unfetch';
import merge from 'lodash/merge';

import { HEADERS } from '@belong/constants';
import { logger } from '@belong/logging/logger';
import type { IGenericFetchResult } from '@belong/types';
import { clearAuthCookie, clearMfaEnrolCookie } from '@belong/cookies';
import { getRootRelativeUrl, isBlueHost, isBrowser, redirectInProgress } from '@belong/utils';

import { IAppConfig, IGenericFetch } from './types';
import { getSessionInfo, isUnauthorisedStatus, DEFAULT_ERROR } from './serviceUtils';
import { initAnalytic } from './analyticEvent';

const isHTTPResponseNotOkay = (response: Response): boolean => !response.ok;

const parseResponse = async <T = any>(apiResponse: Response): Promise<T> => {
  try {
    return apiResponse.json();
  } catch (error) {
    throw new Error(`failed to parse API response as json\n${error}`);
  }
};

/**
 * Fetch from Belong API
 *
 * Adds headers, redirects url, etc.
 */
export const genericFetch = async <T>({
  endpoint,
  headers,
  method = 'GET',
  signal,
  body,
  config,
  includeCredentials = true,
  includeIdToken = false,
  trackedByAnalytics = false,
  ...otherOptions
}: IGenericFetch & IAppConfig): Promise<IGenericFetchResult<T>> => {
  // initialise an analytics service singleton
  const analyticService = initAnalytic();

  const { host, hostBlue, consumerId, basePath } = config;
  const { accessToken = '', correlationId = '', idToken = '' } = getSessionInfo(headers);

  const options: Omit<IGenericFetch, 'endpoint'> = {
    headers: merge(
      {
        [HEADERS.ACCEPT]: 'application/json',
        [HEADERS.CONTENT_TYPE]: 'application/json',
        [HEADERS.CONSUMER_ID]: consumerId,
        [HEADERS.CORRELATION_ID]: correlationId,
        ...(includeCredentials && { [HEADERS.ACCESS_TOKEN]: accessToken }),
        ...(includeIdToken && { [HEADERS.ID_TOKEN]: idToken }),
        // ensure API requests are not cached (similar headers exist in response)
        [HEADERS.CACHE_CONTROL]: 'no-store',
        [HEADERS.PRAGMA]: 'no-cache'
      },
      headers
    ),
    method,
    signal,
    body: typeof body !== 'string' ? JSON.stringify(body) : body,
    ...otherOptions
  };

  if (trackedByAnalytics) {
    analyticService.trackAPI(endpoint, 'BEFORE', {});
  }

  const apiHost = isBlueHost() ? hostBlue : host;
  const url = apiHost + endpoint;

  let response;
  try {
    response = await fetch(url, options as any);
    // redirect on client-initiated requests that return 401
    if (isBrowser() && isUnauthorisedStatus(response.status)) {
      const logPrefix = `${getRootRelativeUrl(response.url)} ${correlationId}`;
      logger.error(logPrefix, 'Unauthenticated API response');
      const currentUrl = `${basePath ?? ''}${Router.asPath}`;
      if (!redirectInProgress(currentUrl)) {
        clearAuthCookie();
        clearMfaEnrolCookie();
        const reason = accessToken ? '&reason=session_expired' : '';
        const location = `/login?redirect=${currentUrl}${reason}`;
        // Location change is necessary to ensure a fresh page load / wipe memory AND cookie
        window.location.assign(basePath === 'nbn-agent' ? '/partner' : location);
      }

      return {
        status: response.status,
        headers: response.headers,
        body: DEFAULT_ERROR
      };
    }

    if (options.headers && options.headers[HEADERS.ACCEPT] === 'application/pdf') {
      // For PDFs return blob as the body
      return {
        status: response.status,
        headers: response.headers,
        body: await response.blob()
      };
    }

    let responseBody;

    try {
      responseBody = await parseResponse(response);
    } catch (e) {
      if (response.status === 204 || response.status === 202) {
        logger.debug('Returning empty payload for 204 response');
        responseBody = {};
      } else {
        responseBody = DEFAULT_ERROR;
      }
    }

    try {
      if (isHTTPResponseNotOkay(response)) {
        const isEmptyObject = Object.keys(responseBody).length === 0;
        // handles empty or non-standardised error response body
        if (isEmptyObject || !responseBody.errorCode) {
          responseBody = {
            ...DEFAULT_ERROR,
            status: response.status.toString()
          };
        }
      }
    } catch (e) {
      responseBody = DEFAULT_ERROR;
    }

    return {
      status: response.status,
      headers: response.headers,
      body: responseBody
    };
  } catch (err) {
    logger.error('API request failed', { url, method, error: err });

    return {
      // 424: Failed Dependency
      status: 424,
      body: {
        status: '424',
        errorCode: DEFAULT_ERROR.errorCode,
        errorDescription: err.message
      }
    };
  } finally {
    if (trackedByAnalytics) {
      analyticService.trackAPI(endpoint, 'AFTER', { ok: Boolean(response?.ok) });
    }
  }
};
