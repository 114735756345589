export enum GLOBAL_HEADER_TEST_ID {
  AGENT_HEADER = 'agent-header',
  AGENT_BUTTON_LOGOUT = 'agent-logout-button',
  //
  GLOBAL_HEADER_BUTTON_PREFIX = 'global-header-button-',
  GLOBAL_HEADER_BUTTON_INTERNET_PLANS = `${GLOBAL_HEADER_BUTTON_PREFIX}internet-plans`,
  GLOBAL_HEADER_BUTTON_SIM_PLANS = `${GLOBAL_HEADER_BUTTON_PREFIX}sim-plans`,
  BUTTON_ABOUT_BELONG = `${GLOBAL_HEADER_BUTTON_PREFIX}about-belong`,
  BUTTON_BLOG = `${GLOBAL_HEADER_BUTTON_PREFIX}blog`,
  BUTTON_COVERAGE_MAP = `${GLOBAL_HEADER_BUTTON_PREFIX}coverage-map`,
  BUTTON_DATA_PLANS = `${GLOBAL_HEADER_BUTTON_PREFIX}data-plans`,
  BUTTON_DROPDOWN_MAIN = `${GLOBAL_HEADER_BUTTON_PREFIX}dropdown-main`,
  BUTTON_DROPDOWN_MY_ACCOUNT = `${GLOBAL_HEADER_BUTTON_PREFIX}dropdown-profile`,
  BUTTON_HOME = `${GLOBAL_HEADER_BUTTON_PREFIX}home`,
  BUTTON_LOGIN = `${GLOBAL_HEADER_BUTTON_PREFIX}login`,
  BUTTON_LOGOUT = `${GLOBAL_HEADER_BUTTON_PREFIX}logout`,
  BUTTON_MOBILE_PLANS = `${GLOBAL_HEADER_BUTTON_PREFIX}mobile-plans`,
  BUTTON_MY_PROFILE = `${GLOBAL_HEADER_BUTTON_PREFIX}my-profile`,
  BUTTON_MY_SERVICES = `${GLOBAL_HEADER_BUTTON_PREFIX}my-services`,
  BUTTON_NBN_PLANS = `${GLOBAL_HEADER_BUTTON_PREFIX}nbn-plans`,
  BUTTON_INTERNATIONAL_ROAMING = `${GLOBAL_HEADER_BUTTON_PREFIX}international-roaming`,
  BUTTON_OUR_MOBILE_NETWORK = `${GLOBAL_HEADER_BUTTON_PREFIX}our-mobile-network`,
  BUTTON_SEARCH = `${GLOBAL_HEADER_BUTTON_PREFIX}search`,
  BUTTON_SECOND_LIFE_DEVICES = `${GLOBAL_HEADER_BUTTON_PREFIX}second-life-devices`,
  BUTTON_SUPPORT = `${GLOBAL_HEADER_BUTTON_PREFIX}support`,
  BUTTON_SUSTAINABILITY = `${GLOBAL_HEADER_BUTTON_PREFIX}sustainability`,
  BUTTON_HELP = `${GLOBAL_HEADER_BUTTON_PREFIX}help`,
  //
  DESKTOP = 'global-header-desktop',
  HEADER_BAR = 'global-header-header-bar',
  DROPDOWN_WRAPPER = 'global-header-dropdown-wrapper',
  MOBILE = 'global-header-mobile',
  NAV_HEADER = 'global-header-nav-header',
  OFFCANVAS = 'offcanvas',
  OFFCANVAS_SEARCH = 'offcanvas-search',
  OFFCANVASLINKPANEL = 'offcanvas-link-panel',
  OFFCANVASLINK_PREFIX = 'offcanvas-link-',
  OFFCANVAS_HEADERLINK_PREFIX = 'mobile-header-button-',
  A11YCOPY = 'a11ycopy',
  DROPDOWN_CONTENT = 'dropdown-content',
  DROPDOWN_SUMMARY = 'dropdown-summary',
  DROPDOWN_BUTTON_PREFIX = 'dropdown-button-',
  DROPDOWN_BUTTON_INTERNET_PLANS = 'dropdown-button-internet-plans',
  DROPDOWN_BUTTON_SIM_PLANS = 'dropdown-button-mobile-sim-plans',
  MOBILE_MENU_HAMBURGER = 'mobile-menu-hamburger',
  MOBILE_MENU_SEARCH_BUTTON = 'mobile-menu-search-button',
  MOBILE_MENU_BUTTON_LOGIN = 'mobile-menu-button-login',
  MOBILE_MENU_BUTTON_LOGOUT = 'mobile-menu-button-logout'
}
