import React from 'react';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';

export interface IBaseRichTextProps {
  options?: Options;
  children: Document;
}

const BaseRichText: React.FC<IBaseRichTextProps> = ({ options, children }) => (
  <>{documentToReactComponents(children, options)}</>
);

BaseRichText.displayName = 'BaseRichText';
export default BaseRichText;
