import type { IEmbeddedInformationModal } from '@belong/types';
import { FImage } from '../image.factory';
import { FLink } from './elementLink.factory';

export const FElementEmbeddedInformationModal = ({ fields, buttonProps = {} }): IEmbeddedInformationModal => {
  return {
    link: {
      label: fields.modalTriggerText,
      variant: fields.modalTriggerVariant && fields.modalTriggerVariant.toLowerCase(),
      a11yLabel: fields.modalTriggerAriaLabel,
      icon: fields.buttonIcon,
      ...buttonProps
    },
    modalHeading: fields.modalHeading,
    modalContents: fields.modalContents,
    image: fields.image && FImage(fields.image),
    leftAlignText: !!fields.leftAlignText,
    primaryButtonLink: fields.primaryButtonLink && FLink(fields.primaryButtonLink),
    secondaryButtonLink: fields.secondaryButtonLink && FLink(fields.secondaryButtonLink)
  };
};
