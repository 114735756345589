import React from 'react';
import { logger } from '@belong/logging/logger';
import { RenderText } from '@contentful/rich-text-react-renderer';

const renderTextMapBreak = (str: string, i: number) => (str === '\n' ? <br key={i} /> : str);

export const RENDER_TEXT: RenderText = (text: string) => text.split(/(\n)/g).map(renderTextMapBreak);

export const warn = (type: string, data: any): null => {
  logger.error(`[${type}]: cannot render "${data?.target?.sys?.contentType?.sys?.id}"`);
  return null;
};
