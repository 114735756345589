export enum SEARCH_TEST_IDS {
  SEARCH_PANEL = 'search-panel',
  INPUT_ROOT = 'search-input-root',
  INPUT_FORM = 'search-input-form',
  INPUT_HEADER = 'search-input-header',
  INPUT_ERROR = 'search-input-error',
  INPUT_LABEL = 'search-input-label',
  INPUT_FIELD = 'search-input-field',
  SUBMIT_BUTTON = 'search-input-submit-button',
  RESULTS_HEADER = 'search-results-header',
  RESULTS_GROUP_HEADING = 'group-search-results-heading',
  RESULTS_SEE_MORE_RESULTS_BUTTON = 'search-see-more-results-button'
}
