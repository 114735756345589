import { maskRestrictedFields } from './maskers';
import { isObject } from './utils';

/**
 * Combine all message args into single string
 * and stringify any complex objects to avoid truncation ([Object object])
 */
export const combineLogs = (messages: any[]): string => {
  return messages.reduce((str, arg) => {
    if (arg instanceof Error) {
      return `${str} ${(arg as Error).stack}`;
    }

    if (typeof arg === 'object') {
      try {
        const obj = isObject(arg) ? maskRestrictedFields(arg) : arg;
        return `${str} ${JSON.stringify(obj)}`;
      } catch (err) {
        //
      }
    }
    return `${str} ${arg}`;
  }, '');
};
