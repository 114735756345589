import { useState } from 'react';

import { AnalyticsEvents } from '@belong/analytics';

import createProvider from '../_factory';

type AnalyticsEventsHook = (/* this hook takes no args */) => AnalyticsEvents;

const useAnalyticsEvents: AnalyticsEventsHook = () => {
  /*
   * This creates a new instance whenever a full page load (not SPA transition)
   * happens. Also obviously the client gets a different instance to the server.
   *
   * This is "thread safe" -- individual user sessions each get their own instance.
   */
  return useState(new AnalyticsEvents())[0];
};

const { hook, provider } = createProvider<AnalyticsEventsHook>(useAnalyticsEvents, 'AnalyticsEvents');

export const Provider = provider;

export default hook;

export { hook as useAnalyticsEvents };
