import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Document } from '@contentful/rich-text-types';

/**
 * Takes an input which may already be a string but may also be a rich text
 * document. If it is a string, returns the input as-is. If it is a rich text
 * document, converts the document to a string before returning it.
 */
export const stringOrRichTextToString = (input: string | Document, blockDivisor?: string): string =>
  typeof input === 'string' ? input : documentToPlainTextString(input, blockDivisor);
