import { useMemo } from 'react';
import { useCookies } from 'react-cookie';

import { COOKIES } from '@belong/constants';
import { decodeJwtToken } from '@belong/jwt';
import { logger } from '@belong/logging/logger';
import { IAgentIdToken, IAuth, USER_TYPE } from '@belong/types';

interface IAgent {
  agentName?: string;
  agentEmail?: string;
  userId?: string;
  isAgent: boolean;
}
export const useAgent = (): IAgent => {
  const [cookies] = useCookies([COOKIES.BELONG]);
  const belong = cookies[COOKIES.BELONG];
  return useMemo(() => getAgentDetails(belong), [belong]);
};

export const fallbackAgentName = 'Sales Agent';

export const getAgentDetails = (belong: IAuth | undefined): IAgent => {
  const isAgent = belong?.userType === USER_TYPE.AGENT;

  if (!isAgent) {
    return { isAgent };
  }

  if (!belong.idToken) {
    logger.error('Error - unable to find idToken in cookie');
    return { isAgent: false };
  }

  // The `custom_attributes` are set by sales forces so we cannot control the format
  /* eslint-disable camelcase */
  const decodedToken = decodeJwtToken<IAgentIdToken>(belong.idToken);

  if (!decodedToken || Object.keys(decodedToken).length === 0) {
    return { isAgent: false };
  }

  const { custom_attributes } = decodedToken;

  return {
    agentName: custom_attributes?.First_Name || fallbackAgentName,
    agentEmail: custom_attributes?.Email,
    isAgent,
    userId: custom_attributes?.User_ID
  };
  /* eslint-enable camelcase */
};
