import { COLOURS, Z_INDEX, CONTAINER_MAX_WIDTHS, media, isFocused } from '@belong/themes';
import styled from 'styled-components';
import { Alert, Container, withLink } from '@belong/ui-components';

export const HEIGHT = 72;
export const HEIGHT_WITH_UNIT = `${HEIGHT}px`;
export const ITEM_HEIGHT = '5.6rem';
const ANIMATION_TIME_HIDE_ON_SCROLL = '0.3s';
const ANIMATION_TIME_DROPDOWN = '0.5s';
const Z_INDEX_ROOT = Z_INDEX.GLOBAL_HEADER;
const Z_INDEX_ALERT = Z_INDEX_ROOT + 1;

export const Z_INDEX_HEADER_BAR = Z_INDEX.GLOBAL_HEADER + 1;
export const AGENT_HEADER_HEIGHT = '4.8rem';
export const LOGO_PADDING = '2.4rem';

export const HeaderBar = styled.div`
  z-index: ${Z_INDEX_HEADER_BAR};
  background-color: black;
`;

export const LogoWrapper = withLink(styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.6rem;
  margin-left: 6px;
  padding-left: ${LOGO_PADDING};
  padding-right: ${LOGO_PADDING};
  text-decoration: none;

  &:focus {
    ${(): any => isFocused(true)}
  }
`);

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
`;

export const LeftLinks = styled.div`
  display: flex;
  width: 100%;
`;

export const Root = styled.nav<{ isUserAgent?: boolean }>`
  display: none;
  background-color: black;

  ${media('lg')`
    display: block;
    height: ${HEIGHT_WITH_UNIT};
    ${({ isUserAgent }): string => (isUserAgent ? `margin-top: ${AGENT_HEADER_HEIGHT};` : '')}
  `}
`;

export const NavHeader = styled.div<{ isHidden?: boolean }>`
  position: fixed;
  z-index: ${Z_INDEX.GLOBAL_HEADER};
  left: 0;
  top: 0;
  background-color: ${COLOURS.BLACK};
  width: 100%;
  transition: transform ${ANIMATION_TIME_HIDE_ON_SCROLL} ease;

  ${({ isHidden }): any => isHidden && `transform: translate(0, -100%);`}
`;

export const Screen = styled.aside<{
  isExpanded: boolean;
  top: number;
}>`
  position: fixed;
  z-index: ${Z_INDEX_ROOT};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #000;
  overflow-y: auto;
  will-change: transform, visibility;
  visibility: hidden;
  transition: opacity ${ANIMATION_TIME_DROPDOWN} ease;

  ${({ isExpanded }): any =>
    isExpanded &&
    `
      opacity: 0.75;
      visibility: visible;
    `}
`;

export const StyledAlert = styled(Alert)`
  z-index: ${Z_INDEX_ALERT};
  position: relative;
`;

export const StyledContainer = styled(Container).attrs(() => ({
  isPadded: false
}))`
  margin: 0 2.3rem;
  // use XL max width for both LG & XL breakpoints
  max-width: ${CONTAINER_MAX_WIDTHS.xl};

  ${media('xl')`
    margin-left: auto;
    margin-right: auto;
  `}

  display: flex;
  align-items: center;
  height: ${HEIGHT_WITH_UNIT};
`;

export const StyledSearchContainer = styled(Container)`
  padding-top: 13rem;
  padding-bottom: 6.4rem;
  padding-left: calc(2 * 1.6rem);
  padding-right: calc(2 * 1.6rem);
`;
