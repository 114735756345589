import styled, { css } from 'styled-components';
import { StyledCSS, MaybeStyledCSS, ColoursValue } from '@belong/types';
import { isFocused, FONT_COLOURS, mediaMap } from '@belong/themes';
import { Icon, BaseFontStyles, Text, withLink } from '@belong/ui-components';

const HEIGHT = '7.2rem';
const ITEM_HEIGHT = '5.6rem';

const SIDE_PADDING = {
  md: '1rem',
  xl: '2.4rem'
};

export const Wrapper = styled.button<{
  hideUnderline?: boolean;
  rotateIconOnHover?: boolean;
  isRightLink?: boolean;
}>`
  position: relative;
  display: inline-flex;
  height: ${ITEM_HEIGHT};
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  background-color: black;
  border: none;
  outline: none;
  flex-shrink: 0;

  ${({ isRightLink }): MaybeStyledCSS =>
    isRightLink &&
    css`
      margin-left: auto;
    `};

  ${mediaMap(
    SIDE_PADDING,
    padding => css`
      padding-left: ${padding};
      padding-right: ${padding};
    `
  )};

  &:focus {
    ${(): StyledCSS => isFocused(true)};
  }

  &:hover,
  &:focus,
  &&:hover *,
  &&:focus * {
    color: ${FONT_COLOURS.ACTIVE};
  }

  ${({ hideUnderline }): MaybeStyledCSS =>
    !hideUnderline &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: calc(-1 * (${HEIGHT} - ${ITEM_HEIGHT}) / 2);
        display: block;
        height: 0.4rem;
        background: ${FONT_COLOURS.ACTIVE};
        opacity: 0;
        transition: opacity 0.2s ease;

        ${mediaMap(
          SIDE_PADDING,
          padding => css`
            width: calc(100% - 2 * ${padding});
          `
        )};
      }

      &:active,
      &:hover,
      &[aria-current] {
        &::after {
          opacity: 1;
        }
      }
    `};
`;

export const WrapperWithLink = withLink(Wrapper);

export const StyledIcon = styled(Icon)<{
  hasColor?: ColoursValue;
  hasPadding?: boolean;
  iconRight?: boolean;
  rotateIconOnHover?: boolean;
}>`
  vertical-align: middle;
  margin: ${({ hasPadding, iconRight }): any => {
    if (hasPadding) {
      return iconRight ? '0 0 0 0.8rem' : '0 0.8rem 0 0';
    }
    return 0;
  }};

  ${({ rotateIconOnHover, hasColor }): MaybeStyledCSS =>
    rotateIconOnHover &&
    css`
      transition: transform 0.5s ease;
      transform: ${(): string => (hasColor === FONT_COLOURS.ACTIVE ? 'rotate(180deg)' : 'rotate(0deg)')};
    `}
`;

export const Primary = styled(Text)`
  ${BaseFontStyles};
  padding-top: 0.3rem;
`;
