import { useState } from 'react';
import { IHeaderMenu } from '@belong/types';

import createProvider from '../_factory';

interface IProvider {
  header: IHeaderMenu | null;
  setHeader: (newValue: IHeaderMenu) => void;
}

type hook = (/* this hook takes no args */) => IProvider;

const useHeader: hook = () => {
  const [header, setHeader] = useState<IHeaderMenu | null>(null);

  return {
    header,
    setHeader
  };
};

const { hook, provider } = createProvider<hook>(useHeader, 'useHeader');

export const Provider = provider;

export default hook;
