import UrlPattern from 'url-pattern';
import type { ILinkRendererCollection, ILinkRenderer, IRouteDefinition } from '@belong/types';

// Create a pattern object for a known App route
export const getRoutePattern = (route: string): IRouteDefinition => ({ path: route, pattern: new UrlPattern(route) });

export default class RouteConfig {
  public debug = false;
  public basePath = '';
  public routes: IRouteDefinition[] = [];
  public fileRoutes: IRouteDefinition[] = [];
  public linkRenderers: ILinkRendererCollection = {};
  public defaultLinkRenderer: ILinkRenderer | null = null;

  setRoutes(routes: string[]): void {
    this.routes = routes.map(getRoutePattern);
  }

  setFileRoutes(routes: string[]): void {
    this.fileRoutes = routes.map(getRoutePattern);
  }

  setLinkRenderers(...renderers: ILinkRenderer[]): void {
    renderers.forEach(renderer => {
      this.linkRenderers[renderer.name] = { ...renderer };
    });
  }

  // copy a config:
  // - only retain link renderers
  // - discard all route info and other settings
  public clone(): RouteConfig {
    const config = new RouteConfig();
    config.setLinkRenderers(...Object.values(this.linkRenderers));
    config.defaultLinkRenderer = this.defaultLinkRenderer ? { ...this.defaultLinkRenderer } : null;
    return config;
  }
}
