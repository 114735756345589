import { createClient } from 'contentful';
import { logger } from '@belong/logging/logger';
import { getConfig as nextConfig } from '@belong/configs/next/config';
import { GlobalConfigItem, IGlobalConfigParsed } from '@belong/types';
import { extractGlobalLayoutContent } from '../factories/websiteConfig.factory';

const CONTENT_TYPE = 'content_type';

/**
 *
 * @param configEnv local contentful override
 * @returns Parsed header and footer content or statusCode === 500 if errors are found
 */
export const getWebsiteConfig = async (configEnv?: string): Promise<IGlobalConfigParsed> => {
  const { publicRuntimeConfig } = nextConfig();
  // if a modified environment is passed in, use that instead of the default
  const contentfulConfig = configEnv
    ? {
        ...publicRuntimeConfig.contentful,
        environment: configEnv
      }
    : publicRuntimeConfig.contentful;

  const contentfulClient = createClient(contentfulConfig);

  try {
    const { errors, total, items } = await contentfulClient.getEntries<GlobalConfigItem>({
      [CONTENT_TYPE]: 'websiteConfig',
      'fields.identifier': 'SiteConfig:Public',
      include: 10
    });

    /**
     * Payload returned with no content.
     * 500 error instead of 404 error
     * since this is expected to be found.
     */
    if (errors || !total || !items) {
      logger.error('Issues found in fetching websiteConfig');
      logger.info('With errors: ', errors);
      logger.info('With total: ', total);
      logger.info('With items: ', items);

      return { statusCode: 500 };
    }

    return extractGlobalLayoutContent(items);
  } catch (err) {
    logger.error('Failed to fetch websiteConfig, errors', err);
    return { statusCode: 500 };
  }
};
