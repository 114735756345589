import { Document } from '@contentful/rich-text-types';
import { ISpacingProps } from './theme';
import type { ILink } from './links';
import type { IRichText } from './ui';

export enum ALERT_POSITION {
  INLINE = 'INLINE',
  BLOCK = 'BLOCK',
  GLOBAL = 'GLOBAL'
}

export enum ALERT_LEVELS {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  MESSAGE = 'MESSAGE',
  MOSAIC = 'MOSAIC'
}

export type TAlertPosition = keyof typeof ALERT_POSITION;
export type TAlertLevel = keyof typeof ALERT_LEVELS;

export interface IAlert extends ISpacingProps {
  position: TAlertPosition;
  type: TAlertLevel;
  onDismiss?: () => void;
  children: any;
  link?: ILink;
  className?: string;
  role?: string;
  'aria-hidden'?: boolean;
  'aria-label'?: string;
  id?: string;
  code?: string;
  innerRef?: React.Ref<HTMLDivElement>;
  hasFocus?: boolean;
  asNotification?: boolean;

  /**
   * This should only be used if there are multiple alerts which need to have their height aligned
   * for example, in the plan cards
   */
  alertHeight?: string;
}

export interface IMoleculeAlert {
  id: string;
  code: string;
  message: IRichText;
  level: ALERT_LEVELS;
  inputName?: string;
  inputErrorMessage?: string;
  link?: ILink;
}

export interface IPageAlert extends IAlert {
  position: ALERT_POSITION.GLOBAL;
  content?: Document;
}
