import { ReactNode } from 'react';
import { HeadingElements, IIcon, ISpacingProps } from '@belong/types';

export type AccordionRowVariant = 'default' | 'slim';
export enum ACCORDION_ROW_STATE {
  open = 'open',
  closed = 'closed'
}
export type AccordionRowState = keyof typeof ACCORDION_ROW_STATE;

export interface IAccordionRow extends React.ComponentPropsWithRef<any>, ISpacingProps {
  id?: string;
  label: string;
  headingLevel?: Omit<HeadingElements, 'h1' | 'h6'>;
  icon?: IIcon;
  variant?: AccordionRowVariant;
  available?: boolean;
  hasBackground?: 'transparent' | 'grey' | string;
  initialState?: AccordionRowState;
  children: ReactNode;
  headingHasVerticalBorder?: boolean;
  hasBottomBorder?: boolean;
  onClick?: (any) => void;
  unmountCollapsedContent?: boolean;
}
