import type { ILinkItem } from '@belong/types';

export const FLinkListItem = (data: any): ILinkItem => {
  const { shouldOpenInNewTab, url, ...fields } = data.fields;

  if (data.fields.shouldOpenInNewTab) {
    fields.target = '_blank';
  }

  return {
    ...fields,
    href: url.fields.url
  };
};
