import * as styles from './styles';

export type TLevelOpen = boolean | undefined;

interface IHamburgerMenuIcon {
  onClick?: (e: React.MouseEvent<any>) => void;
  open?: TLevelOpen;
  secondLevelOpen?: TLevelOpen;
}

export const HamburgerMenuIcon = ({ open, secondLevelOpen, onClick, ...otherProps }: IHamburgerMenuIcon) => (
  <styles.Wrapper onClick={onClick} {...otherProps}>
    <styles.Inner>
      {!secondLevelOpen && <styles.Line1 open={open} />}
      <styles.Line2 open={open} secondLevelOpen={secondLevelOpen} />
      <styles.Line3 open={open} secondLevelOpen={secondLevelOpen} />
      {!secondLevelOpen && <styles.Line4 open={open} />}
    </styles.Inner>
  </styles.Wrapper>
);

HamburgerMenuIcon.displayName = 'HamburgerMenuIcon';
