import { COLOURS, SHADOWS, media, BORDER_RADIUS } from '@belong/themes';
import { ShadowsKey, StyledCSS } from '@belong/types';
import styled, { css } from 'styled-components';

export interface IContainerProps {
  containsForm?: boolean;
  hasShadow?: boolean;
  shadowVariant?: ShadowsKey;
}
export const Container = styled.div<IContainerProps>`
  background: ${COLOURS.WHITE};
  border-radius: ${BORDER_RADIUS.CARD};

  /* --- [ Shadow / border rules ] ----------------------------- */

  ${({ containsForm, hasShadow, shadowVariant = 'KEY_LIGHT' }): StyledCSS =>
    containsForm
      ? css`
          box-shadow: none;

          ${media('md')`
            box-shadow: ${SHADOWS[shadowVariant]};
          `};
        `
      : css`
          box-shadow: ${hasShadow ? SHADOWS[shadowVariant] : 'none'};
        `}
`;
