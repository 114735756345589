import { logger } from '@belong/logging/logger';
import type { IFeatureToggles } from '../types';

/**
 * @deprecated Use FeatureTogglesProvider and useFeatureToggles hook.
 */
class FeaturesManager {
  private values: IFeatureToggles = {};
  private isInitDone = false;

  /**
   * Only warn once per session
   * (always suppress the warning when running in Jest)
   */
  private hasWarnedAboutUsageBeforeSeeding = false;

  /**
   * @deprecated Use FeatureTogglesProvider and useFeatureToggles hook.
   */
  public init(correlationId: string, featuresMap: IFeatureToggles): void {
    logger.debug(correlationId, 'FeaturesManager::init()');
    this.values = { ...featuresMap };

    this.isInitDone = true;
  }

  /**
   * @deprecated Use FeatureTogglesProvider and useFeatureToggles hook.
   */
  public isFeatureOn(feature: string): boolean {
    if (!this.isInitDone || !this.values) {
      if (!this.hasWarnedAboutUsageBeforeSeeding) {
        logger.error('FeaturesManager.isFeatureOn used before it was seeded with any feature values!');
        this.hasWarnedAboutUsageBeforeSeeding = true;
      }
      return false;
    }
    // ensure false value if toggle isn't defined
    return this.values[feature] ?? false;
  }

  /**
   * @deprecated Use FeatureTogglesProvider and useFeatureToggles hook.
   */
  public export(): IFeatureToggles {
    return { ...this.values };
  }
}

const featuresManager = new FeaturesManager();
export default featuresManager;
