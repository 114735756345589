import React from 'react';

import { ICON_NAMES } from '../icons';

export enum STATUS_INDICATOR_STATUSES {
  SUCCESS = 'success', // green
  INFO = 'info', // blue
  WARNING = 'warning', // orange
  DANGER = 'danger', // red
  INACTIVE = 'inactive' // grey
}

export enum STATUS_INDICATOR_VARIANT {
  DEFAULT = 'default',
  STATUS_PILL = 'status-pill',
  NO_STATUS_LIGHT = 'no-status-light'
}

export interface IStatusIndicator {
  status: STATUS_INDICATOR_STATUSES;
  type?: STATUS_INDICATOR_VARIANT;
  children?: React.ReactNode;
  withIcon?: boolean;
  iconName?: ICON_NAMES;
  a11yPrefixStatusIndicator?: string;
}
