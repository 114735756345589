import { getConfig } from '@belong/configs/next/config';

const { basePath } = getConfig().publicRuntimeConfig;

export const INTERNAL_ROUTES = {
  // NOTE. basePath contains leading slash, eg. /go
  BLOG: `${basePath}/blog`,
  HOME: '/',
  INTERNET_PLANS: `${basePath}/internet`,
  MOBILE_DATA_PLANS: `${basePath}/data-plans`,
  MOBILE_PLANS: `${basePath}/mobile`,
  INTERNATIONAL_ROAMING: `${basePath}/international-roaming`,
  OUR_MOBILE_NETWORK: `${basePath}/our-mobile-network`,
  COVERAGE_MAP: `${basePath}/mobile/coverage-map`,
  PUBLIC_WILDCARD: `${basePath}/*`,
  REFERRAL_SQ: `${basePath}/referral`,
  SUSTAINABLIITY: `${basePath}/go/sustainability`,
  REFERRAL_SQ_INACTIVE_CAMPAIGN: `${basePath}/internet/inactive-campaign`
};

export const EXTERNAL_ROUTES = {
  LOGIN: '/login',
  MY_PROFILE: '/new/profile',
  MY_SERVICES: '/new/services',
  SECOND_LIFE_DEVICES: `https://shop.belong.com.au`, // assumes there is only one shop and no shop.belongtest.com.au
  SUPPORT: '/support'
};

export const ROUTES = {
  ...EXTERNAL_ROUTES,
  ...INTERNAL_ROUTES
};
