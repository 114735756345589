import styled from 'styled-components';

import { FONT_COLOURS, isFocused, media } from '@belong/themes';
import withLink from '../../hocs/withLink';
import { Icon } from '../../Icon';

export const SocialLink = withLink(styled.a`
  line-height: 0;
  text-decoration: none;
  width: 4.8rem; // larger touch target
  height: 4.8rem;

  display: flex;
  align-items: center; // vertically center icon in container
  justify-content: center;

  :focus {
    ${isFocused()}
  }

  ${media('md')`
    & + & {
      margin-left: 2rem;
    }
  `}
`);

export const StyledIcon = styled(Icon)`
  color: ${FONT_COLOURS.LIGHT};

  &:hover {
    color: ${FONT_COLOURS.ACTIVE};
  }
`;
