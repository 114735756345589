import { isObject } from './utils';

export type ObjectMasker = (obj: UntypedObject) => UntypedObject;
export type UntypedObject = Record<string, any>;

const ADDRESS_FIELDS = [
  'streetNumber',
  'streetNumberSuffix',
  'streetName',
  'streetSuffix',
  'streetType',
  'streetAddress',
  'locality',
  'state',
  'postcode',
  'addrText',
  'addressLine1',
  'addressLine2',
  'buildingName',
  'subBuildingType',
  'subBuildingNumber',
  'buildingLevelType',
  'buildingLevelNumber',
  'lotNumber'
];

const PAYMENT_FIELDS = ['cardType', 'lastFour', 'cardNumber', 'expirationDate', 'cvv'];

const RESTRICTED_FIELDS = [
  ...ADDRESS_FIELDS,
  ...PAYMENT_FIELDS,
  'arn',
  'dateOfBirth',
  'mobileNumber',
  'serviceNumber',
  'number',
  'password',
  'email',
  'emailAddress'
];

const maskValue = (key: string, value: any): string => {
  if (!value.toString().length) {
    return '';
  }
  if (PAYMENT_FIELDS.includes(key) || value.length < 5) {
    return '****';
  }
  return value.toString().replace(/./g, (match: string, idx: number, str: string): string => {
    return [0, str.length - 1].includes(idx) ? match : '*';
  });
};

export const restrictedFieldMasker = (fieldsToMask: string[]): ObjectMasker => {
  const maskFields = (obj: UntypedObject): UntypedObject =>
    Object.entries(obj).reduce((acc: any, [key, value]): UntypedObject => {
      if (isObject(value)) {
        acc[key] = maskFields(value);
        return acc;
      }

      if (fieldsToMask.includes(key)) {
        // mask and convert to string
        acc[key] = maskValue(key, value);
      } else {
        acc[key] = value;
      }

      return acc;
    }, {} as UntypedObject);

  return obj => maskFields(obj);
};

// Generic sensitive information masker
export const maskRestrictedFields = restrictedFieldMasker(RESTRICTED_FIELDS);
