import { PropsWithChildren } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { mediaMap } from '@belong/themes';
import { responsiveValue } from '@belong/ui-core';
import { AlignX, alignXToFlex, AlignY, alignYToFlex, ITestable, ResponsiveProp, ColumnWidth } from '@belong/types';

export interface IColumnProps extends PropsWithChildren<ITestable> {
  /**
   * The horizontal alignment of the children in Column. Similar to alignX in Stack.
   */
  alignX?: ResponsiveProp<AlignX>;

  /**
   * The vertical alignment of the children in Column. Similar to alignY in Columns.
   */
  alignY?: ResponsiveProp<AlignY>;

  /**
   * The value `content` forces the Column to take up as much width as its children need.
   * If you want a Column to take up all the space left in a row, use `flex` value for width.
   * All other values provide percentages of the parent width, respecting the gap provided
   * by parent between columns.
   */
  width?: ResponsiveProp<ColumnWidth>;
}

const getSizeStyle = (scale: number): FlattenSimpleInterpolation => css`
  flex-shrink: 1;
  flex-grow: 0;
  width: ${scale * 100}%;
`;

export const widthToCSS: Record<ColumnWidth, FlattenSimpleInterpolation> = {
  content: css`
    flex: 0 0 auto;
  `,
  flex: css`
    flex: 1 1 auto;
  `,
  '1/12': getSizeStyle(1 / 12),
  '1/6': getSizeStyle(1 / 6),
  '1/5': getSizeStyle(1 / 5),
  '1/4': getSizeStyle(1 / 4),
  '1/3': getSizeStyle(1 / 3),
  '2/5': getSizeStyle(2 / 5),
  '5/12': getSizeStyle(5 / 12),
  '1/2': getSizeStyle(1 / 2),
  '7/12': getSizeStyle(7 / 12),
  '3/5': getSizeStyle(3 / 5),
  '2/3': getSizeStyle(2 / 3),
  '3/4': getSizeStyle(3 / 4),
  '5/6': getSizeStyle(5 / 6),
  '11/12': getSizeStyle(11 / 12),
  '1': getSizeStyle(1)
};

const columnDefaultProps: Required<Omit<IColumnProps, 'children' | 'alignY'>> = {
  alignX: 'left',
  'data-testid': 'column',
  width: 'flex'
};

// todo: these alignX styles could be extracted and reused.
export const Column = styled.div
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => !['width'].includes(prop) && defaultValidatorFn(prop)
  })
  .attrs((props: IColumnProps) => ({
    ...props,
    alignX: props.alignX || columnDefaultProps.alignX,
    'data-testid': props['data-testid'] || columnDefaultProps['data-testid'],
    width: props.width || columnDefaultProps.width
  }))`
  min-width: 0;
  ${p =>
    mediaMap(
      responsiveValue(p.alignX),
      alignX => css`
        display: ${alignX === 'left' ? 'block' : 'flex'};
        justify-content: ${alignXToFlex[alignX]};
      `
    )};

  /**
   * When an object is passed in as the width, anything under the lower breakpoint has no width set due to the 
   * way mediaMap works. However, it should be use the default value specified in 'columnDefaultProps' for
   * example { lg: '1/2' } would only set lg and greater to have a width
  */
  ${p =>
    mediaMap(
      {
        xs: columnDefaultProps.width,
        ...responsiveValue(p.width)
      },
      width => css`
        ${widthToCSS[width]}
      `
    )};

  ${p =>
    p.alignY &&
    mediaMap(
      responsiveValue(p.alignY),
      alignY => css`
        align-self: ${alignYToFlex[alignY]};
      `
    )}
`;
Column.displayName = 'Column';
