import React from 'react';
import { Wrapper } from './ContentFormatter.styles';
import { IContentFormatter } from './ContentFormatter.types';

const ContentFormatter: React.FC<IContentFormatter> = ({
  children,
  alignment,
  spacing = 'medium',
  contentLength = 'short',
  ...otherProps
}: IContentFormatter) => (
  <Wrapper {...otherProps} alignment={alignment} contentLength={contentLength} spacing={spacing}>
    {children}
  </Wrapper>
);

ContentFormatter.displayName = 'ContentFormatter';
export default ContentFormatter;
