import { setCookie as writeCookie } from 'cookies-next';
import type { GetServerSidePropsContext, NextPageContext } from 'next';

import { COOKIES } from '@belong/constants';

/**
 * Set a cookie on either client or server side within NextJS app using the cookies-next library.
 *
 * @param name string - cookie name
 * @param data any - cookie value
 * @param options any - cookie options
 * @param context NextJS context - pass to enable setting cookie SSR or in API route
 */
export const setCookie = (
  name: (typeof COOKIES)[keyof typeof COOKIES],
  data: any,
  options?: any,
  context?: NextPageContext | GetServerSidePropsContext
): void => {
  const ctxOptions = context?.req && context?.res ? { req: context.req, res: context.res } : undefined;

  writeCookie(name, data, { ...options, ...ctxOptions });
};
