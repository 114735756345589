import styled from 'styled-components';

// For accessibility; screen-reader to read without displaying visually
// useful for giving more context to screen-reader users, and avoiding visible contents
// being cluttered with extra text
export const VisuallyHidden = styled.span`
  position: absolute;
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  white-space: nowrap;
`;
