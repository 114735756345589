import { FC } from 'react';
import { InstantSearch, InstantSearchSSRProvider } from 'react-instantsearch';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import singletonRouter from 'next/router';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';
import type { InitialResults } from 'instantsearch.js';
import { ISearchProviderProps } from './types';

import {
  CONFIG,
  RESULTS_PER_PAGE,
  QUERY_BY,
  VECTOR_QUERY,
  searchIndexName,
  searchOptions,
  TOKENS_NUMBER,
  EXCLUDE_FIELDS
} from '../searchConfig';

export const SearchProvider: FC<ISearchProviderProps> = ({ children, searchServerUrl }) => {
  /*
   ** This function is unused for the time being. It will come into play if/when we do live search results
   **
   **  const queryHookFunction = (query: string, searchFunction: (query: string) => void): void => {
   **    searchFunction(query);
   **  };
   */

  // fallback in case searchServerUrl is not provided and the SearchProvider can't be rendered
  //
  if (!searchServerUrl) {
    return <>{children}</>;
  }

  const urlParams = new URL(searchServerUrl);
  const searchQuery = urlParams.searchParams.get('query');

  const searchRouting = searchQuery
    ? {
        stateMapping: {
          stateToRoute(uiState) {
            const indexUiState = uiState[searchIndexName];
            return {
              query: indexUiState.query
              // page: indexUiState.page  TODO: Page not yet implimented
            };
          },

          routeToState(routeState) {
            return {
              [searchIndexName]: {
                query: routeState.query
                // page: routeState.page TODO: Page not yet implimented
              }
            };
          }
        },
        router: createInstantSearchRouterNext({
          singletonRouter,
          serverUrl: searchServerUrl,
          routerOptions: {
            cleanUrlOnDispose: false
          }
        })
      }
    : false;

  const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
      apiKey: CONFIG.apiKey ?? '',
      sendApiKeyAsQueryParam: false,
      nodes: [
        {
          host: CONFIG.host ?? '',
          path: CONFIG.path ?? '/',
          port: CONFIG.port ?? 8108,
          protocol: CONFIG.protocol ?? 'http'
        }
      ]
    },

    // The following parameters are directly passed to the Typesense search API endpoint
    // so you can pass any parameters supported by the search endpoint below.
    // queryBy is required.
    additionalSearchParameters: {
      query_by: QUERY_BY,
      per_page: RESULTS_PER_PAGE,
      vector_query: VECTOR_QUERY,
      exclude_fields: EXCLUDE_FIELDS,
      highlight_affix_num_tokens: TOKENS_NUMBER
    }
  });

  const { searchClient } = typesenseInstantsearchAdapter;

  const limitedRequestsSearchClient = {
    ...searchClient,
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: ''
          }))
        });
      }
      return searchClient.search(requests);
    }
  };

  const serverState: { initialResults: InitialResults } = {
    initialResults: {
      searchIndexName: {
        state: {
          index: searchIndexName
        },
        results: [
          {
            query: searchQuery,
            queryAfterRemoval: searchQuery,
            params: `query=${searchQuery}`
          }
        ],
        requestParams: [
          {
            query: searchQuery
          }
        ]
      }
    }
  };

  return (
    <InstantSearchSSRProvider {...serverState}>
      <InstantSearch
        indexName={searchIndexName}
        searchClient={limitedRequestsSearchClient}
        routing={searchRouting}
        future={searchOptions}
      >
        {children}
      </InstantSearch>
    </InstantSearchSSRProvider>
  );
};

SearchProvider.displayName = 'SearchProvider';
