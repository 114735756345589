import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import useRuntime from '@belong/providers/runtime';
import { ICON_NAMES } from '@belong/types';

import { GLOBAL_BACK_TO_TOP_PORTAL, GLOBAL_BACK_TO_TOP_TRIGGER } from '../../../helpers/testIds';
import { useBackToTopVisibility } from '../../hooks/useBackToTopVisibility';
import { FullPrompt, Icon, Root, ScreenReaderOnly, TruncatedPrompt } from './BackToTopTrigger.styles';

interface IProps {
  withShadow?: boolean;
  icon?: ICON_NAMES;
  appearanceThreshold?: number;
}

const TheButton: React.FC<IProps> = ({
  withShadow = true,
  icon = ICON_NAMES.ChevronUp,
  appearanceThreshold = 3 // how many screen heights (100vh) the user must scroll before showing
}: IProps) => {
  const backToTopVisibility = useBackToTopVisibility(appearanceThreshold);

  const rootNode = useRef<HTMLButtonElement>();
  const { isDesktopViewport } = useRuntime();

  /**
   * When 'back to top' button is clicked scroll to top and
   * move focus to a visible element at the top so it is intuitive
   * specially for sighted keyboard users
   */
  const scrollToTop = () => {
    // scroll to top of the current page
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    // Get a visible element at the top (Belong home button)
    const homeMobile = document
      ?.querySelector(`[data-testid="global-header-mobile"]`)
      ?.querySelector(`[data-testid="global-header-button-home"]`);
    const homeDesktop = document
      ?.querySelector(`[data-testid="global-header-desktop"]`)
      ?.querySelector(`[data-testid="global-header-button-home"]`);

    const topElement = (isDesktopViewport ? homeDesktop : homeMobile) as HTMLElement | null;

    if (topElement) {
      // Add focus ring to Belong home button (accessibility requirement)
      topElement.focus({ preventScroll: true });
    }
  };

  const handleClick = () => {
    if (rootNode.current) {
      rootNode.current.blur();
    }

    scrollToTop();
  };

  return (
    <Root
      ref={rootNode as any}
      onClick={handleClick}
      withShadow={withShadow}
      shouldShow={!backToTopVisibility.isHidden}
      tabIndex={backToTopVisibility.isHidden ? -1 : 0}
      data-testid={GLOBAL_BACK_TO_TOP_TRIGGER}
    >
      <Icon name={icon} />
      <FullPrompt>Back to top</FullPrompt>
      <TruncatedPrompt>
        <ScreenReaderOnly aria-label="Back to" />
        Top
      </TruncatedPrompt>
    </Root>
  );
};

TheButton.displayName = 'TheButton';

export const BackToTopTrigger: React.FC<IProps> = (props: IProps) => {
  const [mountNode, setMountNode] = useState<HTMLElement | null>(null);

  /**
   * We mount this component at the very end of the document, right before the
   * `</body>` so it will be the most-on-top at all times.
   */
  useEffect(() => {
    const portalMountNode = document.createElement('div');
    portalMountNode.dataset.testid = GLOBAL_BACK_TO_TOP_PORTAL;

    const body = document.querySelector('body');

    if (body) {
      body.append(portalMountNode);
      setMountNode(portalMountNode);
    }

    return () => {
      setMountNode(null);
      const nodeToRemove = document.querySelector(`[data-testid="${GLOBAL_BACK_TO_TOP_PORTAL}"]`);
      if (nodeToRemove) {
        nodeToRemove.remove();
      }
    };
  }, [setMountNode]);

  if (!mountNode) {
    return null;
  }

  return createPortal(<TheButton {...props} />, mountNode);
};

BackToTopTrigger.displayName = 'BackToTopTrigger';

export default BackToTopTrigger;
