// Always initially assume not a blue host
const BLUE = 'blue';
let blueHost = false;

export const isBlueHost = (): boolean => {
  return blueHost;
};

/**
 * @description Takes a requestOrigin/ host and
 * determine if it's one of belong blue domains
 * @param requestOrigin ctx.req.headers.host or window.location.host
 */
export const setBlueHost = (requestOrigin?: string): void => {
  blueHost = !!requestOrigin?.includes(BLUE);
};
