import { COLOURS, FONT_WEIGHT, isFocused, Z_INDEX } from '@belong/themes';
import { URI_ICONS } from '@belong/web-icons/dist/dynamic';
import styled, { css } from 'styled-components';
import { LinearProgress } from '@belong/ui-core';
import ButtonUnstyled from '../../actions/ButtonUnstyled';
import TextInput from '../TextInput';

export const Wrapper = styled.div`
  position: relative;
`;

//  NOTE: you MUST use double-quotes ("") not single quotes ('') here or the string is not properly parsed
// prettier-ignore
const chevronDownBg = css`
  background-image: url("${URI_ICONS.ChevronDown}");
`;

export const ExpandIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 4.8rem;
  height: 100%;

  ${chevronDownBg}
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) 1.1rem;
  background-size: 2.4rem;

  z-index: ${Z_INDEX.SEARCH_BOX + 1};
  pointer-events: none;
`;

export const IconWrapper = styled.span`
  padding: 1rem;
  line-height: 0;
  position: absolute;
  z-index: ${Z_INDEX.SEARCH_BOX + 1};
  top: 50%;
  left: 0.2rem;
  transform: translate3d(0, -50%, 0);
`;

export const ClearBtn = styled(ButtonUnstyled)
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => !['onDarkBG'].includes(prop) && defaultValidatorFn(prop)
  })
  .attrs({
    type: 'button'
  })<{ onDarkBG?: boolean }>`
  padding: 0.8rem;
  line-height: 1;
  position: absolute;
  z-index: ${Z_INDEX.SEARCH_BOX + 1};
  top: 50%;
  right: 0.4rem;
  transform: translate3d(0, -50%, 0);
  cursor: pointer;
  color: ${({ onDarkBG }) => (onDarkBG ? COLOURS.BELONG_BLUE : COLOURS.DARK_BLUE)};
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};

  &:focus {
    ${() => isFocused(true)}
  }

  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.2rem;
  }
`;

export const Input = styled(TextInput)<{ searchIcon?: boolean }>`
  padding-right: 6.4rem;
  padding-left: ${({ searchIcon }) => (searchIcon ? '4.4rem' : '1.2rem')};
  position: relative;
  z-index: ${Z_INDEX.SEARCH_BOX};
`;

export const Progress = styled(LinearProgress).attrs({
  isThin: true
})`
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  right: 0.1rem;
  z-index: ${Z_INDEX.SEARCH_BOX_PROGRESS_BAR};
`;
