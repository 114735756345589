import styled from 'styled-components';

import { COLOURS, Z_INDEX } from '@belong/themes';

export const StyledSkipLink = styled('a')`
  position: absolute;
  z-index: ${Z_INDEX.SKIP_LINK};
  top: -100rem;
  left: -100rem;

  background: ${COLOURS.WHITE};
  padding: 0.5rem;

  &:focus {
    top: 1rem;
    left: 1rem;
  }
`;
