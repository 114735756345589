import React, { useState } from 'react';
import { Collapse, CollapseCallbackArgs, UnmountClosed } from 'react-collapse';
import { MaybeStyledCSS, MediaMapResult } from '@belong/types';
import {
  SECTION_VERTICAL_INNER_SPACINGS,
  INPUTS_WRAPPER_VERTICAL_SPACING,
  FIELDSET_VERTICAL_SPACING,
  mediaMap
} from '@belong/themes';
import styled, { css } from 'styled-components';
import { ICollapsibleItem, IPlaceholderItem, TisChildrenOf } from './CollapsibleItem.types';

const getSpacingFromChildrenOfValue = (isChildrenOf: TisChildrenOf): { [key: string]: string } | null => {
  switch (isChildrenOf) {
    case 'InnerSection':
      return SECTION_VERTICAL_INNER_SPACINGS;
    case 'InputsWrapper':
      return INPUTS_WRAPPER_VERTICAL_SPACING;
    case 'Fieldset':
      return { xs: FIELDSET_VERTICAL_SPACING };
    default:
      return null;
  }
};

const childrenOf = ({ isChildrenOf, isPlaceholder = false }: IPlaceholderItem): MediaMapResult | null => {
  const SPACINGS = getSpacingFromChildrenOfValue(isChildrenOf);
  if (!SPACINGS) {
    return null;
  }

  return mediaMap(SPACINGS, (spacing: string) =>
    isPlaceholder
      ? css`
          margin-top: -${spacing};
        `
      : css`
          padding-top: ${spacing};
        `
  );
};

export const PlaceholderItem: React.FC<IPlaceholderItem> = styled.div<IPlaceholderItem>`
  ${({ isChildrenOf }): MaybeStyledCSS | null => childrenOf({ isChildrenOf, isPlaceholder: true })}
`;

const CollapsibleItem: React.FC<ICollapsibleItem> = styled(
  ({ className, shouldUnmount = false, isChildrenOf, ...otherProps }: ICollapsibleItem) => {
    const [collapseClass, setCollapseClass] = useState(className);

    const props = {
      theme: {
        collapse: `${collapseClass}`,
        content: `${className}__inner`
      },
      ...otherProps
    };

    /**
     * Callback function triggered when animation has started
     * */
    const onWork = (args: CollapseCallbackArgs) => {
      // If the collapse is opening, we need to remove the hidden class
      if (args.isOpened) {
        setCollapseClass(`${className}`);
      }
    };

    /**
     *  Callback function triggered when animation has completed
     * */
    const onRest = (args: CollapseCallbackArgs) => {
      // If the collapse is fully closed, we need to add the hidden class
      if (args.isFullyClosed) {
        setCollapseClass(`${className} hidden`);
      }
    };

    return shouldUnmount ? <UnmountClosed {...props} /> : <Collapse {...props} onRest={onRest} onWork={onWork} />;
  }
)<ICollapsibleItem>`
  transition: height 500ms;

  &&.hidden {
    visibility: hidden;
  }

  ${({ isChildrenOf }): MaybeStyledCSS =>
    isChildrenOf &&
    css`
      && {
        margin-top: 0;
      }

      &__inner {
        ${(): MaybeStyledCSS | null => childrenOf({ isChildrenOf })}
      }
    `}
`;

export default CollapsibleItem;
