import React from 'react';
import { IPicture } from './Picture.types';
import { PICTURE } from '../../../helpers/testIds';

/**
 * Picture component with media queries
 */
export const Picture: React.FC<IPicture> = ({
  className,
  source,
  alt,
  loading = 'lazy',
  contentType,
  showHoverEffect,
  ...otherProps
}: IPicture) => {
  if (Array.isArray(source)) {
    const defaultSource = source[source.length - 1].src;

    return (
      <picture data-testid={PICTURE.ROOT}>
        {source.map(({ media, src }) => (
          <source key={src + media} media={media} srcSet={src} />
        ))}
        <img
          loading={loading}
          data-testid={PICTURE.IMG}
          alt={alt}
          className={className}
          src={defaultSource}
          {...otherProps}
        />
      </picture>
    );
  }

  return (
    <img loading={loading} data-testid={PICTURE.IMG} alt={alt} className={className} src={source} {...otherProps} />
  );
};

Picture.displayName = 'Picture';
export default Picture;
