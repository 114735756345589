import { COLOURS } from '@belong/themes';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  &:hover {
    .playing {
      opacity: 1;
    }
  }
`;

export const Video = styled.video`
  object-fit: cover;
`;

export const HiddenTooltip = styled.span`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const ButtonLabel = styled.span<{ showControl: boolean }>`
  position: relative;

  ${({ showControl }): any =>
    showControl &&
    css`
      padding-left: 2rem;
      &::before {
        content: '';
        width: 1.35rem;
        height: 1.6rem;
        border-color: transparent transparent transparent ${COLOURS.BELONG_BLUE};
        border-style: solid;
        border-width: 0.8rem 0 0.8rem 1.35rem;

        position: absolute;
        left: 0rem;
        top: 0rem;
      }
    `}
`;

export const PlayButton = styled.button`
  position: absolute;
  left: 2rem;
  bottom: 3rem;
  border: none;
  padding: 1.2rem 1.6rem;
  border-radius: 4rem;
  color: ${COLOURS.WHITE};
  background-color: rgb(0, 0, 0, 0.75);
  opacity: 1;
  transition: opacity 0.5s;
  cursor: pointer;

  &.playing {
    opacity: 0;

    .play-button-label::before {
      border-style: double;
      border-width: 0 0 0 1.6rem;
      top: 0rem;
      left: 0rem;
    }
  }

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

export const ControlLabel = styled.span`
  display: inline-block;
  min-width: 4.8rem;
  margin-left: 0.8rem;
  text-align: left;
`;

export const TimeLabel = styled.span`
  display: inline-block;
  min-width: 4rem;
  text-align: right;
`;

export const TimeSeparator = styled.span`
  display: inline-block;
  margin: 0 0.8rem;
`;

export const DurationLabel = styled.span`
  display: inline-block;
  min-width: 4rem;
  text-align: left;
`;
