export const SHORT_SPACING = {
  large: {
    xs: '0.8rem',
    md: '1.6rem',
    lg: '2.4rem'
  },
  medium: {
    xs: '0.8rem',
    md: '1.6rem'
  },
  small: {
    xs: '0.8rem'
  }
};

const SPACING = {
  SMALL: {
    xs: '0.8rem',
    md: '1.6rem'
  },
  STANDARD: {
    xs: '1.6rem',
    md: '2.4rem'
  },
  LARGE: {
    xs: '2.4rem',
    md: '3.2rem'
  },
  XL: {
    xs: '3.2rem',
    md: '4.0rem'
  },
  XXL: {
    xs: '4.0rem',
    md: '4.8rem'
  }
};

export const LONG_SPACING = {
  p: {
    p: SPACING.STANDARD,
    h2: SPACING.XXL,
    h3: SPACING.LARGE,
    h4: SPACING.LARGE,
    ol: SPACING.SMALL,
    ul: SPACING.SMALL,
    '.columns': SPACING.SMALL,
    '.media-caption-block': SPACING.STANDARD,
    '.alert': SPACING.STANDARD,
    a: SPACING.STANDARD,
    button: SPACING.STANDARD
  },
  h2: {
    p: {
      xs: '1.6rem'
    },
    h3: {
      xs: '2.4rem'
    },
    h4: {
      xs: '2.4rem'
    },
    '.media-caption-block': SPACING.STANDARD,
    a: {
      xs: '2.4rem'
    },
    button: {
      xs: '2.4rem'
    },
    '.alert': SPACING.STANDARD
  },
  h3: {
    p: {
      xs: '1.6rem'
    },
    ol: SPACING.SMALL,
    ul: SPACING.SMALL,
    '.columns': SPACING.SMALL,
    '.media-caption-block': {
      xs: '2.4rem'
    },
    a: {
      xs: '2.4rem'
    },
    button: {
      xs: '2.4rem'
    },
    '.alert': SPACING.STANDARD
  },
  h4: {
    p: {
      xs: '1.6rem'
    },
    ol: SPACING.SMALL,
    ul: SPACING.SMALL,
    '.columns': SPACING.SMALL,
    '.media-caption-block': {
      xs: '2.4rem'
    },
    a: {
      xs: '2.4rem'
    },
    button: {
      xs: '2.4rem'
    },
    '.alert': SPACING.STANDARD
  },
  ol: {
    p: SPACING.SMALL,
    h2: SPACING.XL,
    h3: SPACING.STANDARD,
    h4: SPACING.STANDARD,
    '.media-caption-block': SPACING.STANDARD,
    a: SPACING.STANDARD,
    button: SPACING.STANDARD,
    '.alert': SPACING.STANDARD
  },
  ul: {
    p: SPACING.SMALL,
    h2: SPACING.XL,
    h3: SPACING.STANDARD,
    h4: SPACING.STANDARD,
    '.media-caption-block': SPACING.STANDARD,
    a: SPACING.STANDARD,
    button: SPACING.STANDARD,
    '.alert': SPACING.STANDARD
  },
  '.columns': {
    p: SPACING.SMALL,
    h2: SPACING.XL,
    h3: SPACING.STANDARD,
    h4: SPACING.STANDARD,
    '.media-caption-block': SPACING.STANDARD,
    a: SPACING.STANDARD,
    button: SPACING.STANDARD,
    '.alert': SPACING.STANDARD
  },
  '.media-caption-block': {
    p: SPACING.STANDARD,
    h2: SPACING.XXL,
    h3: SPACING.XL,
    h4: SPACING.XL,
    ol: SPACING.STANDARD,
    ul: SPACING.STANDARD,
    '.columns': SPACING.STANDARD,
    '.media-caption-block': SPACING.STANDARD,
    a: {
      xs: '2.4rem'
    },
    button: {
      xs: '2.4rem'
    },
    '.alert': SPACING.STANDARD
  },
  a: {
    p: SPACING.STANDARD,
    h2: SPACING.XXL,
    h3: SPACING.XL,
    h4: SPACING.XL,
    ol: {
      xs: '2.4rem'
    },
    ul: {
      xs: '2.4rem'
    },
    '.columns': {
      xs: '2.4rem'
    },
    '.media-caption-block': SPACING.STANDARD,
    '.alert': SPACING.STANDARD
  },
  button: {
    p: SPACING.STANDARD,
    h2: SPACING.XXL,
    h3: SPACING.XL,
    h4: SPACING.XL,
    ol: {
      xs: '2.4rem'
    },
    ul: {
      xs: '2.4rem'
    },
    '.columns': {
      xs: '2.4rem'
    },
    '.media-caption-block': SPACING.STANDARD,
    '.alert': SPACING.STANDARD
  },
  '.alert': {
    p: SPACING.STANDARD,
    h2: SPACING.XXL,
    h3: SPACING.XL,
    h4: SPACING.XL,
    ol: SPACING.STANDARD,
    ul: SPACING.STANDARD,
    '.columns': SPACING.STANDARD,
    '.media-caption-block': SPACING.STANDARD,
    a: {
      xs: '2.4rem'
    },
    button: {
      xs: '2.4rem'
    },
    '.alert': SPACING.STANDARD
  }
};
