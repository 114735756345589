import { IImage, ContentfulImageOptions, ResponsiveImageOptions } from '@belong/types/ui';

export const getResponsiveSrc = (
  { contentType, src }: IImage,
  breakpoints: ResponsiveImageOptions,
  options?: ContentfulImageOptions
): any => {
  if (contentType === 'svg') {
    return [src, {}];
  }

  return Object.entries(breakpoints).reduce(
    (obj, [key, val]) => ({
      ...obj,
      [key]: [src, { ...val, ...(options || {}) }]
    }),
    {}
  );
};
