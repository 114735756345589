import { useCallback, useEffect, useState, useRef } from 'react';
import { useResizeObserver } from 'usehooks-ts';

import type { IVisibility } from '@belong/types';

export const useHeaderVisibility = (navHeaderRef: React.RefObject<HTMLElement>, defaultHeight: number): IVisibility => {
  const positionRef = useRef(typeof window !== 'undefined' ? window.scrollY : 0);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const { height: navHeaderHeight = defaultHeight } = useResizeObserver({ ref: navHeaderRef });

  const handleScroll = useCallback(() => {
    const prevPosition = positionRef.current;
    positionRef.current = window.scrollY;

    setIsHidden(positionRef.current >= prevPosition && positionRef.current > navHeaderHeight);
  }, [navHeaderHeight]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return { isHidden };
};
