import React from 'react';
import Head from 'next/head';
import { getConfig } from '@belong/configs/next/config';
import { useRouter } from 'next/router';

const { publicRuntimeConfig } = getConfig();
const protocol = publicRuntimeConfig.env.isDevelopment ? 'http' : 'https';

export interface IProps {
  pageUrl: string;
  canonicalUrl?: string;
}

// Note. this component only renders on CSR because we can't reliably
// and securely get the host when running SSR
const CanonicalLink = (props: IProps) => {
  const [locationHost, setLocationHost] = React.useState<string | undefined>();
  const router = useRouter();
  const { basePath } = router;

  let pathName = props.pageUrl || '/';

  if (pathName === '/' && basePath !== '/') {
    pathName = basePath;
  }

  // Check for homepage
  if (pathName === '/go') {
    pathName = '/';
  }

  let url = new URL(`${protocol}://${locationHost}${pathName}`);

  if (props.canonicalUrl) {
    url = new URL(props.canonicalUrl);
  }

  // on mount
  React.useEffect(() => {
    setLocationHost(window.location.host);
  }, []);

  if (!locationHost) {
    return null;
  }

  return (
    <Head>
      <link rel="canonical" href={url.toString()} />
    </Head>
  );
};

CanonicalLink.displayName = 'CanonicalLink';
export default CanonicalLink;
