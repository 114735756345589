/* Styles for the light theme button */

import { IThemedButtonVariants } from '@belong/types';
import { COLOURS } from '../../helpers';

export const buttonVariants: IThemedButtonVariants = {
  primary: {
    backgroundColor: COLOURS.BELONG_BLUE,
    borderColor: COLOURS.BELONG_BLUE,
    color: COLOURS.BLACK,
    hover: {
      backgroundColor: 'transparent',
      borderColor: COLOURS.BELONG_BLUE_DARK,
      color: COLOURS.BLACK
    },
    active: {
      backgroundColor: COLOURS.BELONG_BLUE_DARK,
      borderColor: COLOURS.BELONG_BLUE_DARK
    },
    disabled: {
      backgroundColor: COLOURS.GREY_200,
      borderColor: COLOURS.GREY_200,
      color: COLOURS.GREY_500
    },
    selected: {
      background: 'transparent',
      border: 'none',
      hover: {
        backgroundColor: 'transparent',
        border: 'none'
      },
      active: {
        backgroundColor: 'transparent',
        border: 'none'
      }
    },
    loading: {
      backgroundColor: COLOURS.GREY_200
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    borderColor: COLOURS.BELONG_BLUE_DARK,
    color: COLOURS.BLACK,
    hover: {
      backgroundColor: COLOURS.BELONG_BLUE,
      borderColor: COLOURS.BELONG_BLUE,
      color: COLOURS.BLACK
    },
    active: {
      backgroundColor: 'transparent',
      borderColor: COLOURS.BELONG_BLUE_DARK
    },
    disabled: {
      backgroundColor: 'transparent',
      borderColor: COLOURS.GREY_300,
      color: COLOURS.GREY_400
    },
    selected: {
      background: 'transparent',
      border: 'none',
      hover: {
        backgroundColor: 'transparent',
        border: 'none'
      },
      active: {
        backgroundColor: 'transparent',
        border: 'none'
      }
    },
    loading: {
      backgroundColor: COLOURS.GREY_200,
      borderColor: 'transparent',
      color: COLOURS.BLACK
    },
    currentSelected: {
      background: COLOURS.LIGHT_GREEN,
      border: 'none',
      hover: {
        background: COLOURS.LIGHT_GREEN,
        border: 'none'
      },
      active: {
        background: COLOURS.LIGHT_GREEN,
        border: 'none'
      }
    }
  },
  tertiary: {
    backgroundColor: COLOURS.BELONG_BLUE,
    borderColor: COLOURS.BELONG_BLUE,
    color: COLOURS.BLACK,
    gradientColor: COLOURS.BELONG_BLUE,
    hover: {
      color: COLOURS.DARK_BLUE,
      gradientColor: COLOURS.DARK_BLUE
    },
    active: {
      color: COLOURS.BLACK,
      gradientColor: COLOURS.BELONG_BLUE_LIGHT
    },
    focus: {
      color: COLOURS.BLACK,
      gradientColor: COLOURS.BELONG_BLUE
    },
    disabled: {
      color: COLOURS.GREY_400,
      gradientColor: COLOURS.GREY_300
    }
  },
  quaternary: {
    color: COLOURS.DARK_BLUE,
    focus: {
      color: COLOURS.DARK_BLUE
    },
    disabled: {
      color: COLOURS.GREY_400
    }
  }
};
