import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { Table, Th, Td, TableViewportScroller } from '../../..';

export default {
  /* eslint-disable react/display-name */
  [BLOCKS.TABLE]: (_, children) => (
    <TableViewportScroller>
      <Table>
        <tbody>{children}</tbody>
      </Table>
    </TableViewportScroller>
  ),
  // [BLOCKS.TABLE_ROW]: (node, next) => ``,
  [BLOCKS.TABLE_HEADER_CELL]: (_, children) => <Th>{children}</Th>,
  [BLOCKS.TABLE_CELL]: (_, children) => <Td>{children}</Td>
  /* eslint-enable react/display-name */
};
