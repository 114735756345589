import { Cookies } from 'react-cookie';

import { COOKIES } from '@belong/constants';
import { logger } from '@belong/logging/logger';
import { PLATFORM_TYPE, IPlatformConfig } from '@belong/types';

export const platformConfig: IPlatformConfig = {
  // Always initially assume browser
  // (device bridge does not / must not work on the server)
  platform: PLATFORM_TYPE.BROWSER,
  settings: {}
};

export const isBrowser = (): boolean => {
  return typeof window !== 'undefined' && typeof window.document !== 'undefined';
};

export const isDetailedConfig = (config: IPlatformConfig | string): config is IPlatformConfig =>
  !!config && Object.keys(config).includes('platform');
export const isValidPlatform = (type): boolean => Object.values(PLATFORM_TYPE).includes(type);

export const parsePlatformConfig = (nativeAppCookie: IPlatformConfig | string): IPlatformConfig => {
  const result: IPlatformConfig = {
    platform: PLATFORM_TYPE.BROWSER, // Always initially assume browser
    settings: {}
  };

  // V2 cookie
  if (isDetailedConfig(nativeAppCookie)) {
    const { platform, appVersion, ...settings } = nativeAppCookie;
    result.platform = isValidPlatform(platform) ? platform : PLATFORM_TYPE.BROWSER;
    result.appVersion = appVersion;
    result.settings = { ...settings } as any; // TODO : resolve this type error
  }

  // V1 cookie
  else if (isValidPlatform(nativeAppCookie)) {
    result.platform = nativeAppCookie as PLATFORM_TYPE;
  }

  return result;
};

// Set device platform from cookie
export const initPlatformConfig = (nativeAppCookie: IPlatformConfig | string): void => {
  // V2 cookie
  if (isDetailedConfig(nativeAppCookie)) {
    const { platform, appVersion, ...settings } = nativeAppCookie;
    platformConfig.platform = isValidPlatform(platform) ? platform : PLATFORM_TYPE.BROWSER;
    platformConfig.appVersion = appVersion;
    platformConfig.settings = { ...settings } as any; // TODO : resolve this type error
    return;
  }

  // V1 cookie
  if (isValidPlatform(nativeAppCookie)) {
    platformConfig.platform = nativeAppCookie as PLATFORM_TYPE;
    return;
  }

  // Unrecognised cookie value
  resetPlatformConfig();
};

export const getDevicePlatform = (): PLATFORM_TYPE => {
  return platformConfig.platform;
};

export const resetPlatformConfig = (): void => {
  platformConfig.platform = PLATFORM_TYPE.BROWSER;
  platformConfig.settings = {};
};

export const isUnderAutomatedTesting = (cookies: Cookies): boolean => {
  if (cookies.get(COOKIES.AUTOMATED_BROWSER_TESTING)) {
    logger.debug(`Cookie ${COOKIES.AUTOMATED_BROWSER_TESTING} detected`);
    return true;
  }

  return false;
};

export const shouldRunAnalytics = (analytic: { shouldRun: boolean }, cookie?: string): boolean => {
  if (!analytic.shouldRun) {
    return false;
  }

  const cookies = new Cookies(cookie);
  return !isUnderAutomatedTesting(cookies);
};

/**
 * Compares two app version strings to determine their ordering.
 *
 * The version strings are assumed to be in the format of "x.y.z" where
 * x, y, and z are non-negative integers. The function can handle versions
 * with different numbers of components (e.g., "1.2" vs "1.2.0").
 *
 * @param version1 - The first version string to compare.
 * @param version2 - The second version string to compare.
 * @returns 1 if version1 is greater than version2,
 *          -1 if version1 is less than version2,
 *          and 0 if the two versions are equal.
 */
export const compareAppVersions = (version1: string, version2: string): number => {
  const v1Components = version1.split('.').map(Number);
  const v2Components = version2.split('.').map(Number);

  // Equalize lengths by padding with 0s
  while (v1Components.length < v2Components.length) {
    v1Components.push(0);
  }
  while (v2Components.length < v1Components.length) {
    v2Components.push(0);
  }

  for (let i = 0; i < v1Components.length; i += 1) {
    if (v1Components[i] > v2Components[i]) {
      return 1;
    }
    if (v1Components[i] < v2Components[i]) {
      return -1;
    }
  }
  return 0; // If loop completes, versions are equal
};
