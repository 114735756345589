import { ENVS } from '@belong/types/envs';
import { getConfig } from '@belong/configs/next/config';
import { isBrowser } from '../device';

export type IBuildInfo = {
  timestamp?: string;
  branch?: string;
  commit?: string;
};

/**
 * @todo
 * Implementing a quick fix while migrating to Yarn v2.
 * We should consider removing getConfig() call from utils
 * and implement in the apps themselves
 */
export const getCurrentEnvironment = (): ENVS => {
  return getConfig()?.publicRuntimeConfig.env.stage ?? process.env.NEXT_PUBLIC_STAGE ?? ENVS.PRODUCTION;
};

export function hasAdBlocker(): boolean {
  if (!isBrowser()) {
    return false;
  }

  const div = document.createElement('div');
  div.setAttribute('class', 'adBanner');
  div.setAttribute('style', 'width:1px;height:1px;background-color:transparent');
  document.body.appendChild(div);

  const adWasBlocked = div.offsetHeight === 0;
  document.body.removeChild(div);

  return adWasBlocked;
}

export const getBuildData = async (): Promise<IBuildInfo | null> => {
  const { basePath, env } = getConfig().publicRuntimeConfig;
  const { isLocal } = env;
  if (!isBrowser() || isLocal) {
    return null;
  }

  const result = await fetch(`//${window.location.host}${basePath}/build.json`, { cache: 'no-cache' })
    .then(response => response.json())
    .catch(() => null);

  if (!result) {
    return null;
  }

  return {
    ...result,
    timestamp: result.timestamp?.toLocaleString()
  };
};

// Unified app info logger
export const logAppInfo = async (appName: string, correlationId: string): Promise<void> => {
  const buildData = await getBuildData();
  /* eslint-disable no-console */
  console.group(`✨Belong: ${appName}`);

  if (!buildData) {
    console.info('No build data available');
  } else {
    const { timestamp, branch, commit } = buildData;
    console.info(`timestamp: ${timestamp}`);
    console.info(`branch: ${branch}`);
    console.info(`commit: ${commit}`);
  }

  console.info(`correlationId: ${correlationId}`);
  console.groupEnd();
  /* eslint-enable no-console */
};
